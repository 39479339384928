import React from 'react';

import styles from './Card.module.scss';

interface Props {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

function Card({ style, children }: Props) {
  return (
    <div className={styles.wrapper} style={style}>
      {children}
    </div>
  );
}

export default Card;
