import React from 'react';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import { CardSkeleton } from 'components/widgets/CardView/CardSkeleton/CardSkeleton';
import { RailCardContainerProps } from 'components/widgets/RailV2/components/RailProviderV2/types';

import RecommendationCard from '../RecommendationCard/RecommendationCard';

function RecommendationCardContainer({ item, itemWidth, imageHeight }: RailCardContainerProps<CollectionAsset>) {
  if (!item?.id) {
    return <CardSkeleton height={imageHeight} width={itemWidth} />;
  }
  return <RecommendationCard imageHeight={imageHeight} itemWidth={itemWidth} item={item} />;
}

export default React.memo(RecommendationCardContainer);
