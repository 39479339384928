import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import { Channel, useTimer } from '@kaltura-ott/tvpil-shared';

import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import RailViewer from 'components/widgets/RailV2/components/RailViewer/RailViewer';
import { useApplyStyles } from 'components/widgets/RailV2/hooks/useApplyStyles/useApplyStyles';
import { HERO } from 'consts';
import { useWidgetContext } from 'context/widgetProvider/widgetProvider';

import HeroCardContainer from '../HeroCardContainer/HeroCardContainer';

import styles from './HeroRailContainer.module.scss';

const HeroRailContainer = () => {
  // using version v2 of the rail provider
  const { wrapperRef, refElementInner, componentHeight } = useRailContext();
  const { settings } = useWidgetContext();
  const handleRef = useApplyStyles(settings);
  // state for the current timestamp
  const [now, setNow] = useState(() => Date.now());
  // handler for the timer tick
  const handleTimerTick = useCallback(() => {
    setNow(Date.now());
  }, []);
  // This timer needs to handle updating program in the rail each 5000 milliseconds.
  useTimer({ interval: 5000, onTimerTick: handleTimerTick });

  return (
    <div id={HERO} ref={handleRef} className={cn(styles.container)}>
      <div ref={refElementInner}>
        <div
          ref={wrapperRef}
          style={{
            height: componentHeight,
          }}
        >
          <RailViewer<Channel>>
            {({ imageHeight, itemWidth, item, index }) => (
              <HeroCardContainer
                imageHeight={imageHeight}
                itemWidth={itemWidth}
                channel={item}
                timestamp={now}
                index={index}
              />
            )}
          </RailViewer>
        </div>
      </div>
    </div>
  );
};

export default HeroRailContainer;
