import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { observer } from 'mobx-react';

import { useContentRestriction } from 'hooks';

interface Props {
  handleLinkRef: React.MutableRefObject<HTMLAnchorElement | null>;
  lockIcon: string;
  isAdult: boolean;
}

function CardContentRestricted({ handleLinkRef, lockIcon, isAdult }: Props) {
  const isContentRestricted = useContentRestriction(isAdult);

  useEffect(() => {
    if (isContentRestricted) {
      handleLinkRef?.current?.setAttribute('ui-type', 'restrictedContent');
    } else {
      handleLinkRef?.current?.removeAttribute('ui-type');
    }
  }, [isContentRestricted, handleLinkRef]);

  return <>{isContentRestricted && <SVG data-target='lock' src={lockIcon!} />}</>;
}

export default observer(CardContentRestricted);
