import { AssetWithSeriesId } from 'types';

import { CollectionAsset, convertRhinoAssetTypeToMediaType } from '@kaltura-ott/tvpil-shared';

import { resolveLinkProgramAsset } from 'components/widgets/Card/helpers';

interface Props {
  data: CollectionAsset;
  isSingleVod: boolean;
  isFutureProgram: boolean;
  collectionType: string | undefined;
  isChannelAsset: boolean;
}

function useSeriesCatchUpCardLink({ data, isSingleVod, isFutureProgram, collectionType, isChannelAsset }: Props) {
  const mediaTypeParam = convertRhinoAssetTypeToMediaType(data.type);

  const link = resolveLinkProgramAsset({
    data: data as AssetWithSeriesId,
    collectionType,
    mediaTypeParam,
    isFutureProgram,
    isSingleVod,
    isAsPlayAsset: false,
    isChannelAsset,
  });

  return { link, mediaTypeParam };
}

export { useSeriesCatchUpCardLink };
