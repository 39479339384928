import { useRef } from 'react';

import {
  EntityRating,
  getTopShelfAssetContentMarker,
  isTopShelfAssetPurchasable,
  isTopShelfPackageAsset,
  useLinears,
} from '@kaltura-ott/tvpil-shared';

import { useCardIconsPaths } from 'hooks';

import { CardCarouselProps } from '../../types';
import { useClickHandler } from '../useClickHandler/useClickHandler';
import { useImages } from '../useImage/useImages';
import { useLink } from '../useLink/useLink';

/**
 * `useCardContentCombiner` is a custom React hook used for managing data necessary for the carousel
 *
 *
 * @param items - Array of assets.
 * @param width - A width of asset.
 * @param height - A height of asset.
 * @param shouldPresentRating - A boolean flag.
 * @param imageType - A number, image type of asset.
 *
 * @returns An object containing:
 * - `isShowRating`: logical flag for rating ui presentation.
 * - `isAdult`: logical flag for adult ui presentation.
 * - `isPurchasable`: logical flag for presentation of purchase ui.
 * - `clickHandler`: callback function for click handling.
 * - `contentMarker`: an object with content markers data.
 * - `rating`: an object with rating data.
 * - `url`: an string with path of image.
 * - `lockIcon`: an string with path of lock icon.
 * - `handleLinkRef`: mutable react ref object.
 *
 */

function useCardContentCombiner({ item, width, height, imageType, shouldPresentRating }: CardCarouselProps) {
  const { linears } = useLinears();
  const handleLinkRef = useRef(null);
  let rating;
  let isAdult = false;

  if (!isTopShelfPackageAsset(item)) {
    isAdult = item.isAdult || false;
    rating = item.rating as EntityRating;
  }
  const isShowRating = shouldPresentRating && rating && rating.value;
  const { lockIcon } = useCardIconsPaths({ height, width });
  const isPurchasable = isTopShelfAssetPurchasable(item, linears);
  const contentMarker = getTopShelfAssetContentMarker(item);
  const isPackageAsset = isTopShelfPackageAsset(item);
  const link = useLink(item, isPackageAsset);
  const { url } = useImages({
    item,
    width,
    height,
    imageType,
  });
  const clickHandler = useClickHandler({ item, link, isPackageAsset, isAdult });

  return { url, isPurchasable, clickHandler, contentMarker, handleLinkRef, lockIcon, isAdult, isShowRating, rating };
}

export { useCardContentCombiner };
