import { useCallback } from 'react';

import { Channel } from '@kaltura-ott/tvpil-shared';

import { hasOrderChanged } from '../helper';

type Lineup = { channelId: number; channelNumber: number };

interface UseHandleLineupChangeProps {
  originalChannels: Array<Channel> | undefined;
  setChannels: React.Dispatch<React.SetStateAction<Array<Channel> | null>>;
  updateLineup: React.Dispatch<React.SetStateAction<Array<Lineup> | null>>;
  setIsUniqueModification: React.Dispatch<React.SetStateAction<Boolean>>;
}

export const useHandleLineupChange = ({
  originalChannels,
  setChannels,
  updateLineup,
  setIsUniqueModification,
}: UseHandleLineupChangeProps) => {
  return useCallback(
    (updatedChannels: Array<Channel>) => {
      setChannels(updatedChannels);

      const newUserLineup = updatedChannels.map((channel) => ({
        channelNumber: channel.virtualNumber!,
        channelId: channel.id,
      }));

      updateLineup(newUserLineup);

      if (!originalChannels) {
        return;
      }

      const orderingHasBeenChanged = hasOrderChanged(originalChannels, updatedChannels);

      setIsUniqueModification(orderingHasBeenChanged);
    },
    [originalChannels, setChannels, updateLineup, setIsUniqueModification],
  );
};
