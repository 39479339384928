import { IntlShape } from 'react-intl';
import { CollectionGridMediaAssetWithEpisodes } from 'types';

import { RhinoProgram } from '@kaltura-ott/tvpil-shared';

type SeasonEpisodeMeta = {
  episodeName: string | undefined;
  episodeNumber: number | undefined;
  seasonNumber: number | undefined;
};

export function getSeasonEpisodeTitleV2(
  intl: IntlShape,
  data: CollectionGridMediaAssetWithEpisodes | RhinoProgram | SeasonEpisodeMeta,
  delimiter = ':',
  withEpisodeName = true,
) {
  let text = '';
  const { seasonNumber, episodeNumber, episodeName } = data;
  const season =
    seasonNumber &&
    `${intl.formatMessage({ defaultMessage: 'Season', id: 'VideoDetails.season' }).slice(0, 1)}${seasonNumber}`;
  const episode =
    episodeNumber &&
    `${intl.formatMessage({ defaultMessage: 'Episode', id: 'VideoDetails.episode' }).slice(0, 1)}${episodeNumber}`;

  if (season && episode) {
    text = `${season}${delimiter}${episode}`;
  } else if (season) {
    text = season;
  } else if (episode) {
    text = episode;
  }

  if (episodeName && withEpisodeName) {
    text += ` ${episodeName}`;
  }

  return text;
}
