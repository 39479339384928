import { SetStateAction, useCallback, useState } from 'react';

import { useContinueWatching, useContinueWatchingSettings, WatchedAsset } from '@kaltura-ott/tvpil-shared';

import { RailProviderData, RailSettings } from 'components/widgets/RailV2/components/RailProviderV2/types';
import { useHandleSkeletonRef } from 'components/widgets/RailV2/hooks/useHandleSkeletonRef/useHandleSkeletonRef';
import { DEFAULT_HEIGHT, LANDSCAPE_RATIO, RAIL_PAGE_SIZE, WIDGET_TYPE } from 'consts';

function useContinueWatchingRail(referenceId: string) {
  const [shouldPreventDisplay, setShouldPreventDisplay] = useState(true);
  const originalSettings = useContinueWatchingSettings({
    referenceId,
    defaultLandscapeImageHeight: DEFAULT_HEIGHT,
    defaultPortraitImageHeight: DEFAULT_HEIGHT,
    defaultImageRatio: LANDSCAPE_RATIO,
  });
  const originalData = useContinueWatching({
    referenceId,
    pageSize: RAIL_PAGE_SIZE,
    useLimit: true,
  });
  const type = WIDGET_TYPE.CW;
  const setIsSkeletonConditionForward = useCallback(
    (value: SetStateAction<boolean>) => setShouldPreventDisplay(value),
    [],
  );
  const hasNoData = !originalData.items.length && !originalData.isLoading;
  const isRailEmpty = hasNoData || Boolean(originalData.error);

  const railData: RailProviderData<WatchedAsset> = {
    items: originalData.items,
    totalCount: originalData.totalCount,
    hasNextPage: originalData.hasNextPage,
    isLoading: originalData.isLoading,
    error: originalData.error,
    fetchNextPage: originalData.fetchNextPage,
    refetch: originalData.refetch,
  };

  const railSettings: RailSettings = {
    branding: originalSettings?.branding,
    id: originalSettings?.id,
    imageHeight: originalSettings?.imageHeight,
    imageType: originalSettings?.imageType,
    imageWidth: originalSettings?.imageWidth,
    title: originalSettings?.title,
    moreLink: originalSettings?.moreLink,
    shouldShowRating: originalSettings?.shouldShowRating,
    shouldGroupEpisodes: originalSettings?.shouldGroupEpisodes,
  };

  const handleSkeletonRef = useHandleSkeletonRef(setIsSkeletonConditionForward, originalData.items, railSettings);

  return {
    type,
    isRailEmpty,
    data: railData,
    settings: originalSettings ? railSettings : null,
    shouldPreventDisplay,
    handleSkeletonRef,
  };
}

export { useContinueWatchingRail };
