import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import ActionButton from 'components/widgets/ActionButton/ActionButton';
import { ActionButtonTypesEnum } from 'interface';

import { FULL_COMPONENT_NAME } from '../../constants';

import styles from './ActionButtons.module.scss';

interface ActionButtonsProps {
  isSavingUserLineup: boolean;
  shouldShowCancelButton: boolean;
  shouldShowRevertButton: boolean;
  shouldShowSaveButton: boolean;
  handleClickSaveButton: () => void;
  handleClickRevertButton: () => void;
  handleClickCancelButton: () => void;
}

export const ActionButtons = ({
  isSavingUserLineup,
  shouldShowCancelButton,
  shouldShowRevertButton,
  shouldShowSaveButton,
  handleClickSaveButton,
  handleClickRevertButton,
  handleClickCancelButton,
}: ActionButtonsProps) => (
  <div className={styles.container}>
    <div>
      {shouldShowSaveButton && (
        <ActionButton
          type={ActionButtonTypesEnum.primary}
          onClick={handleClickSaveButton}
          className={styles.saveButton}
          isPending={isSavingUserLineup}
        >
          <FormattedMessage defaultMessage='Save' id={`${FULL_COMPONENT_NAME}.buttonSave`} />
        </ActionButton>
      )}

      {shouldShowCancelButton && (
        <ActionButton className={cn(styles.transparentButtons, styles.cancelButton)} onClick={handleClickCancelButton}>
          <FormattedMessage defaultMessage='Cancel' id={`${FULL_COMPONENT_NAME}.buttonCancel`} />
        </ActionButton>
      )}
    </div>

    {shouldShowRevertButton && (
      <ActionButton className={cn(styles.transparentButtons)} onClick={handleClickRevertButton}>
        <FormattedMessage defaultMessage='Revert to original' id={`${FULL_COMPONENT_NAME}.buttonReset`} />
      </ActionButton>
    )}
  </div>
);
