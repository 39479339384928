import React, { useCallback } from 'react';
import cn from 'classnames';

import { NEXT_BUTTON_WIDTH } from 'components/widgets/Rail/constants';

import { Position } from '../../types';
import { ControlsProps } from '../Carousel/types';

import styles from './NavigationButton.module.scss';

interface Props {
  position: Position;
  className: string;
  controlsRef: React.MutableRefObject<ControlsProps>;
}

const NavigationButton: React.FC<Props> = ({ position, controlsRef, className }) => {
  const handleClick = useCallback(() => {
    if (controlsRef.current.clickArrowHandle) {
      controlsRef.current.clickArrowHandle(position === Position.Right);
    }
  }, [controlsRef.current]);

  return (
    <div
      className={cn(className, styles.wrapper, {
        [styles.left]: position === Position.Left,
        [styles.right]: position === Position.Right,
      })}
    >
      <button
        className={cn(className, styles.nextButton)}
        onClick={handleClick}
        style={{ width: NEXT_BUTTON_WIDTH }}
        type='button'
      >
        &gt;
      </button>
    </div>
  );
};

export default NavigationButton;
