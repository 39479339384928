import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { PinValidateStatus } from '@kaltura-ott/tvpil-shared';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import { useModalContext } from 'context/modal/modal';
import { useParentalPinStore } from 'hooks/common/useParentalPinStore';
import { ActionButtonTypesEnum, ApplicationError } from 'interface';
import { sendApplicationErrorEvent } from 'services/analytics/applicationErrorEvent';

// TODO: this component must be divided and refactored
import { calculatePinLimitTime } from '../PinCodeModal/utils/calculatePinLimitTime/calculatePinLimitTime';
import { getPinLimitError } from '../PinCodeModal/utils/getPinLimitError/getPinLimitError';

import PinCode from './PinCode/PinCode';
import { PinCodeModalTypes } from './PinCodeModalTypesEnum';

import styles from './PinCodeModal.module.scss';

// maybe use KalturaPin enum
export interface PinCodeModalProps {
  parentalRating: number | undefined;
  type: PinCodeModalTypes;
  beforePinCodeContent?: React.ReactNode;
  afterPinCodeContent?: React.ReactNode;
  validatePin?: (pin: string) => Promise<any> | Object | undefined;
  callback?(success: boolean): void;
  onDecline?(): void;
  title?: string; // TODO: make this and 'description' required
  description?: string | React.ReactNode;
  className?: string;
  isConfirmErrorMessage?: boolean;
  context?: UIDAPresentationTypeEnum;
  inAppLink?: string;
}

const defaultPropsError = {
  errorMessage: '',
  errorCode: '',
};

const DefaultError = {
  errorMessage: 'The PIN you entered doesn’t match. Please try again.',
  errorCode: '5999',
};

export const WRONG_PIN_ERR_CODE = '5002';

function PinCodeModal({
  validatePin,
  beforePinCodeContent,
  afterPinCodeContent,
  callback,
  className,
  description,
  title,
  type,
  onDecline,
  parentalRating,
  isConfirmErrorMessage,
  context,
  inAppLink,
}: PinCodeModalProps) {
  const [pinCode, setPinCode] = useState('');
  const [error, setError] = useState(defaultPropsError);
  const [loading, setLoading] = useState(false);
  const inputWrapper = useRef<HTMLDivElement>(null);
  const modalContext = useModalContext();
  const parentalPinStore = useParentalPinStore();
  const { isParentalPinLocked, parentalPinTimeout, isPurchasePinLocked, purchasePinTimeout } = parentalPinStore;
  const pincodeLength = 4;
  let titleResolved = title;
  let timeoutValue = 0;
  const intl = useIntl();
  let timeout: NodeJS.Timeout;
  const isRestrictedLock =
    [
      PinCodeModalTypes.RestrictedContent,
      PinCodeModalTypes.RestrictedContentParental,
      PinCodeModalTypes.EnterParental,
      PinCodeModalTypes.RestrictedContentForLink,
    ].includes(type) && isParentalPinLocked;
  const isPurchaseLock = PinCodeModalTypes.Purchase === type && isPurchasePinLocked;

  if (isRestrictedLock) {
    timeoutValue = parentalPinTimeout;
  } else if (isPurchaseLock) {
    timeoutValue = purchasePinTimeout;
  }
  const isPinLocked = isRestrictedLock || isPurchaseLock;
  const isResetError = !isParentalPinLocked && !isPurchasePinLocked;
  const pinTimeout = calculatePinLimitTime(timeoutValue);
  const pinLimitError = isPinLocked ? getPinLimitError(pinTimeout) : undefined;

  function checkResetInput(err = '') {
    if (err && pinCode) {
      setPinCode('');
    }
  }

  function appErrorOnValidatePin(result: any) {
    if (result.code !== WRONG_PIN_ERR_CODE) {
      sendApplicationErrorEvent({
        context: context as UIDAPresentationTypeEnum,
        message: result.message,
        code: result.code?.toString(),
        additionalInfo: ApplicationError.Pin,
      });
    }
  }

  async function submit(event?: any) {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);

    let result;
    if (validatePin) {
      result = await validatePin(pinCode);
    }
    if (type === PinCodeModalTypes.RestrictedContentForLink) {
      result = await parentalPinStore.validateInApplicationLink(pinCode, inAppLink!);
    } else if (type === PinCodeModalTypes.RestrictedContentParental || type === PinCodeModalTypes.EnterParental) {
      result = await parentalPinStore.validateParentalRating({ pin: pinCode, parentalRating: parentalRating! });
    } else {
      result = await parentalPinStore.validateAdultPin(pinCode);
    }

    setLoading(false);

    if (!result && callback) {
      callback(false);
      return;
    }

    const { message: errorMessage, state, code: errorCode } = result;

    if (state === PinValidateStatus.error) {
      setPinCode('');
      setError({ errorMessage: errorMessage!, errorCode: errorCode! });
      appErrorOnValidatePin(result);
      checkResetInput(errorMessage);
      return;
    }

    modalContext.closeModal();

    if (callback) {
      callback(true);
    }
  }

  useEffect(() => {
    const node = inputWrapper?.current?.childNodes[0] as HTMLInputElement;
    if (node && !pinCode) {
      timeout = setTimeout(() => {
        node.focus();
      }, 0);
    }
    if (pinCode.length === pincodeLength) {
      submit();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [pinCode]);

  let text = description;

  if (!title) {
    titleResolved = intl.formatMessage({
      id: 'PinCodeModal.title',
      defaultMessage: 'Enter PIN Code',
    });
  }

  // TODO: move description out of component and use description prop in DefaultModal
  switch (type) {
    case PinCodeModalTypes.Purchase:
      if (!text) {
        text = intl.formatMessage({
          id: 'PinCodeModal.purchaseTextConfirm',
          defaultMessage: 'Enter PIN code to confirm purchase:',
        });
      }
      break;

    case PinCodeModalTypes.UpdateParental:
      text = intl.formatMessage({
        id: 'PinCodeModal.parentalTextNew',
        defaultMessage: 'Enter new Parental PIN',
      });
      titleResolved = intl.formatMessage({
        id: 'PinCodeModal.parentalTitle',
        defaultMessage: 'Change Parental PIN',
      });
      break;

    case PinCodeModalTypes.UpdatePurchase:
      text = intl.formatMessage({
        id: 'PinCodeModal.purchaseTextNew',
        defaultMessage: 'Enter new Purchase PIN',
      });
      titleResolved = intl.formatMessage({
        id: 'PinCodeModal.purchaseTitleChange',
        defaultMessage: 'Change Purchase PIN',
      });
      break;

    case PinCodeModalTypes.ConfirmPurchase:
      text = intl.formatMessage(
        {
          id: 'PinCodeModal.purchaseTextNewConfirm',
          defaultMessage: 'Enter new purchase again',
        },
        {
          strong: (value: React.ReactNode) => <b>{value}</b>,
        },
      );
      titleResolved = intl.formatMessage({
        id: 'PinCodeModal.purchaseTitleChange',
        defaultMessage: 'Change Purchase PIN',
      });
      break;

    case PinCodeModalTypes.ConfirmParental:
      text = intl.formatMessage(
        {
          id: 'PinCodeModal.parentalTextNewConfirm',
          defaultMessage: 'Enter new Parental PIN again',
        },
        {
          strong: (value: React.ReactNode) => <b>{value}</b>,
        },
      );
      titleResolved = intl.formatMessage({
        id: 'PinCodeModal.parentalTitle',
        defaultMessage: 'Change Parental PIN',
      });
      break;

    case PinCodeModalTypes.RestrictedContentForLink:
    case PinCodeModalTypes.RestrictedContent:
      if (!text) {
        text = intl.formatMessage({
          id: 'PinCodeModal.unlockText',
          defaultMessage: 'Enter PIN code to unlock:',
        });
      }
      titleResolved = intl.formatMessage({
        id: 'Modals.PinCode.enterPinToViewContent',
        defaultMessage: 'Enter PIN Code To View This Content',
      });
      break;

    case PinCodeModalTypes.EnterParental:
      text = intl.formatMessage({
        id: 'PinCodeModal.parentalTextEdit',
        defaultMessage: 'To edit your parental control settings you must enter PIN code',
      });
      break;

    default:
      if (!text) {
        text = intl.formatMessage({
          id: 'PinCodeModal.title',
          defaultMessage: 'Enter PIN Code',
        });
      }
      break;
  }

  useEffect(() => {
    if (isConfirmErrorMessage) {
      setError({ ...DefaultError });
    }
  }, [isResetError]);

  useEffect(() => {
    if (isResetError) {
      setError(defaultPropsError);
    }
  }, [isResetError]);

  return (
    <form onSubmit={submit}>
      <DefaultModal
        buttonProps={{
          acceptButtonText: intl.formatMessage({
            id: 'PinCodeModal.DefaultModal.buttonSubscribe',
            defaultMessage: 'Subscribe',
          }),
          acceptButtonType: ActionButtonTypesEnum.primary,
        }}
        className={className}
        loading={loading}
        onDecline={onDecline}
        title={titleResolved}
      >
        {beforePinCodeContent}
        <div>
          <div className={styles.instruction}>{text}</div>
        </div>
        <PinCode
          error={pinLimitError || error}
          inputWrapper={inputWrapper}
          loading={loading}
          pinCode={pinCode}
          pincodeLength={pincodeLength}
          setError={setError}
          setPinCode={setPinCode}
          pinTimeout={pinTimeout}
        />
        {afterPinCodeContent}
      </DefaultModal>
    </form>
  );
}

export default observer(PinCodeModal);
