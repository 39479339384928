import { useCallback } from 'react';

import { MIN_LEFT_POSITION, MIN_LEFT_POSITION_FOR_LANDSCAPE } from '../../constants';
import { calculateTop, getScaleAndTranslateX, setElementStyle } from '../../helpers';

// TODO: cover by tests

interface Props {
  imageHeight: number;
  isLandscape: boolean;
  currentIndex: number; // active asset
  cardsNumber: number;
}

function useCardCssProps({ imageHeight, isLandscape, currentIndex, cardsNumber }: Props) {
  const isMinNumbersOfCards = cardsNumber === 4;
  const minLeftPosition = isLandscape ? MIN_LEFT_POSITION_FOR_LANDSCAPE : MIN_LEFT_POSITION;

  return useCallback(
    (i: number, element: HTMLElement) => {
      const { scale, translateX, differ } = getScaleAndTranslateX(
        currentIndex,
        i,
        isLandscape,
        cardsNumber,
        minLeftPosition,
        isMinNumbersOfCards,
      );
      const top = calculateTop(imageHeight, scale);
      const isActive = i === currentIndex;

      setElementStyle(element, `translateX(${translateX}%) scale(${scale})`, 99 - Math.abs(differ), top, isActive);
    },
    [imageHeight, isLandscape, currentIndex, cardsNumber],
  );
}

export { useCardCssProps };
