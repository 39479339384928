import { useRef } from 'react';

import { getAssetImageUrl, isTopShelfPackageAsset, TopShelfAsset } from '@kaltura-ott/tvpil-shared';

import { topShelfStorage } from 'components/widgets/RailV2/TopShelfRail/topShelfStorage';
import { useContentRestriction, useRecordRepository } from 'hooks';

import { getContentProps, getRecordInfo } from '../../utils';

/**
 * `useContentCombiner` is a custom React hook used for managing content data
 *
 *
 * @param coverImageType - A number.
 * @param focusAssetRef - A mutable react ref object.
 * @param cardImageType - A number.
 *
 * @returns An object containing:
 * - `isEmpty`: logical flag for displaying of element based on for presence or absence focused asset.
 * - `isContentRestricted`: logical flag for displaying of restricted ui.
 * - `listOfItems`: an array structured as `TopShelfMovieAsset | TopShelfProgramAsset | TopShelfEpisodeAsset | TopShelfSeriesAsset`.
 * - `description`: the description of asset.
 * - `title`: the title of asset.
 * - `audioTypes`: the audio type of asset.
 * - `genres`: the genres of asset.
 * - `year`: the year of asset.
 * - `qualities`: the qualities of asset.
 * - `country`: the country of asset.
 * - `duration`: the duration of asset.
 * - `parentalRating`: the parental rating of asset.
 * - `fallBackUrl`: the fallBack url of asset.
 * - `poster`: the poster url of asset.
 * - `hasRecording`: logical flag for displaying the record icon.
 * - `isSeriesRecording`: logical flag for changing of record icon ui.
 */

function useContentCombiner(
  coverImageType: number | undefined,
  focusAssetRef: React.MutableRefObject<TopShelfAsset | undefined>,
  cardImageType: number | undefined,
) {
  const activeItem: TopShelfAsset | undefined = focusAssetRef?.current!;
  const recordRepository = useRecordRepository();
  let isAdult = false;
  let fallBackUrl;

  useRef(topShelfStorage.isUpdated); // for updating component from mobX field

  if (focusAssetRef.current && !isTopShelfPackageAsset(focusAssetRef.current)) {
    isAdult = focusAssetRef.current.isAdult || false;
  }

  const isContentRestricted = useContentRestriction(isAdult);
  const { isSeriesRecording, hasRecording } = getRecordInfo({ recordRepository, activeItem });
  const isEmpty = !activeItem;
  const { height, width } = window.screen;
  const {
    images,
    description,
    title,
    audioTypes,
    parentalRating,
    genres,
    year,
    qualities,
    country,
    duration,
  } = getContentProps(activeItem);

  const poster = getAssetImageUrl(images, {
    type: coverImageType,
    width,
    height,
  });

  if (!poster) {
    fallBackUrl = getAssetImageUrl(images, {
      type: cardImageType,
      width,
      height,
    });
  }

  return {
    isEmpty,
    description,
    title,
    audioTypes,
    genres,
    year,
    qualities,
    country,
    duration,
    parentalRating,
    fallBackUrl,
    poster,
    isContentRestricted,
    isSeriesRecording,
    hasRecording,
  };
}

export { useContentCombiner };
