import { CollectionAsset, useGridCollection } from '@kaltura-ott/tvpil-shared';

import { PAGE_SIZE } from 'components/pages/Grids/constants';
import { useGridMatchPath } from 'components/pages/Grids/hooks/useGridMatchPath/useGridMatchPath';
import { WidgetProviderData } from 'context/widgetProvider/types';
import routes from 'routesPaths';

function useCollectionGrid() {
  const { type, id } = useGridMatchPath(routes.collectionGrid.path);
  const { items, totalCount, isLoading, hasNextPage, fetchNextPage, error, refetch } = useGridCollection({
    gridReferenceId: type,
    railReferenceId: id,
    pageSize: PAGE_SIZE,
  });

  const data: WidgetProviderData<CollectionAsset> = {
    items,
    totalCount,
    hasNextPage,
    isLoading,
    error,
    fetchNextPage,
    refetch,
  };

  return {
    gridData: data,
    id,
  };
}

export { useCollectionGrid };
