import React from 'react';
import { FormattedMessage } from 'react-intl';

import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import Img from 'components/presenters/Img/Img';
import MainLayout from 'components/presenters/layouts/main/MainLayout';
import Loader from 'components/presenters/Loader/Loader';
import NoContent from 'components/widgets/NoContent/NoContent';
import routes from 'routesPaths';
import { APPLICATION_SPINNER_EVENT_NAME } from 'utils/getAdditionalEventParam';

import GridUpdatingHook from '../components/GridUpdatingHook/GridUpdatingHook';
import { COMPONENT_NAME } from '../constants';

import { CollectionGridLogic } from './components/CollectionGridLogic/CollectionGridLogic';
import { useCollectionGrid } from './hooks/useCollectionGrid/useCollectionGrid';

import styles from './CollectionGrid.module.scss';

function CollectionGrid() {
  const {
    isLoading,
    gridSettings,
    gridType,
    isNoContent,
    wrapperRef,
    providerLogo,
    notValidPageTitle,
    setForceUpdate,
  } = useCollectionGrid();

  return (
    <MainLayout>
      {isNoContent ? (
        <NoContent title={notValidPageTitle} />
      ) : (
        <>
          <GridUpdatingHook setForceUpdate={setForceUpdate} path={routes.collectionGrid.path} />
          <div ref={wrapperRef} className={styles.wrapper}>
            {!!providerLogo && <Img alt='provider logo' className={styles.providerLogo} srcList={providerLogo} />}
            {isLoading ? (
              <div className={styles.loaderWrapper}>
                <LoaderWrapperForEvents hashKey={APPLICATION_SPINNER_EVENT_NAME.GRID_PAGE} component={<Loader />} />
              </div>
            ) : (
              <>
                <FormattedMessage
                  defaultMessage={gridSettings.title}
                  id={`${COMPONENT_NAME}.title.${gridSettings.title}`}
                  tagName='h3'
                />
                <CollectionGridLogic type={gridType} settings={gridSettings} />
              </>
            )}
          </div>
        </>
      )}
    </MainLayout>
  );
}

export default React.memo(CollectionGrid);
