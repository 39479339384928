import React from 'react';

import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import MaxiPlayerPoster from 'components/widgets/Player/components/MaxiPlayerPoster/MaxiPlayerPoster';
import { createSkeletonCollection } from 'utils';

import { COMPONENT_NAME } from '../../constans';
import VideoContent from '../VideoContent/VideoContent';
import { useVideoPlayoutContext } from '../VideoPlayoutProvider/VideoPlayoutProvider';

import styles from './VideoPlayoutResolveRendering.module.scss';

const SKELETON_ITEMS_NUMBER = 3;
const SCAFFOLD_SKELETON_ITEMS_PREFIX = 'scaffold-skeleton';

function VideoPlayoutResolveRendering() {
  const { asset, loaded, isContentRestricted, isContentGeoBlocking } = useVideoPlayoutContext();
  const isPlayerShowed = asset && loaded;
  const isContentGeoBlocked = Boolean(isContentGeoBlocking) || Boolean(isContentRestricted);
  const scaffolds = createSkeletonCollection(SKELETON_ITEMS_NUMBER, SCAFFOLD_SKELETON_ITEMS_PREFIX);

  return (
    <>
      {!isContentGeoBlocked && <MaxiPlayerPoster />}
      {isPlayerShowed ? (
        <VideoContent isContentGeoBlocked={isContentGeoBlocked} />
      ) : (
        <div className={styles.scaffold}>
          <LoaderWrapperForEvents
            hashKey={COMPONENT_NAME}
            component={scaffolds.map((scaffold) => (
              <div key={scaffold.id} className={styles.scaffoldItem} />
            ))}
          />
        </div>
      )}
    </>
  );
}

export default VideoPlayoutResolveRendering;
