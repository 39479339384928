import React from 'react';

import MoreButton from '../MoreButton/MoreButton';
import { RailSettings } from '../RailProviderV2/types';

import styles from './Title.module.scss';

interface Props {
  railSettings: RailSettings;
  forwardHeaderRef: any;
  totalCount: number;
  itemsLength: number;
  hasNextPage: boolean;
}

const Title = ({ forwardHeaderRef, railSettings, totalCount, itemsLength, hasNextPage }: Props) => {
  const { title, moreLink } = railSettings;

  const shouldShowMoreButton = moreLink && !hasNextPage && itemsLength < totalCount;

  return (
    <>
      {title && (
        <header className={styles.header} ref={forwardHeaderRef}>
          <h3 data-length={itemsLength} data-count={totalCount}>
            {title}
          </h3>
          {shouldShowMoreButton && <MoreButton data={moreLink} />}
        </header>
      )}
    </>
  );
};

export default Title;
