import { SetStateAction, useCallback, useState } from 'react';

import { CollectionProgramAsset, useRailSettings, useSeriesCatchUpRailCollection } from '@kaltura-ott/tvpil-shared';

import { RailProviderData, RailSettings } from 'components/widgets/RailV2/components/RailProviderV2/types';
import { useHandleSkeletonRef } from 'components/widgets/RailV2/hooks/useHandleSkeletonRef/useHandleSkeletonRef';
import { useRailEmptyCheck } from 'components/widgets/RailV2/hooks/useRailEmptyCheck/useRailEmptyCheck';
import { RAIL_PAGE_SIZE } from 'consts';

function useSeriesCatchUpRail(referenceId: string) {
  const originalSettings = useRailSettings({ referenceId });
  const originalData = useSeriesCatchUpRailCollection(referenceId, RAIL_PAGE_SIZE);

  const isRailEmpty = useRailEmptyCheck(originalData);
  const isRailInvalid = isRailEmpty || Boolean(originalData.error);

  const [shouldPreventDisplay, setShouldPreventDisplay] = useState(true);

  const setShouldPreventDisplayForward = useCallback(
    (value: SetStateAction<boolean>) => setShouldPreventDisplay(value),
    [],
  );

  const data: RailProviderData<CollectionProgramAsset> = {
    items: originalData.items,
    totalCount: originalData.totalCount,
    hasNextPage: originalData.hasNextPage,
    isLoading: originalData.isLoading,
    error: originalData.error,
    fetchNextPage: originalData.fetchNextPage,
    refetch: originalData.refetch,
  };

  const settings: RailSettings = {
    branding: originalSettings?.branding,
    id: originalSettings?.id,
    imageHeight: originalSettings?.imageHeight,
    imageType: originalSettings?.imageType,
    imageWidth: originalSettings?.imageWidth,
    title: originalSettings?.title,
    moreLink: originalSettings?.moreLink,
    shouldShowRating: Boolean(originalSettings?.shouldShowRating),
    shouldShowEntitlementIndicator: Boolean(originalSettings?.shouldShowEntitlementIndicator),
  };

  const handleSkeletonRef = useHandleSkeletonRef(setShouldPreventDisplayForward, data.items, settings);

  return {
    data,
    settings: originalSettings ? settings : null,
    isRailInvalid,
    handleSkeletonRef,
    shouldPreventDisplay,
  };
}

export { useSeriesCatchUpRail };
