import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import { QuotaStatusEnum } from '@kaltura-ott/tvpil';
import { Program, Record, SeriesRecord } from '@kaltura-ott/tvpil-shared';

import { RecordIcon } from 'components/presenters/icons';
import { MD_ICON } from 'consts';
import { useVideoContext } from 'context/videoContext/videoContext';
import { useAppStore, useRecordRepository } from 'hooks';
import { checkIsMosaicProgram, getMDIconPath, getMosaicStreamDetails, playoutStoreDataResolver } from 'utils';

import { COMPONENT_NAME } from '../../../../constants';

import styles from './Title.module.scss';

type Props = {
  title: string;
  record: Record;
  seriesRecord?: SeriesRecord;
};

export const Title = ({ title, record, seriesRecord }: Props) => {
  const { program, isOverlay } = useVideoContext();
  const {
    data: { externalId, mosaicInfo },
  } = program as Program;
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();
  const recordRepository = useRecordRepository();
  const { mosaicInfo: mosaicStreamInfo } = playoutStoreDataResolver();
  const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
  const isMosaicIconShow = checkIsMosaicProgram(isOverlay ? mosaicStreamInfo : mosaicInfo);
  const isRecordIconShow = (record || seriesRecord) && isNpvrEnabled;
  const isSeriesRecord = !!seriesRecord || (record && record.isSeriesRecord);
  const { streamOrder, totalStreams } = getMosaicStreamDetails(mosaicStreamInfo, externalId);
  const isMosaicStreamInfoShow = isOverlay && checkIsMosaicProgram(mosaicStreamInfo) && !!streamOrder && !!totalStreams;

  return (
    <div className={styles.title}>
      <h1>
        {title}
        {isMosaicStreamInfoShow && (
          <>
            &nbsp;
            <FormattedMessage
              defaultMessage={`(Stream ${streamOrder}/${totalStreams})`}
              id={`${COMPONENT_NAME}.VideoContent.titleStreamNumber`}
              values={{ number: streamOrder, total: totalStreams }}
            />
          </>
        )}
      </h1>
      {isMosaicIconShow && <SVG className={styles.mosaicIcon} src={`${staticAssetsUrl}${getMDIconPath(MD_ICON)}`} />}
      {isRecordIconShow && <RecordIcon active className={styles.recordIcon} multiple={isSeriesRecord} />}
    </div>
  );
};
