import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { EntityRating } from '@kaltura-ott/tvpil-shared';

import { PurchaseIcon, Rating } from 'components/presenters/icons';

import styles from './SeriesCatchUpCardContent.module.scss';

function CollectionCardContent({
  shouldDisplayRating,
  assetRating,
  shouldDisplayCartIcon,
}: {
  assetRating: EntityRating | undefined;
  shouldDisplayRating: boolean;
  shouldDisplayCartIcon: boolean;
}) {
  return (
    <div className={cn(styles.inner, styles.programAsset)}>
      {shouldDisplayCartIcon && <PurchaseIcon className={styles.cart} isBadge />}
      {shouldDisplayRating && assetRating && (
        <div data-type='ratingInfo'>
          <Rating rating={assetRating} />
        </div>
      )}
    </div>
  );
}

export default observer(CollectionCardContent);
