import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CollectionGridSettings, GridSettings, ParentalPinType } from '@kaltura-ott/tvpil-shared';

import { LAST_URL } from 'consts';
import { useDetectLocation, useParentalPinStore } from 'hooks';

function useGridResetAdultPin(settings: CollectionGridSettings | GridSettings | undefined) {
  const location = useLocation();
  const { isDetails } = useDetectLocation();
  const parentalPinStore = useParentalPinStore();

  useEffect(() => {
    if (!settings) return;

    const prevLocation = sessionStorage.getItem(LAST_URL);
    const isLeavingDetailsPage = Boolean(prevLocation && isDetails(prevLocation) && !isDetails(location.pathname));

    if (isLeavingDetailsPage) {
      const isPinProtected = settings.parentalPinProtected;

      if (!isPinProtected) {
        parentalPinStore.clearParentalPin(ParentalPinType.adult);
      }
    }
  }, [location.pathname, parentalPinStore]);
}

export { useGridResetAdultPin };
