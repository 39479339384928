import { getImageSizeParams } from 'utils/getImageSizeParams';

import { RailSettings } from '../../components/RailProviderV2/types';
import {
  ADDITIONAL_EPISODE_PADDING,
  ADDITIONAL_HEIGHT,
  CARD_PADDING,
  INITIAL_OFFSET,
  TITLE_BLOCK_MARGIN,
} from '../../constants';

export function calculateItemHeight(railSettings: RailSettings, isLogoRail: boolean) {
  const { imageHeight: settingsImageHeight, branding } = railSettings;
  const { imageHeight } = getImageSizeParams(railSettings); // should be removed after fallback params becomes required
  const titleBlockHeight = branding?.fontSize ?? 0 + TITLE_BLOCK_MARGIN;
  let componentHeight = settingsImageHeight || imageHeight;

  if (!isLogoRail) {
    componentHeight += ADDITIONAL_HEIGHT + titleBlockHeight + ADDITIONAL_EPISODE_PADDING;
  }

  // TODO: must be added the logic for railType === WIDGET_TYPE.CW

  return componentHeight;
}

export function calculateTotalWidth(itemLength: number, imageWidth: number) {
  let width = 0;

  for (let i = 0; i < itemLength; i += 1) {
    width += imageWidth + CARD_PADDING;
  }

  return Math.ceil(width) + INITIAL_OFFSET;
}
