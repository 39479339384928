import { useCallback } from 'react';
import { Node } from 'types';

import { RailSettings as RailSettingsTVPIL } from '@kaltura-ott/tvpil-shared';

import { applyStyles } from 'utils';

import { RailSettings } from '../../components/RailProviderV2/types';

export const useApplyStyles = (store: RailSettingsTVPIL | RailSettings) =>
  useCallback((node: Node) => {
    const { branding } = store;

    if (branding && node) {
      applyStyles(node, branding);
    }
  }, []); // eslint-disable-line
