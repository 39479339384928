import React from 'react';
import cn from 'classnames';

import AirTime from 'components/presenters/AirTime/AirTime';

import { useAssetCardContext } from '../../AssetCardContext/AssetCardContext';
import { AssetCardProgramChannelInfo } from '../AssetCardProgramChannelInfo/AssetCardProgramChannelInfo';
import { InfoButton } from '../InfoButton/InfoButton';
import { TitleInner } from '../TitleInner/TitleInner';

import { useHandleClickInfo, useHandleRef } from './hooks';

import styles from './AssetCardAdditionInfo.module.scss';

export function AssetCardAdditionInfo() {
  const {
    resolvedProps: {
      title,
      isRecordIcon,
      isSeriesRecord,
      isMosaic,
      isHero,
      seasonEpisodeTitle,
      isChannelAndAirTimeInfoCondition,
      isChannelInfo,
      isShowAirTime,
      catchUpAirDate,
      isShowedOnlyInfo,
      isShowPlayButton,
    },
  } = useAssetCardContext();
  const { handleClickInfo } = useHandleClickInfo();
  const ref = useHandleRef();

  return (
    <div
      ref={ref}
      data-type='additionInfo'
      onClick={handleClickInfo}
      role='presentation'
      className={cn(styles.textRow, {
        [styles.showPlayButton]: isShowPlayButton,
      })}
    >
      {isShowedOnlyInfo ? (
        <TitleInner title={title} />
      ) : (
        <>
          <div>
            <TitleInner
              isRecord={isRecordIcon}
              isSeriesRecord={isSeriesRecord}
              title={title}
              isMosaicHeroRailProgram={isMosaic && isHero}
            />

            {!!seasonEpisodeTitle && <p className={styles.description}>{seasonEpisodeTitle}</p>}
            {isChannelAndAirTimeInfoCondition && (
              <div>
                {isChannelInfo && (
                  <div className={styles.channel}>
                    <AssetCardProgramChannelInfo />
                  </div>
                )}
                {isShowAirTime && <AirTime className={styles.airTime} catchUpAirDate={catchUpAirDate} isInfo />}
              </div>
            )}
          </div>
          <InfoButton className={styles.infoButton} />
        </>
      )}
    </div>
  );
}
