import React from 'react';
import cn from 'classnames';
import { MasonryScroller } from 'masonic';
import { RenderComponentProps } from 'types';

import { GridCardContainerProps } from '../types';

import { useDynamicGridViewer } from './hooks/useDynamicGridViewer/useDynamicGridViewer';

import styles from './DynamicGridViewer.module.scss';

interface Props<T extends { id: number }> {
  gridId: string;
  className?: string;
  children: ({ imageHeight, itemWidth, data }: GridCardContainerProps<T>) => React.ReactElement;
}

function DynamicGridViewer<T extends { id: number }>({ gridId, className, children }: Props<T>) {
  const {
    isFullLoaded,
    containerRef,
    totalGridHeight,
    windowHeight,
    columnWidth,
    defaultHeight,
    offset,
    gridItems,
    positioner,
    nextPageHandle,
    resizeObserver,
    imageHeight,
    itemWidth,
  } = useDynamicGridViewer(gridId);

  return (
    <div className={cn(styles.wrapper, className, { [styles.fullLoaded]: isFullLoaded })}>
      <MasonryScroller
        className={styles.grid}
        containerRef={containerRef}
        height={isFullLoaded ? totalGridHeight : windowHeight}
        items={gridItems}
        itemStyle={{ width: columnWidth, height: defaultHeight }}
        offset={offset}
        positioner={positioner}
        itemKey={(data, index) => data?.id || index}
        render={({ data }: RenderComponentProps) => {
          return children({ imageHeight, itemWidth, data });
        }}
        onRender={nextPageHandle}
        resizeObserver={resizeObserver}
        overscanBy={3}
        itemHeightEstimate={defaultHeight}
      />
    </div>
  );
}

export { DynamicGridViewer };
