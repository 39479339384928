import React from 'react';

import { WatchedAsset } from '@kaltura-ott/tvpil-shared';

import { Card, CardBubbleInfo, CardInner, CardTitle } from 'components/presenters';
import { WIDGET_TYPE } from 'consts';

import ContinueWatchingCardContent from '../ContinueWatchingCardContent/ContinueWatchingCardContent';

import { useContinueWatchingCard } from './hooks/useContinueWatchingCard/useContinueWatchingCard';

interface Props {
  item: WatchedAsset;
  itemWidth: number;
  imageHeight: number;
}

function ContinueWatchingCard({ item, itemWidth, imageHeight }: Props) {
  const {
    link,
    title,
    isAdult,
    seriesId,
    progress,
    handleRef,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    contentMarker,
    handleLinkRef,
    handleClickInfo,
    defaultImageUrl,
    seasonEpisodeTitle,
    backgroundImageUrl,
    shouldDisplayPlayButton,
    shouldDisplayContentMarker,
  } = useContinueWatchingCard({ item, itemWidth, imageHeight });

  return (
    <Card
      handleLinkRef={handleLinkRef}
      clickHandler={clickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      link={link}
      imageHeight={imageHeight}
      itemWidth={itemWidth}
      isMosaicProgram={false}
    >
      <CardInner
        cardType={WIDGET_TYPE.CW}
        url={backgroundImageUrl}
        name={item.title}
        handleRef={handleRef}
        handleLinkRef={handleLinkRef}
        isAdult={isAdult}
        isNoDataWithNoLogoImg={false}
        defaultImageUrl={defaultImageUrl}
        contentOverlay={
          <ContinueWatchingCardContent
            shouldDisplayContentMarker={shouldDisplayContentMarker}
            contentMarker={contentMarker}
            shouldDisplayPlayButton={shouldDisplayPlayButton}
            progress={progress}
          />
        }
        cardBubbleInfo={
          <CardBubbleInfo
            channelHasProgram
            isAdult={isAdult}
            title={title}
            isMosaicProgram={false}
            handleClickInfo={handleClickInfo}
            programId={item?.id}
            seriesId={seriesId}
            seasonEpisodeTitle={seasonEpisodeTitle}
            isLinkAsAsset={false}
          />
        }
      />
      <CardTitle
        seriesId={seriesId}
        seasonEpisodeTitle={seasonEpisodeTitle}
        isAdult={isAdult}
        isStaticInfo
        title={title}
        isMosaicProgram={false}
        programId={item?.id}
      />
    </Card>
  );
}

export default ContinueWatchingCard;
