import React from 'react';

import { applyStyle } from 'utils';

export function checkTotalGridHeight(
  gridRef: React.MutableRefObject<HTMLDivElement | null>,
  loaded: boolean,
  itemsCount: number,
  columnCount: number,
  itemHeight: number,
) {
  if (gridRef.current && loaded) {
    const totalGridHeight = Math.ceil(itemsCount / columnCount) * itemHeight;
    applyStyle(gridRef.current, '--gridHeight', `${totalGridHeight}px`);
  }
}
