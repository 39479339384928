import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useToastMessageContext } from 'context/toast/toast';

import { Action } from '../enum';

export const useErrorNotice = () => {
  const intl = useIntl();
  const toastMessageContext = useToastMessageContext();

  return useCallback(
    (action: Extract<Action, 'ReorderLineupFailure' | 'RevertLineupFailure' | 'LoadPersonalChannelLineupFailure'>) => {
      const id = `Settings.PersonalLineup.${action}`;

      const title = intl.formatMessage({
        id,
        defaultMessage: 'Action can not be done!',
      });

      toastMessageContext.showToast({
        error: true,
        title,
      });
    },
    [intl, toastMessageContext],
  );
};
