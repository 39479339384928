import React, { useCallback } from 'react';
import { CollectionGridMediaAsset } from 'types';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { CategoryStore, CollectionAsset, CollectionLinkAsset, CollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import { useCardRecordInfo } from 'components/widgets/Card/hooks';
import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalTypesEnum';
import PinCodeModalV2 from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalV2';
import { useRailContext } from 'components/widgets/RailV2/components/RailProvider/RailProvider';
import { LAST_SCROLL_Y_POSITION } from 'consts';
import { useModalContext } from 'context/modal/modal';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useAppFindPageStore, useIsUrlToFullPlayer, useParentalPinStore, useSettingsStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { resolvePathToDetailsPage, userActionEvents } from 'utils';

interface Props {
  data: CollectionAsset | CollectionProgramAsset | undefined;
  link: string;
  mediaTypeParam: string;
  isLinkAsAsset: boolean;
  isPurchasable: boolean;
  collectionType: string | undefined;
  isProgramAsset: boolean;
  isTypeOfSeries: boolean;
  isCollectionEpisode: boolean;
  isCollectionInAppAdultLink: boolean;
  isAdult: boolean;
  isCatchupSeries: boolean;
}

const useSeriesCatchUpClickHandler = ({
  data,
  link,
  mediaTypeParam,
  isLinkAsAsset,
  isPurchasable,
  collectionType,
  isProgramAsset,
  isTypeOfSeries,
  isCollectionEpisode,
  isCollectionInAppAdultLink,
  isAdult,
}: Props) => {
  const { onCardClick } = useRailContext();
  const { findPageStore } = useAppFindPageStore();
  const { navigate } = useStableReactRouter();
  const { settingsStore } = useSettingsStore();
  const parentalPinStore = useParentalPinStore();
  const isUrlToFullPlayer = useIsUrlToFullPlayer(link) || false;
  const { parentalRating } = data as CollectionGridMediaAsset;
  const { isRecord, isSeriesType } = useCardRecordInfo({
    data,
    isProgramAsset,
    isTypeOfSeries,
    isCollectionEpisode,
  });

  // TODO: maybe change ref to css transform state
  const modalContext = useModalContext();

  return useCallback(
    (e: React.MouseEvent<any>) => {
      globalStorage.clearDetailsAsset(); // Temporary solution to avoid opening a detail page with an asset saved in globalStorage

      sessionStorage.setItem(LAST_SCROLL_Y_POSITION, window.scrollY.toString());

      if (onCardClick) {
        onCardClick(data?.id!);
      }

      const isAdultPinNotEntered = !parentalPinStore.isAdultPinEntered;
      const isAdultBlocked = isAdultPinNotEntered && isAdult;
      const isRestricted = !!((isAdult || isCollectionInAppAdultLink) && isAdultPinNotEntered);
      const page = link && (findPageStore(link.split('?')[0]) as CategoryStore);

      if (isRestricted) {
        e.preventDefault();
        e.stopPropagation();

        let type = PinCodeModalTypes.RestrictedContent;
        let inAppLink;

        if (isCollectionInAppAdultLink) {
          type = PinCodeModalTypes.RestrictedContentForLink;
          inAppLink = (data as CollectionLinkAsset)?.inAppLink!;
        }

        modalContext.openModal(
          <PinCodeModalV2
            callback={(success: boolean) => {
              if (success && isCollectionInAppAdultLink && link) {
                navigate(link);
              }
            }}
            parentalRating={parentalRating}
            context={UIDAPresentationTypeEnum.applications}
            inAppLink={inAppLink}
            type={type}
          />,
        );

        return;
      }

      if (isLinkAsAsset) {
        if (page && isAdultBlocked) {
          e.preventDefault();

          modalContext.openModal(
            <PinCodeModalV2
              parentalRating={parentalRating}
              callback={(success: boolean) => {
                if (success && link) {
                  navigate(link);
                }
              }}
              context={UIDAPresentationTypeEnum.applications}
              type={PinCodeModalTypes.RestrictedContent}
            />,
          );
          return;
        }
      }

      if (isPurchasable && !isRecord && !isSeriesType) {
        e.preventDefault();
        if (isLinkAsAsset) return;

        userActionEvents(UserActionType.details, data?.id, mediaTypeParam);

        navigate(
          resolvePathToDetailsPage({
            id: data?.id!,
            mediaTypeParam: mediaTypeParam.toLowerCase(),
            subtype: collectionType,
          }),
        );
        return;
      }

      if (isUrlToFullPlayer && parentalPinStore.isBlockedByParentalRating(parentalRating!)) {
        e.preventDefault();

        modalContext.openModal(
          <PinCodeModalV2
            callback={(success: boolean) => {
              if (success && link) {
                navigate(link);
              }
            }}
            parentalRating={parentalRating}
            context={UIDAPresentationTypeEnum.applications}
            description={parentalRating && <DescriptionWithAge age={parentalRating} />}
            type={PinCodeModalTypes.RestrictedContentParental}
          />,
        );
        return;
      }

      // temp, better render <a> instead of <Link>
      if (link && link.includes('http')) {
        e.preventDefault();
        window.open(link, '_blank');
      }

      if (link && link.includes('details')) {
        // to send the user event after clicking on an AssetCardLink which should open the details page
        userActionEvents(UserActionType.details, data?.id, mediaTypeParam);
      }
    },
    [modalContext, link, parentalRating, isRecord, isSeriesType, settingsStore, isCollectionInAppAdultLink, isAdult],
  );
};

export { useSeriesCatchUpClickHandler };
