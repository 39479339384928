import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { MediaType } from '@kaltura-ott/tvpil';
import {
  getWatchedAssetContentMarker,
  getWatchedAssetEpisodeIndication,
  getWatchedAssetTitle,
  getWatchingProgress,
  isLiveProgram,
  isWatchedEpisodeAsset,
  isWatchedMovieAsset,
  isWatchedProgramAsset,
  isWatchedRecordingAsset,
  WatchedAsset,
  WatchedEpisodeAsset,
  WatchedProgramAsset,
  WatchedRecordingAsset,
} from '@kaltura-ott/tvpil-shared';

import { useMouseEnterHandle, useMouseLeaveHandle } from 'components/presenters/Card/hooks';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { useWidgetContext } from 'context/widgetProvider/widgetProvider';
import { getSeasonEpisodeTitleV2 } from 'utils';

import { useClickHandler } from '../useClickHandler/useClickHandler';
import { useContinueWatchingCardImages } from '../useContinueWatchingCardImages';
import { useContinueWatchingCardLink } from '../useContinueWatchingCardLink/useContinueWatchingCardLink';
import { useContinueWatchingCardRef } from '../useContinueWatchingCardRef';
import { useHandleClickInfo } from '../useHandleClickInfo';

interface Props {
  item: WatchedAsset;
  itemWidth: number;
  imageHeight: number;
}

function useContinueWatchingCard({ item, itemWidth, imageHeight }: Props) {
  const intl = useIntl();
  const {
    settings: { imageType, shouldGroupEpisodes },
    imageWidth,
  } = useWidgetContext();
  // using version v2 of the rail provider
  const { onHover, nextButtons } = useRailContext();
  const programType = MediaType.Program.toLowerCase();
  const isCwEpisode = isWatchedEpisodeAsset(item);
  const isCwVod = isWatchedMovieAsset(item) || isCwEpisode;
  const isCwProgram = isWatchedProgramAsset(item);
  const isCwLive = isCwProgram && isLiveProgram(item);
  const isCwRecording = isWatchedRecordingAsset(item);
  const isGroupedCwEpisode = Boolean(isCwEpisode && shouldGroupEpisodes);
  const isMosaicProgram = Boolean((isCwProgram || isCwRecording) && item.hasMosaic);
  const assetWithSeriesId = item as WatchedEpisodeAsset | WatchedProgramAsset | WatchedRecordingAsset;

  // seriesId definition
  const { seriesId } = assetWithSeriesId;

  const { link } = useContinueWatchingCardLink({
    item,
    isCwVod,
    isCwLive,
    seriesId,
    isCwRecording,
    isMosaicProgram,
    isGroupedCwEpisode,
  });

  // adults
  const isAdult = Boolean(item?.isAdult);
  // refs
  const handleLinkRef = useRef(null);
  const { ref, handleRef } = useContinueWatchingCardRef(itemWidth, imageHeight);
  // handlers
  const onMouseEnter = useMouseEnterHandle({ ref, itemWidth, imageHeight, isGrid: false, onHover, nextButtons });
  const onMouseLeave = useMouseLeaveHandle(onHover);
  const clickHandler = useClickHandler({
    link,
    data: item,
    mediaTypeParam: item.type,
  });
  const { handleClickInfo } = useHandleClickInfo({
    seriesId,
    id: item.id,
    isCwRecording,
    isMosaicProgram,
    isGroupedCwEpisode,
    externalId: undefined,
    mediaTypeParam: isCwRecording ? programType : item.type,
  });
  // images urls
  const { backgroundImageUrl, defaultImageUrl } = useContinueWatchingCardImages({
    item,
    imageType,
    imageWidth,
    imageHeight,
  });
  // title
  const title = getWatchedAssetTitle(item);

  // prograssBar percentage
  const progress = getWatchingProgress(item);

  // season episode title
  let seasonEpisodeTitle = '';

  if (isCwProgram || isCwEpisode) {
    const seasonEpisodeMeta = getWatchedAssetEpisodeIndication(item);
    seasonEpisodeTitle = getSeasonEpisodeTitleV2(intl, seasonEpisodeMeta, ':', true);
  }

  // content marker
  const contentMarkerData = isCwVod ? getWatchedAssetContentMarker(item) : undefined;
  const contentMarker = contentMarkerData
    ? {
        text: contentMarkerData?.text,
        background: contentMarkerData?.backgroundColor,
        color: contentMarkerData?.textColor,
      }
    : undefined;
  const shouldDisplayContentMarker = Boolean(isCwVod && contentMarker);

  return {
    link,
    title,
    isAdult,
    seriesId,
    progress,
    handleRef,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    contentMarker,
    handleLinkRef,
    handleClickInfo,
    defaultImageUrl,
    seasonEpisodeTitle,
    backgroundImageUrl,
    shouldDisplayContentMarker,
    shouldDisplayPlayButton: !isGroupedCwEpisode,
  };
}

export { useContinueWatchingCard };
