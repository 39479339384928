import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { KuxImages } from '@kaltura-ott/tvpil';
import { Linear } from '@kaltura-ott/tvpil-shared';

import { useAppStore, useDeviceStore, useParentalPinStore } from 'hooks';
import { checkInstanceOf, playoutStoreDataResolver } from 'utils';

export const useResolveConditionsVideoPlayout = () => {
  const {
    appStore: { playoutStoreV2: playoutStore },
  } = useAppStore();
  const parentalPinStore = useParentalPinStore();
  const intl = useIntl();
  const { mosaicAsset } = playoutStoreDataResolver();
  const { householdDevice } = useDeviceStore();
  const [channelLogoError, setChannelLogoError] = useState(false);
  const setChannelLogoErrorForward = useCallback((val: boolean) => setChannelLogoError(val), []);

  const isHouseholdDeviceNotEmpty = !!householdDevice?.householdId;
  const currentAsset = playoutStore?.currentAsset as Linear;
  const program = currentAsset?.currentProgram;
  const isContentBlockedAsAdult = !!program?.data?.adult && parentalPinStore.isBlockByAdult(program);
  const assetId = currentAsset?.id;
  const seriesId = program?.data?.seriesId;
  const seriesName = program?.data?.tags?.SeriesName && program?.data?.tags.SeriesName[0];
  const isMosaicProgram = program?.data?.mosaicInfo?.isMosaicEvent === '1';
  const isContentGeoBlocking = currentAsset?.isGeoBlocked;

  let logoImages: KuxImages = currentAsset?.data?.images;
  let programName: string | undefined = program?.linear?.data.title;
  let programTitle = program?.data?.title;
  let parentalRating = program?.data?.parentalRating || 0;

  if (program?.data?.isNotFound) {
    parentalRating = currentAsset.parentalRating;
    programTitle = intl.formatMessage({ id: 'VideoPlayout.NoData', defaultMessage: 'No Data Received' });
  }

  if (seriesId) {
    programTitle = seriesName || program?.data?.title;
  }

  if (isMosaicProgram) {
    logoImages = playoutStore?.mosaicAsset?.data?.images!;
    programName = playoutStore?.mosaicAsset?.data?.title;
    programTitle = mosaicAsset?.data?.title;
  }

  useEffect(() => {
    setChannelLogoError(false);
  }, [logoImages]);

  const isContentBlockedByAge = !!parentalRating && !!program && parentalPinStore.isBlockByParentRating(program);
  const isContentRestricted = isContentBlockedAsAdult || isContentBlockedByAge;
  const isPlayerCondition = Boolean(!isContentRestricted && !isContentGeoBlocking && isHouseholdDeviceNotEmpty);
  const isLinear = checkInstanceOf(currentAsset, Linear);

  const isLinearWithoutCurrentProgram = isLinear && !currentAsset?.currentProgram;
  const isImagesCondition = logoImages && !channelLogoError;
  const isImagesErrorCondition = !logoImages || channelLogoError;
  const isNoProgramCondition = Boolean(!program && currentAsset?.data);

  return {
    currentAsset,
    isContentBlockedAsAdult,
    program,
    assetId,
    programTitle,
    parentalRating,
    logoImages,
    programName,
    isPlayerCondition,
    isContentRestricted,
    isContentGeoBlocking,
    isImagesErrorCondition,
    isLinearWithoutCurrentProgram,
    isMosaicProgram,
    isImagesCondition,
    isLinear,
    isNoProgramCondition,
    isContentBlockedByAge,
    setChannelLogoErrorForward,
  };
};
