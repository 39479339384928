import React from 'react';

import { CollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import { Card, CardBubbleInfo, CardInner, CardTitle } from 'components/presenters';

import SeriesCatchUpCardContent from './components/SeriesCatchUpCardContent/SeriesCatchUpCardContent';
import { useSeriesCatchUpCard } from './hooks/useSeriesCatchUpCard/useSeriesCatchUpCard';

interface Props {
  seriesCatchup: CollectionProgramAsset;
  itemWidth: number;
  imageHeight: number;
}

function SeriesCatchUpCard({ seriesCatchup, itemWidth, imageHeight }: Props) {
  const {
    id,
    link,
    title,
    isAdult,
    seriesId,
    handleRef,
    assetRating,
    channelName,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    isLinkAsAsset,
    handleLinkRef,
    handleClickInfo,
    isDisplayRating,
    defaultImageUrl,
    channelHasProgram,
    backgroundImageUrl,
    seasonEpisodeTitle,
    shouldDisplayCartIcon,
  } = useSeriesCatchUpCard({ seriesCatchup, itemWidth, imageHeight });

  return (
    <Card
      handleLinkRef={handleLinkRef}
      clickHandler={clickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      link={link}
      imageHeight={imageHeight}
      itemWidth={itemWidth}
      isMosaicProgram={false}
    >
      <CardInner
        cardType='collection'
        url={backgroundImageUrl}
        name={title}
        handleRef={handleRef}
        handleLinkRef={handleLinkRef}
        isAdult={isAdult}
        isNoDataWithNoLogoImg={false}
        defaultImageUrl={defaultImageUrl}
        contentOverlay={
          <SeriesCatchUpCardContent
            shouldDisplayCartIcon={shouldDisplayCartIcon}
            shouldDisplayRating={isDisplayRating}
            assetRating={assetRating}
          />
        }
        cardBubbleInfo={
          <CardBubbleInfo
            channelHasProgram={channelHasProgram}
            seasonEpisodeTitle={seasonEpisodeTitle}
            channelName={channelName}
            isAdult={isAdult}
            title={title}
            isMosaicProgram={false}
            handleClickInfo={handleClickInfo}
            programId={id}
            seriesId={seriesId}
            isLinkAsAsset={isLinkAsAsset}
          />
        }
      />
      <CardTitle
        seriesId={seriesId}
        seasonEpisodeTitle={seasonEpisodeTitle}
        isAdult={isAdult}
        isStaticInfo
        title={title}
        isMosaicProgram={false}
        programId={seriesCatchup?.id}
      />
    </Card>
  );
}

export default SeriesCatchUpCard;
