import { useEffect, useRef } from 'react';

import { containsImage, TopShelfAsset, TopShelfSettings, useTopShelf } from '@kaltura-ott/tvpil-shared';

import { MIN_NUMBER_OF_ITEMS } from 'components/widgets/RailV2/TopShelfRail/constants';
import { topShelfStorage } from 'components/widgets/RailV2/TopShelfRail/topShelfStorage';

/**
 * `useTopShelfContainerCombiner` is a custom React hook used for retrieving and managing data necessary data
 * for displaying a top shelf rail in a web application.
 *
 * @param referenceId - A unique identifier of the top shelf rail widget.
 * @param settings - A rail settings.
 *
 * @returns An object containing:
 * - `focusAssetRef`: focused asset.
 * - `isRailHidden`: logical flag for displaying of element during conditions: the number of assets more 4, there is not error, the rail si not empty.
 * - `isLoading`: logical flag for displaying of element until loading.
 * - `listOfItems`: An array structured as `TopShelfMovieAsset | TopShelfProgramAsset | TopShelfEpisodeAsset | TopShelfSeriesAsset`,
 * total count, and loading state.
 */

function useTopShelfContainerCombiner(referenceId: string, settings: TopShelfSettings) {
  const focusAssetRef = useRef<TopShelfAsset>();
  const { isLoading, items, error } = useTopShelf({ referenceId });
  const { imageType, coverImageType } = settings;

  const listOfItems = items.filter((item) =>
    containsImage({
      images: item.images,
      types: [imageType!, coverImageType!],
    }),
  );
  const isRailHidden = error || listOfItems.length < MIN_NUMBER_OF_ITEMS || (!listOfItems.length && !isLoading);

  useEffect(() => {
    return () => topShelfStorage.clear();
  }, []);

  return { listOfItems, focusAssetRef, isLoading, isRailHidden };
}

export { useTopShelfContainerCombiner };
