import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { TopShelfAsset, useTimer } from '@kaltura-ott/tvpil-shared';

import { topShelfStorage } from 'components/widgets/RailV2/TopShelfRail/topShelfStorage';

import { DEFAULT_SHOW_INDEX, DURATION_OF_DISABLING_ANIMATION } from '../../constants';
import { useCardCssProps } from '../../hooks/useCardCssProps/useCardCssProps';
import { ControlsProps } from '../../types';

/**
 * `CarouselUpdateLogic` is a pseudo React component used for managing/updating data necessary and for avoiding redundant rerenders
 *
 * @param autoTransitionDelay - a number of delay from settings. *
 * @param items - array of assets.
 * @param settings - a rail settings.
 * @param isAutoPlay - a boolean flag.
 * @param focusAssetRef - a focused asset.
 * @param controlsRef - a mutable react ref object for storing a methods.
 * @param carouselRef - a mutable react ref object for sharing a link for the parent element.
 *
 */

interface Props {
  autoTransitionDelay: number;
  isAutoPlay?: boolean;
  items: TopShelfAsset[];
  focusAssetRef: React.MutableRefObject<TopShelfAsset | undefined>;
  controlsRef: React.MutableRefObject<ControlsProps>;
  carouselRef: React.RefObject<HTMLDivElement>;
}

function CarouselUpdateLogic({
  autoTransitionDelay,
  isAutoPlay,
  focusAssetRef,
  items,
  controlsRef,
  carouselRef,
}: Props) {
  const [currentIndex, setCurrentIndex] = useState(DEFAULT_SHOW_INDEX);
  const prev = useCallback(() => {
    if (!topShelfStorage.isAnimating) {
      topShelfStorage.setIsAnimating(true);
      setCurrentIndex((prevValue) => {
        const index = prevValue === 0 ? items?.length! - 1 : prevValue - 1;

        return index;
      });
    }
  }, []);
  const next = useCallback(() => {
    if (!topShelfStorage.isAnimating) {
      topShelfStorage.setIsAnimating(true);
      setCurrentIndex((prevValue) => {
        const index = prevValue < items?.length! - 1 ? prevValue + 1 : 0;

        return index;
      });
    }
  }, []);
  const handleTimerTick = useCallback(() => {
    if (!topShelfStorage.isAnimating && isAutoPlay) {
      next();
    }
  }, []);
  const { reset, start } = useTimer({ interval: autoTransitionDelay!, onTimerTick: handleTimerTick });
  const clickArrowHandle = useCallback((isNext: boolean) => {
    if (isNext) {
      next();
    } else {
      prev();
    }

    reset();
    start();
  }, []);
  const { imageHeight, isLandscape } = topShelfStorage.cardParams;
  const cardsNumber = items?.length!;
  const setCardCssProps = useCardCssProps({
    imageHeight,
    isLandscape,
    currentIndex,
    cardsNumber,
  });

  useEffect(() => {
    if (carouselRef.current && imageHeight) {
      [...carouselRef.current.children].forEach((item: HTMLDivElement | any, index: number) => {
        if (item) {
          setCardCssProps(index, item);
        }
      });
    }
  }, [currentIndex, imageHeight, isLandscape]);

  useEffect(() => {
    controlsRef.current = {
      clickArrowHandle,
    };
  }, []);

  useEffect(() => {
    topShelfStorage.setIsUpdated();
    const resetAnimation = setTimeout(() => {
      topShelfStorage.setIsAnimating(false);
    }, DURATION_OF_DISABLING_ANIMATION);

    if (items) {
      focusAssetRef.current = items[currentIndex];
    }

    return () => clearTimeout(resetAnimation);
  }, [currentIndex]);

  return null;
}

export default observer(CarouselUpdateLogic);
