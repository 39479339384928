import React from 'react';

import { CollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import { CardSkeleton } from '../../CardSkeleton/CardSkeleton';
import { CardContainerProps } from '../../types';
import SeriesCatchUpCard from '../SeriesCatchUpCard/SeriesCatchUpCard';

function SeriesCatchUpCardContainer({ data, itemWidth, imageHeight }: CardContainerProps<CollectionProgramAsset>) {
  if (!data?.id) {
    return <CardSkeleton height={imageHeight} width={itemWidth} />;
  }
  return <SeriesCatchUpCard imageHeight={imageHeight} itemWidth={itemWidth} seriesCatchup={data} />;
}

export default React.memo(SeriesCatchUpCardContainer);
