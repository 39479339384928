import React from 'react';
import SVG from 'react-inlinesvg';

import { SUBTITLES_ICON, VOICE_ICON } from 'consts';
import { useVideoContext } from 'context/videoContext/videoContext';
import { useAppStore } from 'hooks';
import { getSubtitlesIcon, getVoiceIcon } from 'utils';

import styles from './Localization.module.scss';

export const Localization = () => {
  const { program } = useVideoContext();
  const audioLanguages = program.getAudioLanguages();
  const subtitleLanguages = program.getSubtitleLanguages();
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  return (
    <div className={styles.localization}>
      {audioLanguages.length > 0 && (
        <div className={styles.language}>
          <SVG src={`${staticAssetsUrl}${getVoiceIcon(VOICE_ICON)}`} />
          <span>{audioLanguages.join(', ')}</span>
        </div>
      )}
      {subtitleLanguages.length > 0 && (
        <div className={styles.language}>
          <SVG src={`${staticAssetsUrl}${getSubtitlesIcon(SUBTITLES_ICON)}`} />
          <span>{subtitleLanguages.join(', ')}</span>
        </div>
      )}
    </div>
  );
};
