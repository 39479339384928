import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useVideoContext } from 'context/videoContext/videoContext';
import { getSynopsis, isFullSynopsis } from 'utils';
import { getSeasonEpisodeTitle } from 'utils/getSeasonEpisodeTitle';

import { COMPONENT_NAME } from '../../../../constants';
import detailsStoreDataResolver from '../../../../utils/detailsStoreDataResolver';

import styles from './EpisodeInfo.module.scss';

export const EpisodeInfo = () => {
  const { program } = useVideoContext();

  const {
    data: { description, metas },
  } = program;
  const { episodeName } = detailsStoreDataResolver(program);

  const [synopsis, setSynopsis] = useState(getSynopsis(description));
  const [openSynopsis, setOpen] = useState(isFullSynopsis(description));

  const intl = useIntl();

  const seasonEpisodeTitle = getSeasonEpisodeTitle(intl, metas, ':', true, episodeName);

  const openFullSynopsis = () => {
    setOpen(true);
    setSynopsis(description);
  };

  return (
    <p className={styles.synopsis}>
      {seasonEpisodeTitle && <strong>{seasonEpisodeTitle} | </strong>}
      {synopsis}
      {!openSynopsis && (
        <button className={styles.more} onClick={openFullSynopsis} type='button'>
          <FormattedMessage defaultMessage='More' id={`${COMPONENT_NAME}.VideoContent.buttonMore`} />
        </button>
      )}
    </p>
  );
};
