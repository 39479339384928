import { EDGE } from '../../constants';

export function getScaleAndTranslateX(
  currentIndex: number,
  i: number,
  isLandscape: boolean,
  cardsNumber: number,
  minLeftPosition: number,
  isMinNumbersOfCards: boolean,
) {
  const even = i % 2 === 0;
  const differ = i - currentIndex;
  const extra = currentIndex + EDGE + 1 - cardsNumber; // remaining number of items on the right side
  // the index of element from array is equal the number of active element
  if (i === currentIndex) {
    return { scale: 1, translateX: 0, differ };
  }

  // the index of element from array less than the number of active element
  if (i < currentIndex) {
    // for emulating of first position in the rail
    if (i === currentIndex - 1) {
      return { scale: 0.8, translateX: minLeftPosition, differ };
    }

    // when the first element from array becomes last visible in the rail
    if (extra - 1 === i) {
      const scale = isLandscape ? 0.7 : 0.58;
      const translateX = isLandscape ? 92 : 52;

      return { scale, translateX, differ: 2 };
    }

    if (extra > 0 && i < extra) {
      if (i === 0) {
        const translateX = isLandscape ? 48 : 33;
        return { scale: 0.8, translateX, differ: 1 };
      }

      if (i === 1) {
        const scale = isLandscape ? 0.7 : 0.58;
        const translateX = isLandscape ? 92 : 52;

        return { scale, translateX, differ: 2 };
      }
    }

    return { scale: 0, translateX: 0, differ };
  }

  // the index of element from array more than the number of active element
  if (i > currentIndex) {
    if (i > currentIndex + EDGE) {
      const extraLocal = currentIndex - EDGE;

      if (extraLocal < 0 && i >= cardsNumber + extraLocal && !even) {
        const differLocal = i - cardsNumber - currentIndex;
        const scale = 1 - 0.1 + differLocal * 0.1;

        return { scale, translateX: minLeftPosition, differ: differLocal };
      }

      const scale = isMinNumbersOfCards ? 0.8 : 0.1;
      const translateX = isMinNumbersOfCards ? minLeftPosition : 0;

      return { scale, translateX, differ };
    }

    let scale = 0.8;
    let shift = 40;

    if (isLandscape) {
      if (i - currentIndex !== 1) {
        scale = 0.7;
        shift = 60;
      } else {
        shift = 55;
      }
    } else if (i - currentIndex !== 1) {
      scale = 0.58;
    }

    const translateX = `${-7 * (currentIndex - i) ** 2 + shift * (i - currentIndex)}`;

    return { scale, translateX, differ };
  }

  return { scale: 0, translateX: 0, differ };
}
