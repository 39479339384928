import React from 'react';
import cn from 'classnames';

import { CollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import SeriesCatchUpCardContainer from 'components/widgets/CardView/SeriesCatchUp/SeriesCatchUpCardContainer/SeriesCatchUpCardContainer';
import DynamicRailViewer from 'components/widgets/RailV2/components/DynamicRailViewer/DynamicRailViewer';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import Title from 'components/widgets/RailV2/components/Title/Title';
import { useApplyStyles } from 'components/widgets/RailV2/hooks/useApplyStyles/useApplyStyles';
import { useWidgetContext } from 'context/widgetProvider/widgetProvider';

import styles from './SeriesCatchUpRailContainer.module.scss';

function SeriesCatchUpRailContainer() {
  const { wrapperRef, refElementInner, componentHeight } = useRailContext();
  const { settings, data } = useWidgetContext<CollectionProgramAsset>();

  const handleRef = useApplyStyles(settings);

  const { hasNextPage, totalCount, items } = data;

  return (
    <div ref={handleRef} className={cn(styles.container)}>
      <div ref={refElementInner}>
        <Title
          hasNextPage={Boolean(hasNextPage)}
          totalCount={totalCount}
          itemsLength={items.length}
          forwardHeaderRef={handleRef}
          railSettings={settings}
        />
        <div
          ref={wrapperRef}
          style={{
            height: componentHeight,
          }}
        >
          <DynamicRailViewer<CollectionProgramAsset> railData={data}>
            {({ imageHeight, itemWidth, item }) => (
              <SeriesCatchUpCardContainer imageHeight={imageHeight} itemWidth={itemWidth} data={item} />
            )}
          </DynamicRailViewer>
        </div>
      </div>
    </div>
  );
}

export default SeriesCatchUpRailContainer;
