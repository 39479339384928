import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { CollectionProgramAsset, getProgramAiringDate } from '@kaltura-ott/tvpil-shared';

import LocalTime from 'components/presenters/LocalTime/LocalTime';
import { useAppStore } from 'hooks';
import { DateFormatStyle, formatDate } from 'utils/dateTime';

import styles from './AirTime.module.scss';

export type Props = {
  data: CollectionProgramAsset;
  className?: string;
  isInfo?: boolean;
};

function AirTimeV2({ data, className = '', isInfo = false }: Props) {
  const {
    appStore: {
      localizationStore: { displayLanguage, latinLanguage },
    },
  } = useAppStore();
  const locale = latinLanguage || displayLanguage;
  const { label, startDate } = getProgramAiringDate(data);

  if (!startDate || Number.isNaN(Number(startDate))) {
    return null;
  }

  return (
    <div className={cn(styles.airTime, className)}>
      {label && (
        <>
          <FormattedMessage defaultMessage={`${label}`} id={`Rail.RailCard.AirTime.${label}`} tagName='span' />{' '}
          <LocalTime date={startDate} />
        </>
      )}
      {!label && startDate > Date.now() && !isInfo && (
        <>
          <span>{formatDate(startDate, DateFormatStyle.OnlyLongWeekday, locale)}</span>
          <span>{formatDate(startDate, DateFormatStyle.WithoutWeekday, locale)}</span>
        </>
      )}
      {!label && (startDate < Date.now() || isInfo) && (
        <>
          <span>{formatDate(startDate, DateFormatStyle.WithLongWeekday, locale)}</span>
          <LocalTime date={startDate} />
        </>
      )}
    </div>
  );
}

export default AirTimeV2;
