export enum Action {
  ReorderLineupFailure = 'ReorderLineupFailure',
  ReorderLineupSuccess = 'ReorderLineupSuccess',

  RevertLineupFailure = 'RevertLineupFailure',
  RevertLineupSuccess = 'RevertLineupSuccess',

  LoadPersonalChannelLineupFailure = 'LoadPersonalChannelLineupFailure',
}

export enum DndAction {
  Idle = 'idle',
  Dragging = 'dragging',
  Over = 'over',
}
