import { useCallback } from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { UserActionType } from '@kaltura-ott/tvpil-analytics';

import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { resolveLinkForMd, resolvePathToDetailsPageV2, userActionEvents } from 'utils';

interface Props {
  id: number;
  mediaTypeParam: string;
  seriesId: string | undefined;
  externalId: string | undefined;
  isCwRecording: boolean;
  isMosaicProgram: boolean;
  isGroupedCwEpisode: boolean;
}

export const useHandleClickInfo = ({
  id,
  seriesId,
  mediaTypeParam,
  externalId,
  isCwRecording,
  isMosaicProgram,
  isGroupedCwEpisode,
}: Props) => {
  const { navigate, location } = useStableReactRouter();
  const { onCardClick } = useRailContext();
  const { state } = location;
  // handle click info button on hero card to navigate to details page
  const handleClickInfo = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (onCardClick) {
        onCardClick(id);
      }

      userActionEvents(UserActionType.details, id, mediaTypeParam);

      let link = resolvePathToDetailsPageV2({
        id,
        seriesId,
        externalId,
        mediaTypeParam,
        isProgramRecord: isCwRecording,
        isSuppressedEpisode: isGroupedCwEpisode,
        collectionType: UIDAPresentationTypeEnum.cw,
      });

      link += resolveLinkForMd(isMosaicProgram, link); // to avoid displaying MD components in the player

      navigate(link, { state: { goBackUrl: state?.goBackUrl } });
    },
    [id],
  );

  return { handleClickInfo };
};
