import React from 'react';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import { CardSkeleton } from '../../CardSkeleton/CardSkeleton';
import { CardContainerProps } from '../../types';
import CollectionCard from '../CollectionCard/CollectionCard';

function CollectionCardContainer({ data, itemWidth, imageHeight }: CardContainerProps<CollectionAsset>) {
  if (!data?.id) {
    return <CardSkeleton height={imageHeight} width={itemWidth} />;
  }
  return <CollectionCard imageHeight={imageHeight} itemWidth={itemWidth} item={data} />;
}

export default React.memo(CollectionCardContainer);
