import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import { useVideoContext } from 'context/videoContext/videoContext';
import { useRootStore } from 'hooks';
import { useDetectLocation } from 'hooks/common/useDetectLocation';
import { resolveMillisecondsToMinutes } from 'utils';

import styles from './SpecificationsDetails.module.scss';

type Props = {
  duration?: number;
};

// TODO: need to use the SpecificationsDetails element from presenters after refactoring of details page on new api
export const SpecificationsDetails = ({ duration }: Props) => {
  const intl = useIntl();
  const rootStore = useRootStore();
  const { program } = useVideoContext();
  const { isDetailsSeries } = useDetectLocation();
  const genres = program.getGenres();
  const countries = program.getCountries().length > 0 ? program.getCountries() : program.getCountriesOfOrigin();
  const { metas } = program.data;
  const { seriesAdditionalRail } = rootStore?.detailsStore!;
  const isDurationShow = !isDetailsSeries && duration && !Number.isNaN(duration);
  const seasonsCount = seriesAdditionalRail?.seasonsCount;
  const isSeasonCountShow = isDetailsSeries && !!seasonsCount;

  return (
    <div className={styles.specificationsDetails}>
      {genres.length > 0 && <div className={styles.genre}>{genres.join(', ')}</div>}
      {metas?.Year && <div>{String(metas.Year)}</div>}
      {countries.length > 0 && <div>{countries.join(', ')}</div>}
      {isDurationShow && <span>{resolveMillisecondsToMinutes(duration!)}</span>}
      {isSeasonCountShow && (
        <div>
          <FormattedMessage
            defaultMessage='{value, plural, one {# season} other {# seasons}}'
            id={`${COMPONENT_NAME}.VideoContent.seasonsCount.${intl.formatPlural(seasonsCount!)}`}
            values={{ value: seasonsCount }}
          />
        </div>
      )}
    </div>
  );
};
