import { useEffect } from 'react';
import { Location } from 'react-router';

import { useAppStore } from 'hooks';

export const useRebootApp = (
  location: Location,
  targetRoute: string | null,
  setTargetRoute: (route: string | null) => void,
) => {
  const { appStore } = useAppStore();

  useEffect(() => {
    const effect = async () => {
      await appStore.rebootApp(true);
    };

    if (location.pathname === targetRoute) {
      effect();
      setTargetRoute(null);
    }
  }, [appStore, location, targetRoute, setTargetRoute]);
};
