import React from 'react';
import { useIntl } from 'react-intl';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import PinCodeModal from 'components/widgets/Modal/PinCodeModal/PinCodeModal';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModal/PinCodeModalTypesEnum';
import { ContextType as ErrorScreenContextType } from 'context/error/types';
import { useParentalPinStore } from 'hooks';

import PinCodeDetailsDisclaimer from './components/PinCodeDetailsDisclaimer/PinCodeDetailsDisclaimer';
import PinCodeDetailsInner from './components/PinCodeDetailsInner/PinCodeDetailsInner';
import { useOnRentCombiner } from './hooks';

import styles from './PinCodeModalDetails.module.scss';

function PinCodeModalDetails({
  asset,
  duration,
  price,
  optionIndex,
  switchPurchaseRentLoading,
  context,
  errorScreenContext,
}: {
  asset: ViewableEntitiesType;
  duration: number;
  price: string | JSX.Element;
  optionIndex: number;
  switchPurchaseRentLoading(flag: boolean): void;
  context: UIDAPresentationTypeEnum;
  errorScreenContext: ErrorScreenContextType;
}) {
  const { validatePurchasePin } = useParentalPinStore();
  const intl = useIntl();
  const { rents, title } = detailsStoreDataResolver(asset);
  const rentOption = rents[optionIndex];
  const { onPurchase } = useOnRentCombiner({
    asset,
    switchPurchaseRentLoading,
    context,
    rentOption,
    errorScreenContext,
  });

  return (
    <PinCodeModal
      afterPinCodeContent={<PinCodeDetailsDisclaimer />}
      asset={asset}
      callback={async (success: boolean) => {
        if (!success) {
          return;
        }
        onPurchase();
      }}
      className={styles.content}
      description={<PinCodeDetailsInner assetName={title} price={price} duration={duration} />}
      title={intl.formatMessage({
        id: `${COMPONENT_NAME}.VideoContent.PinCodeModalDetails.title`,
        defaultMessage: 'Enter PIN code',
      })}
      type={PinCodeModalTypes.Purchase}
      validatePin={validatePurchasePin}
      context={context}
    />
  );
}

export default PinCodeModalDetails;
