import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { ContentMarkerType } from 'types';

import { CollectionAsset, CollectionProgramAsset, EntityRating } from '@kaltura-ott/tvpil-shared';

import AirTimeV2 from 'components/presenters/AirTimeV2/AirTimeV2';
import { ContentMarker, PurchaseIcon, Rating } from 'components/presenters/icons';
import PlayButton from 'components/widgets/buttons/PlayButton/PlayButton';

import styles from './CollectionCardContent.module.scss';

function CollectionCardContent({
  shouldDisplayPlayButton,
  shouldDisplayCartIcon,
  isDisplayRating,
  isFutureProgram,
  assetRating,
  data,
  isDisplayContentMarker,
  contentMarker,
}: {
  assetRating: EntityRating;
  shouldDisplayPlayButton: boolean;
  shouldDisplayCartIcon: boolean;
  isDisplayRating: boolean;
  isFutureProgram: boolean;
  data: CollectionAsset;
  isDisplayContentMarker: boolean;
  contentMarker: ContentMarkerType | undefined;
}) {
  return (
    <div className={cn(styles.inner, styles.programAsset)}>
      {shouldDisplayCartIcon && <PurchaseIcon className={styles.cart} isBadge />}
      {isDisplayContentMarker && <ContentMarker className={styles.contentMarker} contentMarker={contentMarker!} />}
      {isFutureProgram && (
        <div data-type='futureProgram' className={styles.futureProgram}>
          <AirTimeV2 data={data as CollectionProgramAsset} />
        </div>
      )}
      {isDisplayRating && (
        <div data-type='ratingInfo'>
          <Rating rating={assetRating} />
        </div>
      )}
      {shouldDisplayPlayButton && <PlayButton data-type='playButton' className={styles.playBtn} />}
    </div>
  );
}

export default observer(CollectionCardContent);
