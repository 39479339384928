import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { LoadingStateIcon } from 'components/presenters/icons/LoadingStateIcon/LoadingStateIcon';
import { ActionButtonProps, ActionButtonTypesEnum } from 'interface';

import styles from './ActionButton.module.scss';

const ActionButton: React.FC<ActionButtonProps> = ({
  className,
  icon,
  submit,
  isPending,
  type = ActionButtonTypesEnum.secondary,
  title = '',
  onClick,
  dataType,
  to,
  children,
  isLarge,
  disabled,
  target,
  replace = false,
  forwardRef,
  ...rest
}) => {
  const classes = cn(className, styles.actionButton, {
    [styles.large]: isLarge,
    [styles.link]: !!to,
    [styles.disabled]: !!disabled,
    [styles.loadingState]: isPending,
    // apply styles based on provided type
    [styles.danger]: type === ActionButtonTypesEnum.danger,
    [styles.primary]: type === ActionButtonTypesEnum.primary,
    [styles.secondary]: type === ActionButtonTypesEnum.secondary,
  });

  let Tag: any = 'button'; // TODO: add some type like: string | LinkType
  let linkAttr = {};

  if (to) {
    Tag = typeof to === 'string' && to.startsWith('http') ? 'a' : Link;
    linkAttr = Tag === 'a' ? { href: to, target } : { to, replace };
  }

  const resolveType = Tag === 'button' && (submit ? { type: 'submit' } : { type: 'button' });

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Tag
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      aria-label={title}
      className={classes}
      ref={forwardRef}
      {...(Tag !== 'a' ? { disabled: !!disabled } : {})} // eslint-disable-line react/jsx-props-no-spreading
      data-type={dataType}
      onClick={handleClick}
      title={title}
      {...linkAttr} // eslint-disable-line react/jsx-props-no-spreading
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...resolveType}
    >
      {icon}
      {isPending && <LoadingStateIcon className={styles.loadingIcon} />}
      <span className={styles.content}>{children}</span>
    </Tag>
  );
};

export default ActionButton;
