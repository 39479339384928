import React from 'react';
import { WidgetSettings } from 'types';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import { DynamicGridViewer } from 'components/pages/Grids/DynamicGridViewer/DynamicGridViewer';
import CollectionCardContainer from 'components/widgets/CardView/Collection/CollectionCardContainer/CollectionCardContainer';
import WidgetProvider from 'context/widgetProvider/widgetProvider';

import { useCollectionGrid } from './hooks/useCollectionGrid/useCollectionGridLogic';

interface Props {
  type: string;
  settings: WidgetSettings;
}

function CollectionGridLogic({ type, settings }: Props) {
  const { gridData, id } = useCollectionGrid();

  return (
    <WidgetProvider<CollectionAsset> type={type} settings={settings} data={gridData}>
      <DynamicGridViewer<CollectionAsset> gridId={id}>
        {({ imageHeight, itemWidth, data }) => (
          <CollectionCardContainer imageHeight={imageHeight} itemWidth={itemWidth} data={data} />
        )}
      </DynamicGridViewer>
    </WidgetProvider>
  );
}

export { CollectionGridLogic };
