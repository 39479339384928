import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { TopShelfSettings } from '@kaltura-ott/tvpil-shared';

import { topShelfStorage } from '../../topShelfStorage';
import Carousel from '../Carousel/Carousel';
import CarouselSkeleton from '../CarouselSkeleton/CarouselSkeleton';
import Content from '../Content/Content';
import ProgressBarTopShelf from '../ProgressBarTopShelf/ProgressBarTopShelf';

import { useTopShelfContainerCombiner } from './hooks/useTopShelfContainerCombiner/useTopShelfContainerCombiner';

import style from './TopShelfContainer.module.scss';

interface Props {
  referenceId: string;
  isAutoPlay: boolean;
  handleRef: (node: HTMLDivElement | null) => void;
  settings: TopShelfSettings;
}

function TopShelfContainer({ referenceId, handleRef, isAutoPlay, settings }: Props) {
  const { listOfItems, focusAssetRef, isLoading, isRailHidden } = useTopShelfContainerCombiner(referenceId, settings);

  if (isLoading) {
    return <CarouselSkeleton />;
  }

  if (isRailHidden) return null;

  return (
    <div ref={handleRef} className={cn(style.wrapper, { [style.landscape]: topShelfStorage.cardParams.isLandscape })}>
      <div className={style.carousel}>
        <Carousel focusAssetRef={focusAssetRef} isAutoPlay={isAutoPlay} items={listOfItems} settings={settings} />
      </div>

      <div className={style.content}>
        <Content
          cardImageType={settings.imageType}
          coverImageType={settings.coverImageType}
          focusAssetRef={focusAssetRef}
        />
      </div>

      {isAutoPlay && (
        <div className={style.progressBarWrapper}>
          <ProgressBarTopShelf transitionDelay={settings.autoTransitionDelay} className={style.progressBar} />
        </div>
      )}
    </div>
  );
}

export default observer(TopShelfContainer);
