import React from 'react';

import styles from './SpecificationsDetails.module.scss';

type Props = {
  duration?: string;
  country?: string;
  genres?: string[];
  year?: string;
};

// TODO: caver by units
const SpecificationsDetails = ({ duration, year, country, genres }: Props) => {
  return (
    <div className={styles.specificationsDetails}>
      {year && <div>{year}</div>}
      {country && <div>{country}</div>}
      {duration && <span>{duration}</span>}
      {genres && genres.length ? <div className={styles.genre}>{genres?.join(', ')}</div> : null}
    </div>
  );
};

export default SpecificationsDetails;
