import { MediaType, UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { WatchedAsset } from '@kaltura-ott/tvpil-shared';

import routes from 'routesPaths';

import { resolveLinkForMd, resolvePathToDetailsPageV2 } from '../../../../../../../../utils';

export interface Props {
  item: WatchedAsset;
  isCwVod: boolean;
  seriesId: string | undefined;
  isMosaicProgram: boolean;
  isCwLive: boolean;
  isCwRecording: boolean;
  isGroupedCwEpisode: boolean;
}

function useContinueWatchingCardLink({
  item,
  isCwVod,
  isCwLive,
  seriesId,
  isCwRecording,
  isMosaicProgram,
  isGroupedCwEpisode,
}: Props) {
  let link;
  const mediaTypeParam = isCwRecording ? MediaType.Program.toLowerCase() : item.type;
  const program = MediaType.Program.toLowerCase();

  if (isCwLive) {
    link = routes.startOver.link(item.id, item.type);
  } else if (isCwVod && !isGroupedCwEpisode) {
    link = routes.vod.link(item.id, item.type);
  } else if (isCwRecording) {
    link = routes.program.link(item.id, program);
  } else if (!isGroupedCwEpisode) {
    link = routes.catchUp.link(item.id, program);
  } else {
    link = resolvePathToDetailsPageV2({
      seriesId,
      id: item.id,
      mediaTypeParam,
      isProgramRecord: isCwRecording,
      isSuppressedEpisode: isGroupedCwEpisode,
      collectionType: UIDAPresentationTypeEnum.cw,
    });
  }

  link += resolveLinkForMd(isMosaicProgram, link); // to avoid displaying MD components in the player

  return { link, mediaTypeParam };
}

export { useContinueWatchingCardLink };
