import { TopShelfAsset } from '@kaltura-ott/tvpil-shared';

import routes from 'routesPaths';

function useLink(item: TopShelfAsset, isPackageAsset: boolean) {
  let link = routes.catchUp.link(item.id, item.type);

  if (isPackageAsset) {
    link = `${routes.purchase.path}/${item.id}`;
  }

  return link;
}

export { useLink };
