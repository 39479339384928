import React, { memo, useEffect, useRef, useState } from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import { Channel } from '@kaltura-ott/tvpil-shared';

import { DndAction } from '../enum';

type DragAndDropChannelContainerProps = {
  children: (state: DndAction) => React.ReactNode;
  channel: Channel;
};

export const DragAndDropChannelContainer = memo(({ children, channel }: DragAndDropChannelContainerProps) => {
  const ref = useRef<HTMLImageElement | null>(null);
  const [state, setState] = useState(DndAction.Idle);

  useEffect(() => {
    const el = ref.current;

    if (!el) return;

    combine(
      draggable({
        canDrag: () => {
          return !channel.isAdult;
        },
        element: el,
        getInitialData: () => ({ channel }),
        onDragStart: () => {
          setState(DndAction.Dragging);
        },

        onDrop: () => {
          setState(DndAction.Over);
        },
      }),
      dropTargetForElements({
        element: el,
        getData: () => ({ channel }),
        getIsSticky: () => true,
        onDropTargetChange: () => {
          setState(DndAction.Dragging);
        },

        onDragLeave: () => {
          setState(DndAction.Over);
        },

        onDrop: () => {
          setState(DndAction.Over);
        },
      }),
    );
  }, [channel]);

  return <div ref={ref}>{children(state)}</div>;
});
