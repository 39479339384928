import { Channel, getAssetImageUrl, getImageByRatio, RhinoImageRatio, RhinoProgram } from '@kaltura-ott/tvpil-shared';

import { CHANNEL_LOGO_SIZE_MULTIPLIER } from 'components/widgets/Rail/components/RailCard/components/AssetCardProgramChannelInfo/constants';
import { useCardIconsPaths } from 'hooks';

interface HeroCardImagesProps {
  liveProgram: RhinoProgram | undefined;
  channel: Channel;
  imageType: number | undefined;
  imageHeight: number;
  isChannelHasNoProgram: boolean;
  channelIconType: number | undefined;
  channelIconRatio: RhinoImageRatio;
  imageWidth: number;
}

export function useHeroCardImages({
  liveProgram,
  channel,
  imageWidth,
  imageType,
  imageHeight,
  channelIconRatio,
  channelIconType,
  isChannelHasNoProgram,
}: HeroCardImagesProps) {
  // determining the source of the images (live program or channel) and the image size
  const imagesSource = liveProgram?.images ? liveProgram.images : channel.images;
  const width = imageWidth;
  const height = imageHeight;
  // getting background image url
  const backgroundImageUrl = getAssetImageUrl(imagesSource, {
    type: imageType,
    width,
    height,
  });
  const { defaultImage } = useCardIconsPaths({ height, width });
  // getting channel logo url
  const imageHeightFactor = isChannelHasNoProgram ? 2 : CHANNEL_LOGO_SIZE_MULTIPLIER;
  const channelLogoParams = getImageByRatio(channel.images, {
    imageHeight: imageHeight * imageHeightFactor,
    imageRatio: channelIconRatio,
    imageType: channelIconType!,
  });

  return { height, backgroundImageUrl, width, defaultImageUrl: defaultImage, channelLogoUrl: channelLogoParams?.url };
}
