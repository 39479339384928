import { useRef } from 'react';

import { TopShelfSettings } from '@kaltura-ott/tvpil-shared';

import { topShelfStorage } from 'components/widgets/RailV2/TopShelfRail/topShelfStorage';

import { ControlsProps } from '../../types';

/**
 * `useCarouselCombiner` is a custom React hook used for managing data necessary for the carousel
 *
 
 * @param items - Array of assets.
 * @param settings - A rail settings.
 * @param isAutoPlay - A boolean flag.
 * @param focusAssetRef - A focused asset.
 *
 * @returns An object containing:
 * - `shouldPresentRating`: logical flag for displaying of element .
 * - `width`: a number, width of asset.
 * - `height`: a number, height of asset.
 * - `imageType`: a number image type of asset.
 * - `carouselRef`: a mutable react ref object for sharing of link with other components.
 * - `controlsRef`: a mutable react ref object for storing a methods from other components.
 */

// TODO: cover by tests
function useCarouselCombiner(settings: TopShelfSettings) {
  const carouselRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<ControlsProps>({
    clickArrowHandle: () => {},
  });
  const { imageType, shouldPresentRating } = settings;
  const { imageWidth, imageHeight } = topShelfStorage.cardParams;

  return {
    imageType,
    width: imageWidth,
    height: imageHeight,
    shouldPresentRating,
    carouselRef,
    controlsRef,
  };
}

export { useCarouselCombiner };
