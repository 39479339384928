import React from 'react';

import TopShelfContainer from './components/TopShelfContainer/TopShelfContainer';
import { useTopShelfRailCombiner } from './hooks/useTopShelfRailCombiner';

interface Props {
  referenceId: string;
}

function TopShelfRail({ referenceId }: Props) {
  const { settings, handleRef, isAutoPlay } = useTopShelfRailCombiner(referenceId);

  if (!settings) return null;

  return (
    <TopShelfContainer referenceId={referenceId} settings={settings} handleRef={handleRef} isAutoPlay={isAutoPlay} />
  );
}

export default React.memo(TopShelfRail);
