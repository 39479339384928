import React from 'react';
import SVG from 'react-inlinesvg';

import { getImageByRatio, MyFavoriteChannelsSettings, RhinoImage } from '@kaltura-ott/tvpil-shared';

import { LOGO_HEIGHT } from 'components/pages/Settings/RhinoFavorites/constants';
import { DEFAULT_CHANNEL_LOGO_RATIO, DEFAULT_CHANNEL_LOGO_TYPE, FAVORITE_ICON_BIG, FAVORITE_ICON_FILLED } from 'consts';
import { useToastMessageContext } from 'context/toast/toast';
import { useAppStore } from 'hooks';
import { getFavoriteChannelIcon } from 'utils';

import styles from './Channel.module.scss';

export type ChannelProps = {
  id: number;
  images: RhinoImage[];
  title: string;
  settings: Pick<MyFavoriteChannelsSettings, 'channelIconType' | 'channelIconRatio'> | undefined;
  isFavorite: boolean;
  addFavorite: (channelId: number) => Promise<void>;
  deleteFavorite: (channelId: number) => Promise<void>;
};

function Channel({ deleteFavorite, images, addFavorite, id, title, isFavorite, settings }: ChannelProps) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  const toastMessageContext = useToastMessageContext();

  const originImage = getImageByRatio(images, {
    imageHeight: LOGO_HEIGHT,
    imageRatio: settings?.channelIconRatio ?? DEFAULT_CHANNEL_LOGO_RATIO,
    imageType: settings?.channelIconType ?? DEFAULT_CHANNEL_LOGO_TYPE,
  });

  async function handleClick() {
    toastMessageContext.clearToast();

    if (isFavorite) {
      return deleteFavorite(id);
    }

    return addFavorite(id);
  }

  return (
    <div className={styles.channel} tabIndex={0} role='button' onClick={handleClick}>
      <SVG
        className={styles.starIcon}
        src={`${staticAssetsUrl}${getFavoriteChannelIcon(isFavorite ? FAVORITE_ICON_FILLED : FAVORITE_ICON_BIG)}`}
      />
      {originImage?.url ? <img src={originImage.url} alt={title} /> : <span className={styles.title}>{title}</span>}
    </div>
  );
}

export default React.memo(Channel);
