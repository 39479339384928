import { getAssetImageUrl } from '@kaltura-ott/tvpil-shared';
import { RentalAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/rental/types';

import { useCardIconsPaths } from 'hooks';

interface RentalsCardImagesProps {
  rentalItem: RentalAsset;
  imageType: number | undefined;
  imageHeight: number;
  imageWidth: number;
}

export function useRentalsCardImages({ rentalItem, imageWidth, imageType, imageHeight }: RentalsCardImagesProps) {
  // getting background image url
  const backgroundImageUrl = getAssetImageUrl(rentalItem.images, {
    type: imageType,
    width: imageWidth,
    height: imageHeight,
  });
  const { defaultImage } = useCardIconsPaths({ height: imageHeight, width: imageWidth });

  return {
    height: imageHeight,
    backgroundImageUrl,
    width: imageWidth,
    defaultImageUrl: defaultImage,
  };
}
