import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import styles from './Tags.module.scss';

interface Props {
  qualities?: string[];
  audioTypes?: string[];
  parentalRating?: string;
  componentName?: string;
}
// TODO: caver by units
function Tags({ parentalRating, qualities, audioTypes, componentName }: Props) {
  return (
    <div className={styles.specifications}>
      {parentalRating && (
        <div className={cn(styles.label, styles.rating)}>
          <div className={styles.short}>
            <FormattedMessage
              defaultMessage={parentalRating}
              id={`${componentName}.Tags.ratingTitle.${parentalRating}`}
            />{' '}
            <FormattedMessage
              defaultMessage={parentalRating}
              id={`${componentName}.Tags.ratingDescription.${parentalRating}`}
            />
          </div>
        </div>
      )}

      <>
        {qualities &&
          qualities.length > 0 &&
          qualities?.map((quality: string) => (
            <div key={quality} className={styles.label}>
              {quality}
            </div>
          ))}
        {audioTypes &&
          audioTypes.length > 0 &&
          audioTypes?.map((audioType: string) => (
            <div key={audioType} className={styles.label}>
              {audioType}
            </div>
          ))}
      </>
    </div>
  );
}

export default Tags;
