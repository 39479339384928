import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import KalturaPlayer from 'components/widgets/Player/KalturaPlayer';
import { GEOBLOCK_ICON, LOCK_ICON } from 'consts';
import { useAppStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { getLockIcon } from 'utils';

import { useVideoPlayoutContext } from '../VideoPlayoutProvider/VideoPlayoutProvider';

import styles from './PlayerView.module.scss';

const PlayerView: React.FC = () => {
  const { isPlayerCondition, playableAssetId, muted, isContentGeoBlocking } = useVideoPlayoutContext();
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  useEffect(() => {
    if (!isPlayerCondition) {
      globalStorage.setPossiblePresentPlayer(false);
    }
  }, [isPlayerCondition]);

  return isPlayerCondition ? (
    <div className={styles.videoContainer}>
      <div className={cn(styles.playerContainer, styles.playerDisplayed)}>
        <div className={styles.leftGradient} />
        {playableAssetId && <KalturaPlayer assetId={playableAssetId} hideUI isLive muted={muted} isMaxi />}
      </div>
    </div>
  ) : (
    <div className={styles.lock}>
      <SVG src={`${staticAssetsUrl}${getLockIcon(isContentGeoBlocking ? GEOBLOCK_ICON : LOCK_ICON)}`} />
    </div>
  );
};

export default PlayerView;
