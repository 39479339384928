import React, { useCallback } from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import {
  isTopShelfPackageAsset,
  isTopShelfProgramAsset,
  isTopShelfSeriesAsset,
  TopShelfAsset,
} from '@kaltura-ott/tvpil-shared';

import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalTypesEnum';
import PinCodeModalV2 from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalV2';
import { useModalContext } from 'context/modal/modal';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useParentalPinStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { resolvePathToDetailsPageV2, userActionEvents } from 'utils';

interface Props {
  item: TopShelfAsset;
  link: string;
  isPackageAsset: boolean;
  isAdult: boolean;
}

const useClickHandler = ({ item, link, isPackageAsset, isAdult }: Props) => {
  const { navigate } = useStableReactRouter();
  const parentalPinStore = useParentalPinStore();
  const modalContext = useModalContext();
  // needs for parental rating check in case of url to full player and parental rating is blocked
  let parentalRating: number;

  if (!isTopShelfPackageAsset(item)) {
    parentalRating = Number(item?.parentalRating!);
  }

  return useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (isPackageAsset) {
        navigate(link);

        return;
      }

      const isAdultPinNotEntered = !parentalPinStore.isAdultPinEntered;
      const isRestricted = !!(isAdult && isAdultPinNotEntered);
      const seriesId = (isTopShelfProgramAsset(item) || isTopShelfSeriesAsset(item)) && item.seriesId;

      if (isRestricted) {
        e.preventDefault();
        e.stopPropagation();

        const type = PinCodeModalTypes.RestrictedContent;

        modalContext.openModal(
          <PinCodeModalV2
            parentalRating={Number(parentalRating)}
            context={UIDAPresentationTypeEnum.applications}
            type={type}
          />,
        );

        return;
      }

      globalStorage.clearDetailsAsset(); // Temporary solution to avoid opening a detail page with an asset saved in globalStorage

      userActionEvents(UserActionType.details, item?.id, item.type);

      navigate(
        resolvePathToDetailsPageV2({
          id: item?.id!,
          mediaTypeParam: item.type.toLowerCase(),
          isTypeOfSeries: !!seriesId,
          seriesId: String(seriesId),
        }),
      );
    },
    [modalContext, link, isAdult],
  );
};

export { useClickHandler };
