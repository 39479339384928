import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import { APPLICATION_SPINNER_EVENT_NAME } from 'utils';

import {
  COLUMN,
  DEFAULT_HEIGHT,
  DEFAULT_HEIGHT_PLUS_MARGIN,
  DEFAULT_MIN_WIDTH,
  DEFAULT_WIDTH,
  FOUR_COLUMNS_RESOLUTION,
  HEADER_HEIGHT,
  MENU_HEIGHT,
  SETTINGS_TOP_PADDING,
} from '../../constants';

import { PersonalizedListSkeleton } from './components/Skeleton';

import styles from './PersonalLineupSkeleton.module.scss';

export function PersonalLineupSkeleton() {
  const countVisibleChannel =
    (Math.round(
      (window.innerHeight - MENU_HEIGHT - HEADER_HEIGHT - SETTINGS_TOP_PADDING) / DEFAULT_HEIGHT_PLUS_MARGIN,
    ) +
      1) *
    COLUMN;

  return (
    <LoaderWrapperForEvents
      hashKey={APPLICATION_SPINNER_EVENT_NAME.PERSONALIZED_LIST}
      component={
        <PersonalizedListSkeleton
          className={styles.personalizedList}
          numberOfElements={countVisibleChannel}
          itemWidth={window.innerWidth > FOUR_COLUMNS_RESOLUTION ? DEFAULT_MIN_WIDTH : DEFAULT_WIDTH}
          itemHeigh={DEFAULT_HEIGHT}
        />
      }
    />
  );
}
