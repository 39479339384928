export const CUSTOM_PLAYER_EVENTS = {
  SWITCH_TO_START_OVER: 'SWITCH_TO_START_OVER',
  SWITCH_TO_LIVE: 'SWITCH_ON_LIVE',
  SWITCH_SEEKBAR_TO_LIVE: 'SWITCH_SEEKBAR_TO_LIVE',
  INFO_BUTTON_CLICKED: 'INFO_BUTTON_CLICKED',
  RECORD_BUTTON_CLICKED: 'RECORD_BUTTON_CLICKED',
  CANCEL_RECORD_BUTTON_CLICKED: 'CANCEL_RECORD_BUTTON_CLICKED',
  UPDATE: 'UPDATE',
  START_PLAYBACK: 'START_PLAYBACK',
  TRY_TO_FF: 'TRY_TO_FF',
  TRY_TO_SEEK: 'TRY_TO_SEEK',
  SEEK: 'SEEK',
  CONCURRENCY_LIMIT: 'CONCURRENCY_LIMIT',
  NEXT_EPISODE: 'NEXT_EPISODE',
  SKIP_INTRO: 'SKIP_INTRO',
  PLAYBACK_ENDED: 'PLAYBACK_ENDED',
};

export const CONCURRENCY_ERROR = 'ConcurrencyLimitation';
export const CONCURRENCY_ERROR_CODE = '4001';
export const CONCURRENCY_ICON_PATH = 'concurrency';
export const SLOW_CONNECTION_ICON_PATH = 'slow-connection';
export const CONCURRENCY_DEFAULT_TTL = 10000;
export const CHANGE_LOCAL_STATE = 'CHANGE_LOCAL_STATE';
export const COMPONENT_NAME = 'Player';
export const HOVER_TIMEOUT = 5000;
export const DELAY_TIME = 300;
export const PLAYER_SESSION_STORAGE_SUBTITLE_KEY = 'kaltura-player-js_session_textLanguage';
export const PLAYER_SESSION_STORAGE_AUDIO_KEY = 'kaltura-player-js_session_audioLanguage';

export const STYLES = {
  HIDDEN_PLAYER_GUI: 'hiddenPlayerGui',
};
