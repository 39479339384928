import React, { useCallback, useRef } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { RecordIcon, SvgView } from 'components/presenters/icons';
import Img from 'components/presenters/Img/Img';
import { LOCK_ICON, MD_ICON } from 'consts';
import { useAppStore } from 'hooks';
import { getLockIcon, getMDIconPath } from 'utils';

import { useAssetCardContext } from '../AssetCardContext/AssetCardContext';
import { AssetCardAdditionInfo } from '../components/AssetCardAdditionInfo/AssetCardAdditionInfo';

import {
  useAssetCardBodyResolveData,
  useClickHandler,
  useHandleRef,
  useMouseEnterHandle,
  useMouseLeaveHandle,
  useSetIndexToListStore,
  useUpdateMaxiPlayer,
} from './hooks';

import styles from './AssetCardBody.module.scss';

interface Props {
  children: React.ReactNode;
}

function AssetCardBody({ children }: Props) {
  const {
    listStore,
    resolvedProps: {
      link,
      isContentRestricted,
      isRecordIcon,
      isSeriesRecord,
      seasonEpisodeTitle,
      isMosaicHeroRailProgram,
      title,
    },
  } = useAssetCardContext();

  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();
  useSetIndexToListStore();
  useUpdateMaxiPlayer();
  const ref = useRef<HTMLDivElement | null>(null);
  const setRef = useCallback((value: HTMLDivElement | null) => {
    ref.current = value;
  }, []);
  const { isNoDataWithNoLogoImg, itemImageUrl, defaultImage, height, width } = useAssetCardBodyResolveData();
  const state = {
    isShowStreams: isMosaicHeroRailProgram,
  };
  const onMouseEnter = useMouseEnterHandle({ ref });
  const onMouseLeave = useMouseLeaveHandle();
  const handleRef = useHandleRef({ setRef });
  const clickHandler = useClickHandler();

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Link
      className={cn(styles.assetWrapper, styles[`type-${listStore.subtype}`], {
        [styles.restrictedContent]: isContentRestricted,
      })}
      onClick={clickHandler}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseEnter}
      role='presentation'
      style={{
        minHeight: height,
        width,
      }}
      to={link}
      state={state}
    >
      <div ref={handleRef} className={styles.assetInner}>
        <div className={styles.contentWrapper}>
          <div
            className={cn(styles.imageWrapper, {
              [styles.noDataWithDefaultImg]: isNoDataWithNoLogoImg,
            })}
          >
            {itemImageUrl && <Img alt={title} srcList={[itemImageUrl, defaultImage]} />}
            {isContentRestricted && <SVG src={`${staticAssetsUrl}${getLockIcon(LOCK_ICON)}`} />}
          </div>
          <div className={styles.content}>{children}</div>
        </div>
        {/* TODO: titleBlock should be merged to AssetCardAdditionInfo */}

        <AssetCardAdditionInfo />
      </div>
      <div className={styles.titleBlock}>
        <div className={styles.titleRow}>
          <h5>{title}</h5>
          {isMosaicHeroRailProgram && (
            <span className={styles.mosaicIcon}>
              <SvgView url={`${staticAssetsUrl}${getMDIconPath(MD_ICON)}`} />
            </span>
          )}
          <div className={styles.recordIcon}>{isRecordIcon && <RecordIcon active multiple={isSeriesRecord} />}</div>
        </div>
        {!!seasonEpisodeTitle && <p>{seasonEpisodeTitle}</p>}
      </div>
    </Link>
  );
}

export default observer(AssetCardBody);
