import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import { PageStore } from '@kaltura-ott/tvpil-shared';

import LoaderWrapperForEvents from 'components/hoc/LoaderWrapperForEvents';
import MainLayout from 'components/presenters/layouts/main/MainLayout';
import RailSkeleton from 'components/widgets/Rail/components/RailSkeleton/RailSkeleton';
import RailGrid from 'components/widgets/Rail/RailGrid/RailGrid';

import { useRecordsCombiner } from './hooks/useRecordsCombiner/useRecordsCombiner';

import styles from './Records.module.scss';

interface Props {
  store: PageStore;
}

const COMPONENT_NAME = 'Records';

function Records({ store }: Props) {
  const {
    handleSkeletonRef,
    pageStore,
    handleRef,
    isReady,
    npvrStore,
    columnCount,
    isViewMessage,
  } = useRecordsCombiner(store);

  if (!pageStore) {
    return null;
  }

  return (
    <MainLayout pageId={store.referenceId}>
      <div ref={handleRef} className={styles.wrapper}>
        <FormattedMessage
          defaultMessage={pageStore?.totalRecordsRail?.name}
          id={`${COMPONENT_NAME}.title`}
          tagName='h3'
        />
        {isReady ? (
          <>
            {isViewMessage ? (
              <p className={styles.noRecordings}>
                <FormattedMessage defaultMessage='No recordings' id={`${COMPONENT_NAME}.noRecordingsText`} />
              </p>
            ) : (
              <RailGrid className={styles.grid} store={pageStore.totalRecordsRail!} isRecordPage />
            )}
          </>
        ) : (
          <div>
            <LoaderWrapperForEvents
              hashKey={COMPONENT_NAME}
              component={
                <RailSkeleton
                  className={styles.grid}
                  handleRef={handleSkeletonRef}
                  isGrid
                  items={columnCount * 2}
                  store={npvrStore}
                />
              }
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default observer(Records);
