import { useCallback } from 'react';

import { useCancelConfirmationModal } from './useCancelConfirmationModal';
import { useRevertConfirmationModal } from './useRevertConfirmationModal';

type LineupAction = () => void | Promise<void>;

type UseButtonHandlerWithModalProps = {
  cancelLineupOrder: LineupAction;
  revertToDefaultLineup: LineupAction;
  saveNewLineup: LineupAction;
};

export function useButtonHandlerWithModal({
  cancelLineupOrder,
  revertToDefaultLineup,
  saveNewLineup,
}: UseButtonHandlerWithModalProps) {
  const showRevertConfirmationModal = useRevertConfirmationModal();
  const { openModal: showCancelConfirmationModal } = useCancelConfirmationModal();

  const handlePersonalLineupCancel = useCallback(() => {
    showCancelConfirmationModal({ onAccept: cancelLineupOrder });
  }, [cancelLineupOrder, showCancelConfirmationModal]);

  const handlePersonalLineupRevert = useCallback(async () => {
    showRevertConfirmationModal({ onAccept: revertToDefaultLineup });
  }, [revertToDefaultLineup, showRevertConfirmationModal]);

  const handlePersonalLineupSave = useCallback(async () => {
    await saveNewLineup();
  }, [saveNewLineup]);

  return {
    handlePersonalLineupCancel,
    handlePersonalLineupRevert,
    handlePersonalLineupSave,
  };
}
