import { getAssetImageUrl } from '@kaltura-ott/tvpil-shared';

import { useCardIconsPaths } from 'hooks';

import { CardCarouselProps } from '../../types';

function useImages({ item, width, height, imageType }: CardCarouselProps) {
  const backgroundImageUrl = getAssetImageUrl(item.images, {
    type: imageType!,
    width: width!,
    height: height!,
  });

  const { defaultImage } = useCardIconsPaths({ height, width });

  return { url: backgroundImageUrl || defaultImage };
}

export { useImages };
