import { useMemo } from 'react';

import { LAST_RAIL_X_POSITION, WIDGET_TYPE } from 'consts';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';

type Item = { id: number };

type UseRailLastXPosition<T extends Item> = {
  railId: number;
  railType: string;
  items: T[];
};

export function useRailLastXPosition<T extends Item>({ railId, railType, items }: UseRailLastXPosition<T>) {
  const navigationType = useStableReactRouter();
  // getting the last rail x position from the session storage
  const lastRailXPosition = sessionStorage.getItem(LAST_RAIL_X_POSITION);
  const { id, position, linearId } = JSON.parse(lastRailXPosition || '{}');

  return useMemo(() => {
    let railPosition = 0;

    if (lastRailXPosition && railType !== WIDGET_TYPE.CW && navigationType !== 'PUSH') {
      const firstItemInHeroRail = items?.[0].id;

      if (railId === id && !(railType === WIDGET_TYPE.HERO && firstItemInHeroRail === linearId)) {
        railPosition = position;
      }
    }

    return railPosition;
  }, [railId, railType]);
}
