import React, { createContext, useContext, useMemo } from 'react';

import { getImageSizeParams } from 'utils/getImageSizeParams';

import { WidgetData, WidgetSettings } from './types';

export interface ContextType<T extends { id: number }> {
  data: WidgetData<T>;
  settings: WidgetSettings;
  type: string;
  imageHeight: number;
  imageWidth: number;
}

export interface Props<T extends { id: number }> {
  data: WidgetData<T>;
  settings: WidgetSettings;
  children: React.ReactNode;
  type: string;
}

const defaultContextValue = <T extends { id: number }>(): ContextType<T> => ({
  data: {} as WidgetData<T>,
  settings: {} as WidgetSettings,
  type: '',
  imageHeight: 0,
  imageWidth: 0,
});

const WidgetContext = createContext<ContextType<any>>(defaultContextValue());

const WidgetProvider = <T extends { id: number }>({ children, data, settings, type }: Props<T>) => {
  const { imageHeight, imageWidth } = getImageSizeParams(settings); // should be removed after fallback params becomes required
  const contextValue = useMemo(
    () => ({
      data,
      settings,
      type,
      imageHeight, // should be removed after fallback params becomes required
      imageWidth, // should be removed after fallback params becomes required
    }),
    [data.isLoading, settings.imageHeight],
  );

  return <WidgetContext.Provider value={contextValue}>{children}</WidgetContext.Provider>;
};

const useWidgetContext = <T extends { id: number }>(): ContextType<T> => useContext(WidgetContext);

export { WidgetProvider, useWidgetContext };

export default WidgetProvider;
