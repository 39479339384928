import React from 'react';

import { RentalAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/rental/types';

import { Card, CardBubbleInfo, CardInner, CardTitle } from 'components/presenters';

import RentalsCardContent from '../RentalsCardContent/RentalsCardContent';

import { useRentalsCard } from './hooks/useRentalsCard/useRentalsCard';

interface Props {
  rentalItem: RentalAsset;
  itemWidth: number;
  imageHeight: number;
}

function RentalsCard({ rentalItem, itemWidth, imageHeight }: Props) {
  const {
    link,
    title,
    isAdult,
    handleRef,
    assetRating,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    handleLinkRef,
    handleClickInfo,
    isDisplayRating,
    defaultImageUrl,
    backgroundImageUrl,
  } = useRentalsCard({ rentalItem, itemWidth, imageHeight });

  return (
    <Card
      handleLinkRef={handleLinkRef}
      clickHandler={clickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      link={link}
      imageHeight={imageHeight}
      itemWidth={itemWidth}
      isMosaicProgram={false}
    >
      <CardInner
        cardType='rental'
        url={backgroundImageUrl}
        name={rentalItem.title}
        handleRef={handleRef}
        handleLinkRef={handleLinkRef}
        isAdult={isAdult}
        isNoDataWithNoLogoImg={false}
        defaultImageUrl={defaultImageUrl}
        contentOverlay={
          <RentalsCardContent
            isDisplayRating={isDisplayRating}
            assetRating={assetRating!}
            shouldDisplayPlayButton
            isAdult={isAdult}
          />
        }
        cardBubbleInfo={
          <CardBubbleInfo
            channelHasProgram
            isAdult={isAdult}
            title={title}
            isMosaicProgram={false}
            handleClickInfo={handleClickInfo}
            programId={rentalItem?.id}
            isLinkAsAsset={false}
          />
        }
      />
      <CardTitle isAdult={isAdult} isStaticInfo title={title} isMosaicProgram={false} programId={rentalItem?.id} />
    </Card>
  );
}

export default RentalsCard;
