import React, { createContext, useContext, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';

import { useRailProviderCombiner } from './hooks/useRailProviderCombiner/useRailProviderCombiner';

export interface ContextType {
  checkResize: () => void;
  onHover: (isHover: boolean, value?: number) => void;
  onCardClick: (id: number) => void;
  setNextButtons: (value: any) => void;
  setRailCardsListRefScrollTo: (value: any) => void;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
  totalWidth: number;
  componentHeight: number;
  headerRef: React.MutableRefObject<HTMLElement | null>;
  refElementInner: React.MutableRefObject<HTMLDivElement | null>;
  railCardsListRef: React.MutableRefObject<List | null>;
  nextButtons: React.MutableRefObject<any> | undefined;
  isLogoRail: boolean;
  offset: React.MutableRefObject<any> | undefined;
  savedOffset: number | undefined;
}

export interface Props {
  children: React.ReactNode;
  pageReferenceId?: string;
  referenceId?: string;
}

const defaultContextValue = (): ContextType => ({
  checkResize: () => {},
  onHover: () => {},
  onCardClick: () => {},
  setNextButtons: () => {},
  setRailCardsListRefScrollTo: () => {},
  wrapperRef: { current: null },
  totalWidth: 0,
  componentHeight: 0,
  headerRef: { current: null },
  refElementInner: { current: null },
  railCardsListRef: { current: null },
  nextButtons: undefined,
  isLogoRail: false,
  offset: undefined,
  savedOffset: 0,
});

const RailContext = createContext<ContextType>(defaultContextValue());

const RailProviderV2 = <T extends { id: number }>({ children, pageReferenceId, referenceId }: Props) => {
  const {
    checkResize,
    totalWidth,
    componentHeight,
    isLogoRail,
    wrapperRef,
    headerRef,
    refElementInner,
    railCardsListRef,
    offset,
    savedOffset,
    nextButtons,
    onHover,
    onCardClick,
    setNextButtons,
    setRailCardsListRefScrollTo,
  } = useRailProviderCombiner<T>(pageReferenceId, referenceId);

  const contextValue = useMemo(
    () => ({
      totalWidth,
      componentHeight,
      wrapperRef,
      checkResize,
      nextButtons,
      isLogoRail,
      headerRef,
      refElementInner,
      railCardsListRef,
      offset,
      savedOffset,
      setRailCardsListRefScrollTo,
      setNextButtons,
      onCardClick,
      onHover,
    }),
    [],
  );

  return <RailContext.Provider value={contextValue}>{children}</RailContext.Provider>;
};

const useRailContext = (): ContextType => useContext(RailContext);

export { RailProviderV2, useRailContext };

export default RailProviderV2;
