import React from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { CollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import { RAIL_PAGE_SIZE } from 'consts';
import WidgetProvider from 'context/widgetProvider/widgetProvider';

import RailProviderV2 from '../components/RailProviderV2/RailProviderV2';
import RailSkeleton from '../components/RailSkeleton/RailSkeleton';

import SeriesCatchUpRailContainer from './components/SeriesCatchUpRailContainer/SeriesCatchUpRailContainer';
import { useSeriesCatchUpRail } from './hooks/useSeriesCatchUpRail/useSeriesCatchUpRail';

interface Props {
  referenceId: string;
}

function SeriesCatchUpRail({ referenceId }: Props) {
  const { data, settings, isRailInvalid, shouldPreventDisplay, handleSkeletonRef } = useSeriesCatchUpRail(referenceId);
  const type = UIDAPresentationTypeEnum.series_catch_up;

  if (!settings || isRailInvalid) {
    return null;
  }

  if (shouldPreventDisplay || !data.items.length) {
    return (
      <RailSkeleton handleRef={handleSkeletonRef} settings={settings} numberOfCells={RAIL_PAGE_SIZE} shouldShowHeader />
    );
  }

  return (
    <WidgetProvider<CollectionProgramAsset> type={type} settings={settings} data={data}>
      <RailProviderV2<CollectionProgramAsset> referenceId={referenceId}>
        <SeriesCatchUpRailContainer />
      </RailProviderV2>
    </WidgetProvider>
  );
}

export default SeriesCatchUpRail;
