import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { ScrollRestoration } from 'react-router-dom';
import { observer } from 'mobx-react';

import { appStore, RhinoProvider, setCookie } from '@kaltura-ott/tvpil-shared';

import { SILENT_LOGIN_COOKIE } from 'consts';
import { ErrorScreenProvider } from 'context/error/error';
import { HeartbeatProvider } from 'context/heartbeat/heartbeat';
import { ModalProvider } from 'context/modal/modal';
import { NavigationContextProvider } from 'context/navigationContext/navigationContext';
import { PerformanceEventsProvider } from 'context/performanceEvents/performanceEvents';
import { StableReactRouterContextProvider } from 'context/stableReactRouterContext/stableReactRouterContext';
import { ToastMessageProvider } from 'context/toast/toast';
import { useAppStore, useLoginStore } from 'hooks';
import { flattenMessages } from 'utils';
import { cutStringUpTo } from 'utils/cutStringUpTo';

const defaultLocale = 'en';

function AppContext({ children }: { children: React.ReactNode }) {
  const {
    appStore: {
      localizationStore: { displayLanguage, translation },
    },
  } = useAppStore();
  const { isAnonymous } = useLoginStore();
  const flattenedMessages = flattenMessages(translation);

  useEffect(() => {
    if (!isAnonymous) return;
    // to delete the "silentLoginCookie" cookie after successful login
    setCookie(SILENT_LOGIN_COOKIE, '', {
      'max-age': -1,
    });
  }, [isAnonymous]);

  return (
    <IntlProvider
      defaultLocale={defaultLocale}
      // TODO: add tracking localisation error for appStore.apiError
      locale={displayLanguage || defaultLocale}
      messages={flattenedMessages}
    >
      <RhinoProvider>
        <HeartbeatProvider>
          <PerformanceEventsProvider>
            <ScrollRestoration
              getKey={(location) => {
                // cut the string up to the "details"  to apply the same behavior for page and details page on it
                // for example: page/homepage/details-info/123 -> /page/homepage
                // a result we will have the same scroll position for page and details page(opened as modal)
                if (location.pathname.includes('details')) {
                  return cutStringUpTo(location.pathname, 'details');
                }
                return location.pathname;
              }}
            />
            <StableReactRouterContextProvider>
              <NavigationContextProvider>
                <ModalProvider>
                  <ErrorScreenProvider apiErrorRetrying={appStore.apiErrorRetrying}>
                    <ToastMessageProvider>{children}</ToastMessageProvider>
                  </ErrorScreenProvider>
                </ModalProvider>
              </NavigationContextProvider>
            </StableReactRouterContextProvider>
          </PerformanceEventsProvider>
        </HeartbeatProvider>
      </RhinoProvider>
    </IntlProvider>
  );
}

export default observer(AppContext);
