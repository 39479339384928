import React from 'react';
import cn from 'classnames';

import Img from 'components/presenters/Img/Img';
import { getDefaultImagePath } from 'utils';

import styles from './Image.module.scss';

type Props = {
  title: string;
  staticAssetsUrl: string;
  isLandscapeRatio?: boolean;
  image?: string;
};

export const Image = ({ title, staticAssetsUrl, isLandscapeRatio, image }: Props) => (
  <div
    className={cn(styles.wrapper, {
      [styles.wrapperPortrait]: !isLandscapeRatio,
    })}
  >
    <div>
      <Img alt={title} srcList={[image!, `${staticAssetsUrl}${getDefaultImagePath(isLandscapeRatio)}`]} />
    </div>
  </div>
);
