import React from 'react';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import { RAIL_PAGE_SIZE } from 'consts';
import WidgetProvider from 'context/widgetProvider/widgetProvider';

import RailProviderV2 from '../components/RailProviderV2/RailProviderV2';
import RailSkeleton from '../components/RailSkeleton/RailSkeleton';

import RecommendationRailContainer from './components/RecommendationRailContainer/RecommendationRailContainer';
import { useRecommendationRail } from './hooks/useRecommendationRail/useRecommendationRail';

interface Props {
  referenceId: string;
}

function RecommendationRail({ referenceId }: Props) {
  const { railData, railType, railSettings, isRailHidden } = useRecommendationRail(referenceId);

  if (!railSettings || isRailHidden) {
    return null;
  }

  if (!railData.items.length) {
    return <RailSkeleton settings={railSettings} numberOfCells={RAIL_PAGE_SIZE} shouldShowHeader />;
  }

  return (
    <WidgetProvider<CollectionAsset> type={railType} settings={railSettings!} data={railData}>
      <RailProviderV2 referenceId={referenceId}>
        <RecommendationRailContainer />
      </RailProviderV2>
    </WidgetProvider>
  );
}

export default RecommendationRail;
