import React from 'react';

import styles from './CardSkeleton.module.scss';

export function CardSkeleton({ height, width }: { height: number; width: number }) {
  return (
    <div data-type='skeletonAsset' className={styles.item}>
      <div
        style={{
          height,
          width,
        }}
      />
      <div />
    </div>
  );
}
