import React from 'react';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import { Card, CardBubbleInfo, CardInner, CardTitle } from 'components/presenters';

import CollectionCardContent from './components/CollectionCardContent/CollectionCardContent';
import { useCollectionCard } from './hooks/useCollectionCard/useCollectionCard';

interface Props {
  item: CollectionAsset;
  itemWidth: number;
  imageHeight: number;
}

function CollectionCard({ item, itemWidth, imageHeight }: Props) {
  const {
    id,
    link,
    title,
    isAdult,
    handleRef,
    assetRating,
    channelName,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    isAsPlayAsset,
    isDisplayCartIcon,
    handleLinkRef,
    handleClickInfo,
    isDisplayRating,
    defaultImageUrl,
    backgroundImageUrl,
    isFutureProgram,
    seriesId,
    collectionProgramAsset,
    channelHasProgram,
    isExternalProviderAsset,
    seasonEpisodeTitle,
    isDisplayContentMarker,
    contentMarker,
    isLinkAsAsset,
  } = useCollectionCard({ item, itemWidth, imageHeight });

  return (
    <Card
      handleLinkRef={handleLinkRef}
      clickHandler={clickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      link={link!}
      imageHeight={imageHeight}
      itemWidth={itemWidth}
      isMosaicProgram={false}
    >
      <CardInner
        cardType='collection'
        url={backgroundImageUrl}
        name={title}
        handleRef={handleRef}
        handleLinkRef={handleLinkRef}
        isAdult={isAdult}
        isNoDataWithNoLogoImg={false}
        defaultImageUrl={defaultImageUrl}
        contentOverlay={
          <CollectionCardContent
            data={item}
            isFutureProgram={isFutureProgram}
            isDisplayRating={isDisplayRating}
            assetRating={assetRating!}
            shouldDisplayPlayButton={isAsPlayAsset}
            shouldDisplayCartIcon={isDisplayCartIcon}
            isDisplayContentMarker={isDisplayContentMarker}
            contentMarker={contentMarker}
          />
        }
        cardBubbleInfo={
          <CardBubbleInfo
            channelHasProgram={channelHasProgram}
            seasonEpisodeTitle={seasonEpisodeTitle}
            isExternalProviderAsset={isExternalProviderAsset}
            channelName={channelName}
            isAdult={isAdult}
            title={title}
            isMosaicProgram={false}
            handleClickInfo={handleClickInfo}
            programId={id}
            seriesId={seriesId}
            collectionProgramAsset={collectionProgramAsset}
            isLinkAsAsset={isLinkAsAsset}
          />
        }
      />
      <CardTitle
        seriesId={seriesId}
        seasonEpisodeTitle={seasonEpisodeTitle}
        isAdult={isAdult}
        isStaticInfo
        title={title}
        isMosaicProgram={false}
        programId={item?.id}
      />
    </Card>
  );
}

export default CollectionCard;
