import React from 'react';
import { WidgetSettings } from 'types';

import { CollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import { DynamicGridViewer } from 'components/pages/Grids/DynamicGridViewer/DynamicGridViewer';
import { useGridMatchPath } from 'components/pages/Grids/hooks/useGridMatchPath/useGridMatchPath';
import SeriesCatchUpCardContainer from 'components/widgets/CardView/SeriesCatchUp/SeriesCatchUpCardContainer/SeriesCatchUpCardContainer';
import { WidgetProviderData } from 'context/widgetProvider/types';
import WidgetProvider from 'context/widgetProvider/widgetProvider';
import routes from 'routesPaths';

interface Props {
  type: string;
  settings: WidgetSettings;
  data: WidgetProviderData<CollectionProgramAsset>;
}

function SeriesCatchUpGridContainer({ type, settings, data }: Props) {
  const { id } = useGridMatchPath(routes.seriesCatchUpGrid.path);

  return (
    <WidgetProvider<CollectionProgramAsset> type={type} settings={settings} data={data}>
      <DynamicGridViewer<CollectionProgramAsset> gridId={id}>
        {({ imageHeight, itemWidth, data: seriesCatchUp }) => (
          <SeriesCatchUpCardContainer imageHeight={imageHeight} itemWidth={itemWidth} data={seriesCatchUp} />
        )}
      </DynamicGridViewer>
    </WidgetProvider>
  );
}

export { SeriesCatchUpGridContainer };
