import { ADDITIONAL_HEIGHT, CARD_PADDING, INITIAL_OFFSET } from 'components/widgets/RailV2/constants';

import { Data, Item } from '../VirualizedContainer/types';

export type RenderCardItemProps<T extends Item> = {
  index: number;
  style: React.CSSProperties;
  data: Data<T>;
};

/**
 * Renders a single card item.
 *
 * @typeparam T - The type of the item to be rendered.
 * @param props - The properties passed to the component.
 * @returns The React element representing the card item.
 */
const VirtualizedCardItem = <T extends Item>({ index, style, data }: RenderCardItemProps<T>) => {
  const { children, items, imageHeight } = data;
  const paddingTop = ADDITIONAL_HEIGHT - 10;
  const item = items[index];

  return (
    <li
      key={item.id}
      style={{
        ...style,
        boxSizing: 'border-box',
        left: Number(style.left) + INITIAL_OFFSET,
        paddingRight: CARD_PADDING,
        paddingTop,
      }}
    >
      {children({
        imageHeight: imageHeight ?? 0,
        itemWidth: Number(style.width) - CARD_PADDING,
        item,
        index,
      })}
    </li>
  );
};

export default VirtualizedCardItem;
