import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { TopShelfAsset } from '@kaltura-ott/tvpil-shared';

import { RecordIcon } from 'components/presenters/icons';
import SpecificationsDetails from 'components/presenters/SpecificationsDetails/SpecificationsDetails';
import Tags from 'components/presenters/Tags/Tags';

import { COMPONENT_NAME } from '../../constants';
import AnimateBackground from '../AnimateBackground/AnimateBackground';

import { useContentCombiner } from './hooks/useContentCombiner/useContentCombiner';

import style from './Content.module.scss';

interface Props {
  coverImageType: number | undefined;
  cardImageType: number | undefined;
  focusAssetRef: React.MutableRefObject<TopShelfAsset | undefined>;
}

function Content({ coverImageType, focusAssetRef, cardImageType }: Props) {
  const {
    isEmpty,
    description,
    title,
    audioTypes,
    genres,
    year,
    qualities,
    country,
    duration,
    parentalRating,
    fallBackUrl,
    isContentRestricted,
    poster,
    isSeriesRecording,
    hasRecording,
  } = useContentCombiner(coverImageType, focusAssetRef, cardImageType);

  if (isEmpty) return null;

  return (
    <div className={style.wrapper}>
      {(!!fallBackUrl || isContentRestricted) && <span className={style.blurImage} />}
      <AnimateBackground
        classNameOfShadow={style.bgShadow}
        url={poster}
        fallBackUrl={fallBackUrl}
        className={style.bg}
      />
      <div className={style.info}>
        <div className={style.tags}>
          <Tags
            componentName={COMPONENT_NAME}
            audioTypes={audioTypes}
            qualities={qualities}
            parentalRating={parentalRating}
          />
        </div>
        <div className={style.titleWrapper}>
          <p className={style.title}>
            {isContentRestricted ? (
              <FormattedMessage defaultMessage='Restricted content' id={`${COMPONENT_NAME}.Restricted.title`} />
            ) : (
              title
            )}
          </p>
          {hasRecording && (
            <span className={style.recordIcon}>
              <RecordIcon active multiple={isSeriesRecording} />
            </span>
          )}
        </div>
        <div className={style.specifications}>
          <SpecificationsDetails genres={genres} year={year} country={country} duration={duration} />
        </div>
        <p className={cn(style.description, { [style.descriptionHidden]: isContentRestricted })}>{description}</p>
      </div>
    </div>
  );
}

export default observer(Content);
