import { matchPath } from 'react-router';

function useGridMatchPath(path: string) {
  const { pathname } = window.location;
  const matchPathParams = matchPath({ path, end: false }, pathname)?.params;
  const id = matchPathParams?.id || '';
  const type = matchPathParams?.type || '';

  return { id, type };
}

export { useGridMatchPath };
