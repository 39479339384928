import { useEffect, useRef } from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';

import { saveAssetToGlobalStorage } from 'utils';

import { useAssetCardContext } from '../../../AssetCardContext/AssetCardContext';

function useUpdateMaxiPlayer() {
  const hasComponentAlreadyMounted = useRef(false);
  const {
    listStore,
    listIndex,
    resolvedProps: { currentAsset },
  } = useAssetCardContext();

  useEffect(() => {
    if (listStore.subtype === UIDAPresentationTypeEnum.hero) {
      if (listIndex === 0 && currentAsset.data.startDate && hasComponentAlreadyMounted.current) {
        const firstItem = listStore.items[0];

        saveAssetToGlobalStorage(firstItem);
      }

      hasComponentAlreadyMounted.current = true;
    }
  }, [currentAsset.data.startDate]);
}

export { useUpdateMaxiPlayer };
