import React from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { featureEnabler } from '@kaltura-ott/tvpil-shared';

import { FEATURES } from 'consts';

import Devices from '../Devices/Devices';
import Favorites from '../Favorites/Favorites';
import Languages from '../Languages/Languages';
import ParentalControl from '../ParentalControl/ParentalControl';
import PersonalChannelLineup from '../PersonalChannelLineup/PersonalChannelLineup';
import RhinoFavorites from '../RhinoFavorites/Favorites';
import SelfService from '../SelfService/SelfService';
import Subscriptions from '../Subscriptions/Subscriptions';
import VideoSettings from '../VideoSettings/VideoSettings';

interface Props {
  subtype: string;
  referenceId: string;
  shouldShowFavorites: boolean;
  shouldShowPersonalLineup: boolean;
}

export default function SettingsComponentResolver({
  subtype,
  referenceId,
  shouldShowFavorites,
  shouldShowPersonalLineup,
}: Props) {
  switch (subtype) {
    case UIDAPresentationTypeEnum.subscription:
      return <Subscriptions />;
    case UIDAPresentationTypeEnum.parental_control:
      return <ParentalControl />;
    case UIDAPresentationTypeEnum.language:
      return <Languages />;
    case UIDAPresentationTypeEnum.devices:
      return <Devices />;
    case UIDAPresentationTypeEnum.link_list:
      return <SelfService />;
    case UIDAPresentationTypeEnum.preferred_video_quality:
      return <VideoSettings />;
    case UIDAPresentationTypeEnum.favorites: {
      if (shouldShowFavorites) {
        const isRhinoFavoritesEnabled = featureEnabler.variation(FEATURES.NEW_MY_FAVOURITE_CHANNELS_ENABLED, true);

        return isRhinoFavoritesEnabled ? <RhinoFavorites referenceId={referenceId} /> : <Favorites />;
      }

      if (shouldShowPersonalLineup) {
        return <PersonalChannelLineup referenceId={referenceId} />;
      }

      return null;
    }

    default:
      return <h2>No subpage yet</h2>;
  }
}
