import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { ContentMarkerType } from 'types';

import { ContentMarker } from 'components/presenters/icons';
import PlayButton from 'components/widgets/buttons/PlayButton/PlayButton';
import ProgressBar from 'components/widgets/ProgressBar/ProgressBar';

import styles from './ContinueWatchingCardContent.module.scss';

function ContinueWatchingCardContent({
  shouldDisplayContentMarker,
  shouldDisplayPlayButton,
  contentMarker,
  progress,
}: {
  shouldDisplayContentMarker: boolean;
  shouldDisplayPlayButton: boolean;
  contentMarker: ContentMarkerType | undefined;
  progress: number;
}) {
  return (
    <div className={cn(styles.inner, styles.programAsset)}>
      {shouldDisplayContentMarker && contentMarker && (
        <ContentMarker className={styles.contentMarker} contentMarker={contentMarker} />
      )}
      {shouldDisplayPlayButton && <PlayButton data-type='playButton' className={styles.playBtn} />}
      <ProgressBar className={styles.progressBar} percent={progress} />
    </div>
  );
}

export default observer(ContinueWatchingCardContent);
