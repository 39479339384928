import { useMemo } from 'react';

interface Props<T extends { id: number }> {
  items: T[];
  columnCount: number;
  isFullLoaded: boolean;
}

export const useGridCardsViewItems = <T extends { id: number }>({ items, columnCount, isFullLoaded }: Props<T>) => {
  const skeletonItemsCount = useMemo(() => {
    let result = columnCount * 2;

    if (items.length) {
      result -= items.length % columnCount;
    }
    return result;
  }, [items.length]);
  const fakeItems = columnCount && skeletonItemsCount > 0 ? new Array(skeletonItemsCount).fill({}) : [];
  const gridItems = items.concat(fakeItems);

  return { gridItems: isFullLoaded ? items : gridItems };
};
