import { useIntl } from 'react-intl';

import styles from './DefaultChannelOrderMessage.module.scss';

export const DefaultChannelOrderMessage = () => {
  const intl = useIntl();

  return (
    <p className={styles.subtitle}>
      {intl.formatMessage({
        id: 'Settings.PersonalLineup.DefaultChannelOrderMessage.Subtitle',
        defaultMessage: 'You are currently using the default channel order provided by the operator',
      })}
    </p>
  );
};
