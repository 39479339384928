import { getWidthByHeight } from '@kaltura-ott/tvpil';
import { CollectionGridSettings, HeroSettings, RailSettings } from '@kaltura-ott/tvpil-shared';

import { RailSettings as RailSettingsType } from 'components/widgets/RailV2/components/RailProviderV2/types';
import { DEFAULT_HEIGHT, LANDSCAPE_RATIO } from 'consts';

// should be removed after fallback params becomes required
export const getImageSizeParams = (
  settings: RailSettings | RailSettingsType | CollectionGridSettings | HeroSettings | undefined,
) => {
  const imageHeight = settings?.imageHeight || DEFAULT_HEIGHT;
  const imageWidth = settings?.imageWidth || getWidthByHeight(imageHeight, LANDSCAPE_RATIO);

  return {
    imageHeight,
    imageWidth,
  };
};
