import React from 'react';
import cn from 'classnames';

import { SEARCH_ICON_PATH } from 'consts';
import { useAppStore } from 'hooks';
import { getSearchIcon } from 'utils';

import styles from './SearchButton.module.scss';

interface Props {
  setShowSearchInput: (value: boolean) => void;
  setIsShowHistory: (value: boolean) => void;
  isShowSearchInput: boolean;
}

function SearchButton({ setShowSearchInput, isShowSearchInput, setIsShowHistory }: Props) {
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  const handleClickSearchIcon = () => {
    const value = (document.getElementById('searchInput') as HTMLInputElement)?.value;
    if (!isShowSearchInput) {
      setShowSearchInput(true);
      setIsShowHistory(true);
    } else if (!value?.length) {
      setShowSearchInput(false);
      setIsShowHistory(false);
    }
  };

  return (
    <button
      className={cn(styles.searchIcon, { [styles.searchIconActive]: isShowSearchInput })}
      type='button'
      onClick={handleClickSearchIcon}
    >
      <img alt='search-icon' src={`${staticAssetsUrl}${getSearchIcon(SEARCH_ICON_PATH)}`} />
    </button>
  );
}

export default SearchButton;
