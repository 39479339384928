import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { TopShelfAsset } from '@kaltura-ott/tvpil-shared';

import { topShelfStorage } from '../../topShelfStorage';
import { Position } from '../../types';
import Card from '../Card/Card';
import CardContent from '../CardContent/CardContent';
import NavigationButton from '../NavigationButton/NavigationButton';

import CarouselUpdateLogic from './components/CarouselUpdateLogic/CarouselUpdateLogic';
import { useCarouselCombiner } from './hooks/useCarouselCombiner/useCarouselCombiner';
import { DURATION_OF_ANIMATION } from './constants';
import { CardCarouselProps } from './types';

import styles from './Carousel.module.scss';

function Carousel({ items, isAutoPlay, settings, focusAssetRef }: CardCarouselProps) {
  const { imageType, width, height, shouldPresentRating, carouselRef, controlsRef } = useCarouselCombiner(settings);

  return (
    <>
      <CarouselUpdateLogic
        carouselRef={carouselRef}
        autoTransitionDelay={settings.autoTransitionDelay}
        isAutoPlay={isAutoPlay}
        focusAssetRef={focusAssetRef}
        items={items}
        controlsRef={controlsRef}
      />
      <NavigationButton
        className={cn({
          [styles.buttonLandscape]: topShelfStorage.cardParams.isLandscape,
        })}
        controlsRef={controlsRef}
        position={Position.Left}
      />
      <div className={styles.inner} ref={carouselRef}>
        {items?.map((item: TopShelfAsset) => {
          return (
            <Card
              key={item.id}
              style={{
                transitionDuration: `${DURATION_OF_ANIMATION}s`,
                width,
                height,
              }}
            >
              <CardContent
                shouldPresentRating={shouldPresentRating}
                imageType={imageType}
                width={width}
                height={height}
                item={item}
              />
            </Card>
          );
        })}
      </div>
      <NavigationButton
        className={cn({
          [styles.buttonLandscape]: topShelfStorage.cardParams.isLandscape,
        })}
        controlsRef={controlsRef}
        position={Position.Right}
      />
    </>
  );
}

export default observer(Carousel);
