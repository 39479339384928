import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import successIcon from 'assets/images/icon-success.svg';
import { ActionButtonTypesEnum } from 'interface';
import routes from 'routesPaths';

import ActionButton from '../../ActionButton/ActionButton';

import { COMPONENT_NAME } from './constants';

import styles from './ConfirmationEmail.module.scss';

const successInstructionMessage =
  'We sent a message to <br /> <strong>{email}</strong> <br />Follow the instructions in the Email to reset your password';

interface Props {
  email: string;
}

function ConfirmationModal({ email }: Props) {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate(routes.login.path);
  };

  return (
    <div className={styles.confirmation}>
      <SVG src={successIcon} />
      <FormattedMessage
        tagName={(props: any) => <div {...props} className={styles.confirmationTitle} />}
        defaultMessage='Check out your Email'
        id={`${COMPONENT_NAME}.checkOutEmail`}
      />
      <FormattedMessage
        tagName={(props: any) => <div {...props} className={styles.successInstruction} />}
        defaultMessage={successInstructionMessage}
        id={`${COMPONENT_NAME}.successInstruction`}
        values={{
          email,
          br: <br />,
          strong: (text: React.ReactNode) => <b>{text}</b>,
        }}
      />
      <ActionButton type={ActionButtonTypesEnum.primary} className={styles.continue} onClick={handleContinue}>
        <FormattedMessage defaultMessage='Continue' id={`${COMPONENT_NAME}.continue`} />
      </ActionButton>
    </div>
  );
}

export default ConfirmationModal;
