import {
  TopShelfEpisodeAsset,
  TopShelfMovieAsset,
  TopShelfProgramAsset,
  TopShelfSeriesAsset,
} from '@kaltura-ott/tvpil-shared';

export enum Position {
  Left,
  Right,
}

export type TopShelfAsset = TopShelfMovieAsset | TopShelfProgramAsset | TopShelfEpisodeAsset | TopShelfSeriesAsset;
