import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { useToastMessageContext } from 'context/toast/toast';
import routes from 'routesPaths';

import { IN_APP_LINK_HOME } from '../../constans';

import { useLogoNavigation } from './hooks/useLogoNavigation';
import { useRebootApp } from './hooks/useRebootApp';

import styles from './Logo.module.scss';

type LogoProps = {
  className?: string;
  image?: string;
  path?: string;
};

const Logo: React.FC<LogoProps> = ({ className, image, path = '/' }) => {
  const toastMessageContext = useToastMessageContext();

  const { location, targetRoute, setTargetRoute, handleNavigate } = useLogoNavigation();
  useRebootApp(location, targetRoute, setTargetRoute);

  const handleClick = useCallback(
    async (e: React.SyntheticEvent) => {
      if ([routes.home.path, routes.page.link(IN_APP_LINK_HOME)].includes(path)) {
        e.preventDefault();
        toastMessageContext.clearToast();

        handleNavigate(path);
      }
    },
    [handleNavigate, path, toastMessageContext],
  );

  return (
    <NavLink className={cn(styles.logo, className)} onClick={handleClick} to={path} data-target='logo'>
      <img alt='Logo' src={image} />
    </NavLink>
  );
};

export default React.memo(Logo);
