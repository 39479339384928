import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useParentalControlContext } from 'context/parentalControlContext/parentalControlContext';
import { useParentalControlStore, useParentalPinStore } from 'hooks';

import { FULL_COMPONENT_NAME } from '../../constans';

import styles from './AgeRanges.module.scss';

const AgeRanges = () => {
  const intl = useIntl();
  const { noThresholdAge } = useParentalPinStore();
  const {
    parentalControlStore: { parentalAgeGroups },
  } = useParentalControlStore();
  const { ageThreshold, onChangeAgeRange } = useParentalControlContext();
  const ages = [noThresholdAge, ...parentalAgeGroups].reverse();

  return (
    <div className={styles.age}>
      <span className={styles.range}>
        {ages.map((age: string) => {
          const item = age.trim();
          const noneItem =
            item === noThresholdAge &&
            intl.formatMessage({ defaultMessage: 'None', id: `${FULL_COMPONENT_NAME}.ageDefault` });
          return (
            <Fragment key={item}>
              <input
                checked={item === ageThreshold}
                className={styles.input}
                id={item}
                name='ageRange'
                onChange={onChangeAgeRange}
                type='radio'
                value={item}
              />
              <label className={styles.label} htmlFor={item}>
                {noneItem || (
                  <>
                    <FormattedMessage defaultMessage='+' id={`${FULL_COMPONENT_NAME}.Restriction.sign`} />
                    {item}
                  </>
                )}
              </label>
              {!noneItem && <i className={styles.solid} />}
            </Fragment>
          );
        })}
      </span>
    </div>
  );
};

export default React.memo(AgeRanges);
