import { millisecondsInMinute } from 'date-fns';

import {
  getTopShelfAssetDuration,
  getTopShelfAssetTitle,
  isTopShelfPackageAsset,
  RhinoImage,
  TopShelfAsset,
} from '@kaltura-ott/tvpil-shared';

import { resolveMillisecondsToMinutes } from 'utils';

function getContentProps(activeItem: TopShelfAsset | undefined) {
  let audioTypes: string[] = [];
  let parentalRating = '';
  let genres: string[] = [];
  let year = '';
  let qualities: string[] = [];
  let country = '';
  let duration = '';
  let images: RhinoImage[] = [];
  let description = '';
  let title = '';

  if (activeItem) {
    const dr = getTopShelfAssetDuration(activeItem);
    const conditionsOfDuration = Boolean(dr && dr / millisecondsInMinute > 1);

    images = activeItem.images;
    description = activeItem.description;
    title = getTopShelfAssetTitle(activeItem);
    duration = dr && conditionsOfDuration ? resolveMillisecondsToMinutes(dr) : '';

    if (!isTopShelfPackageAsset(activeItem)) {
      audioTypes = activeItem.audioTypes || [];
      parentalRating = activeItem.parentalRating ? String(activeItem.parentalRating) : '';
      genres = activeItem.genres || [];
      year = activeItem.year ?? '';
      qualities = activeItem.qualities || [];
      country = activeItem.country ?? '';
    }
  }

  return {
    images,
    description,
    title,
    audioTypes,
    parentalRating,
    genres,
    year,
    qualities,
    country,
    duration,
  };
}

export { getContentProps };
