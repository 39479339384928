import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import NotAllowedPurchase from 'components/presenters/NotAllowedPurchase/NotAllowedPurchase';

import { COMPONENT_NAME } from '../../../../constants';
import { useVideoContentDataCombiner } from '../../hooks/useVideoContentDataCombiner';
import ButtonsDetails from '../ButtonsDetails/ButtonsDetails';
import {
  AvailableForWatching,
  Cast,
  Channel,
  EpisodeInfo,
  Image,
  Localization,
  MosaicStreamsList,
  Progress,
  ProviderLogo,
  TitleInfo,
} from '../index';

import styles from './VideoContentInner.module.scss';

interface Props {
  image?: string;
  isLandscapeRatio?: boolean;
  isDataLoaded?: boolean;
}

function VideoContentInner({ image, isLandscapeRatio, isDataLoaded }: Props) {
  const {
    isLandscape,
    isPoster,
    isVisibleProviderLogo,
    providerLogoPath,
    isCatchupSeries,
    title,
    record,
    seriesRecord,
    isButtonDetails,
    closeOverlayAndHideButtons,
    bookmarkInfo,
    remainingTime,
    isVod,
    displayLanguage,
    latinLanguage,
    isAvailableForWatching,
    isMosaic,
    staticAssetsUrl,
    isShowingNotAllowedPurchase,
    isOverlay,
  } = useVideoContentDataCombiner({ isLandscapeRatio });

  return (
    <div
      className={cn(
        { [styles.landscape]: isLandscape },
        { [styles.posters]: isPoster },
        { [styles.overlay]: isOverlay },
      )}
    >
      <div className={styles.content}>
        {isVisibleProviderLogo && (
          <ProviderLogo
            path={providerLogoPath!}
            className={cn({ [styles.providerLandscape]: isLandscape, [styles.providerPoster]: isPoster })}
          />
        )}
        <TitleInfo
          isOverlay={isOverlay}
          isLandscape={isLandscape}
          isCatchupSeries={isCatchupSeries}
          record={record}
          seriesRecord={seriesRecord}
        />
        {isButtonDetails && (
          <div className={cn(styles.buttonDetails, { [styles.isDataNotLoaded]: !isDataLoaded })}>
            <ButtonsDetails
              className={cn({ [styles.buttons]: !isOverlay })}
              closeOverlayAndHideButtons={closeOverlayAndHideButtons}
              watchedDuration={bookmarkInfo.watchedDuration}
              finishedWatching={bookmarkInfo.finishedWatching}
            />
          </div>
        )}
        <Progress
          watchedDuration={bookmarkInfo.watchedDuration}
          remainingTime={remainingTime}
          finishedWatching={bookmarkInfo.finishedWatching}
        />
        {!isVod && <Channel displayLanguage={displayLanguage} latinLanguage={latinLanguage} />}
        {!isCatchupSeries && <EpisodeInfo />}
        <Cast />
        <Localization />
        {isAvailableForWatching && (
          <AvailableForWatching latinLanguage={latinLanguage} displayLanguage={displayLanguage} />
        )}
        {isMosaic && <MosaicStreamsList />}
      </div>
      {!isOverlay && (
        <Image title={title} staticAssetsUrl={staticAssetsUrl} image={image} isLandscapeRatio={isLandscapeRatio} />
      )}
      {/* for TVOD we show not allowed purchase message on details page, not on offer page */}
      {isShowingNotAllowedPurchase && (
        <NotAllowedPurchase className={styles.warningMessage} componentName={`${COMPONENT_NAME}.VideoContent`} />
      )}
    </div>
  );
}

export default observer(VideoContentInner);
