import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { Node } from 'types';

import { QuotaStatusEnum } from '@kaltura-ott/tvpil';

import RecordButton from 'components/widgets/buttons/RecordButton/RecordButton';
import { useVideoContext } from 'context/videoContext/videoContext';
import { useDetectLocation, useIsNpvr, useRecordRepository } from 'hooks';
import { applyStyle, checkIsMosaicProgram, getSeasonEpisodeTitle } from 'utils';

import detailsStoreDataResolver from '../../../../utils/detailsStoreDataResolver';
import { useCheckProgramAvailability } from '../../hooks/useCheckProgramAvailability/useCheckProgramAvailability';

import {
  ButtonsDescription,
  BuyPackageButton,
  EpisodesButton,
  RentButton,
  StartOverButton,
  WatchLiveButton,
  WatchResumeButton,
} from './components';

import styles from './ButtonsDetails.module.scss';

export interface Props {
  finishedWatching?: boolean;
  closeOverlayAndHideButtons(isNotHidingButtonsCondition: boolean): void;
  watchedDuration: number;
  className?: string;
}

function ButtonsDetails({ closeOverlayAndHideButtons, watchedDuration, className, finishedWatching }: Props) {
  const intl = useIntl();
  const recordRepository = useRecordRepository();
  const { firstEpisode, program, isOverlay } = useVideoContext();
  const { isDetailsSeries } = useDetectLocation();
  const isNpvr = useIsNpvr();
  const asset = firstEpisode || program;

  const {
    adult,
    enableCatchUp,
    mediaType,
    isLive: isLivePosterView,
    isPast,
    isPurchased,
    isRecordable,
    isSvod,
    isTvod,
    isVodSingle,
    selectedSubscription,
    seriesId,
    isProviderAsset,
    id,
    metas,
    mosaicInfo,
    record,
    isProgramRecord,
  } = detailsStoreDataResolver(asset);

  const isOnGoingRecord = record?.data.isOngoing;
  const isMosaicProgram = checkIsMosaicProgram(mosaicInfo);
  const isNpvrEnabled = recordRepository.space.status !== QuotaStatusEnum.NpvrServiceNotAllowed;
  const isEpisodesBtnCondition =
    !!seriesId && !isDetailsSeries && !isOverlay && !isNpvr && (!isOnGoingRecord || !isProgramRecord);
  const seasonEpisodeTitle = isProviderAsset ? '' : getSeasonEpisodeTitle(intl, metas, ':', false);
  const isPpv = isTvod && !(isDetailsSeries && isSvod);
  const watchResumeCondition = isPast || isVodSingle;

  const isFinishWatchCondition = finishedWatching || !watchedDuration;
  const recordingCondition = !adult && !isVodSingle && isNpvrEnabled;
  const startOverCondition =
    isLivePosterView || (isVodSingle && !isFinishWatchCondition) || (!isDetailsSeries && !isFinishWatchCondition);
  const programNotAvailable = useCheckProgramAvailability(asset);

  const isRecordBtnDisabled = !isRecordable || (!isNpvr && programNotAvailable);
  const handleRef = useCallback((node: Node) => {
    if (node !== null) {
      let maxWidth = 0;

      Array.from(node.children).forEach((item: Element) => {
        if (item instanceof HTMLElement && item.offsetWidth > maxWidth) {
          maxWidth = item.offsetWidth;
        }
      });

      applyStyle(node, '--max-width', `${maxWidth}px`);
    }
  }, []); // eslint-disable-line
  return (
    <>
      <ButtonsDescription asset={asset} isPpv={isPpv} />
      <div ref={handleRef} className={cn(styles.buttons, className)}>
        {!isMosaicProgram && (
          <>
            {!isPurchased ? (
              <>
                {isPpv && (
                  <RentButton
                    className={styles.actionButton}
                    asset={program}
                    seasonEpisodeTitle={isDetailsSeries ? seasonEpisodeTitle : ''}
                  />
                )}
                {!!selectedSubscription && !isPpv && (
                  <BuyPackageButton
                    asset={asset}
                    className={styles.actionButton}
                    programNotAvailable={programNotAvailable}
                  />
                )}
              </>
            ) : (
              <>
                {(!isOnGoingRecord || !isProgramRecord) && (
                  <>
                    {watchResumeCondition && (
                      <WatchResumeButton
                        asset={asset}
                        className={styles.actionButton}
                        seasonEpisodeTitle={seasonEpisodeTitle}
                        programNotAvailable={programNotAvailable}
                        isFinishWatchCondition={isFinishWatchCondition}
                      />
                    )}
                    {isLivePosterView && <WatchLiveButton asset={asset} className={styles.actionButton} />}
                    {startOverCondition && (
                      <StartOverButton
                        asset={asset}
                        className={styles.actionButton}
                        isLive={isLivePosterView}
                        programNotAvailable={programNotAvailable}
                      />
                    )}
                  </>
                )}

                {recordingCondition && (
                  <RecordButton
                    assetId={id}
                    data-disabled={isRecordBtnDisabled ? 'disabled' : null}
                    className={styles.actionButton}
                    disabled={isRecordBtnDisabled}
                    enableCatchUp={enableCatchUp}
                    callback={closeOverlayAndHideButtons}
                    programNotAvailable={programNotAvailable}
                    seriesId={metas?.SeriesID}
                  />
                )}
              </>
            )}
          </>
        )}
        {isEpisodesBtnCondition && (
          <EpisodesButton className={styles.actionButton} mediaType={mediaType} seriesId={seriesId!} />
        )}
      </div>
    </>
  );
}

export default observer(ButtonsDetails);
