import React from 'react';
import { useIntl } from 'react-intl';

import SettingsContentWrapper from 'components/pages/Settings/SettingsContentWrapper/SettingsContentWrapper';

import styles from './PersonalLineupContentWrapper.module.scss';

type Props = {
  children: React.ReactNode;
};

export function PersonalLineupContentWrapper({ children }: Props) {
  const intl = useIntl();

  return (
    <SettingsContentWrapper
      title={intl.formatMessage({
        id: 'Settings.PersonalLineup.PersonalLineupContentWrapper.title',
        defaultMessage: 'Personalized Channels',
      })}
      description={intl.formatMessage({
        id: 'Settings.PersonalLineup.PersonalLineupContentWrapper.description',
        defaultMessage: 'Move channels to create your personal list of channels',
      })}
      className={styles.personalLineupWrapper}
    >
      {children}
    </SettingsContentWrapper>
  );
}
