import {
  CollectionAsset,
  getCollectionAssetTitle,
  isCollectionProgramAsset,
  useLinears,
} from '@kaltura-ott/tvpil-shared';

export const useCollectionCardChannelInfo = (item: CollectionAsset) => {
  const title = getCollectionAssetTitle(item);
  const { linears } = useLinears();
  const channel = isCollectionProgramAsset(item) ? linears.get(item.channelId) : undefined;
  const channelName = channel?.data.title;

  return { title, channelName };
};
