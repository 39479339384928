import { observer } from 'mobx-react';

import {
  useAppAwakened,
  useAppBeforeUnLoad,
  useAppBranding,
  useAppCheckCookieConfirmation,
  useAppErrorReaction,
  useAppEvents,
  useAppGDPRCustomScript,
  useAppHistoryListener,
  useAppImagesPreload,
  useApplicationErrorEvents,
  useAppNpvrHandler,
  useAppParentalPin,
  useAppPreloadAfterLoad,
  useAppPreparePlayer,
  useAppSentryInit,
  useAppTitleManager,
  useDetailsPageBackNavigation,
  useScrollToTop,
} from 'hooks';

function AppHooksCombiner() {
  // apply the gdpr custom script
  useAppGDPRCustomScript();
  // apply branding rules
  useAppBranding();
  // adding and removing an event listener
  useAppEvents();
  // setting and deleting values in the browser's session storage on an beforeunload event
  useAppBeforeUnLoad();
  // initializing sentry under certain conditions
  useAppSentryInit();
  // to check apiError field from tvpill
  useAppErrorReaction();
  // to check parental
  useAppParentalPin();
  // for manipulation when changing the url
  useAppHistoryListener();
  // for manipulation with 2 step back navigation for details pages
  useDetailsPageBackNavigation();
  // to check if the player is available
  useAppPreparePlayer();
  // to confirmation cookie
  useAppCheckCookieConfirmation();
  // to preload images from static repo
  useAppImagesPreload();
  // to preload EPG
  useAppPreloadAfterLoad();
  // application error analytics
  useApplicationErrorEvents();
  // auto scroll to page top if user comes to new page
  useScrollToTop();
  // configure app title
  useAppTitleManager();
  // app awaking after stand-by mode
  useAppAwakened();
  // to go 2 steps back after cancelling recording
  useAppNpvrHandler();

  return null;
}

export default observer(AppHooksCombiner);
