import { useEffect } from 'react';

type UseBeforeUnloadWarningArgs = {
  shouldWarn: boolean;
};

export function useBeforeUnloadWarning({ shouldWarn }: UseBeforeUnloadWarningArgs) {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldWarn) {
        event.preventDefault();
        event.returnValue = true;

        return true;
      }
      return false;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldWarn]);
}
