/* eslint-disable no-continue */
import { Channel } from '@kaltura-ott/tvpil-shared';

type ShiftChannelNumbersForwardArgs = { startIndex: number; destinationIndex: number; channels: Array<Channel> };

export function shiftChannelNumbersForward({
  startIndex,
  destinationIndex,
  channels,
}: ShiftChannelNumbersForwardArgs): Array<Channel> {
  const subArrayBefore = channels.slice(0, startIndex);
  const subArrayAfter = channels.slice(destinationIndex + 1);
  const subArrayMiddle = channels.slice(startIndex, destinationIndex);

  const targetedChannel = channels[destinationIndex];

  const shiftedSubarray = [];

  let freeChannelNumber = targetedChannel.virtualNumber;

  for (let i = 0; i < subArrayMiddle.length; i += 1) {
    const channel = subArrayMiddle[i];

    if (!channel.virtualNumber) {
      shiftedSubarray.push(channel);
      continue;
    }

    const nextChannelNumber = subArrayMiddle[i + 1]?.virtualNumber;

    // if there's a gap between the current and next channel number, update the freeChannelNumber
    if (nextChannelNumber && nextChannelNumber - channel.virtualNumber > 1) {
      freeChannelNumber = channel.virtualNumber + 1;
    }

    // do not modify the channel number for adult channels
    if (channel.isAdult) {
      shiftedSubarray.push(channel);
      continue;
    }

    // assign the first free channel number to the current channel and update freeChannelNumber
    shiftedSubarray.push({ ...channel, virtualNumber: freeChannelNumber });
    freeChannelNumber = channel.virtualNumber;
  }

  const composedMiddleSubArray = [
    ...shiftedSubarray,
    {
      ...targetedChannel,
      virtualNumber: freeChannelNumber,
    },
  ];

  const sortedMiddleSubArray = composedMiddleSubArray.sort((a, b) => a.virtualNumber! - b.virtualNumber!);

  const composedChannels = [...subArrayBefore, ...sortedMiddleSubArray, ...subArrayAfter];

  return composedChannels;
}
