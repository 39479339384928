import { action, observable } from 'mobx';

import { Linear, ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

class GlobalStorage {
  @observable isPlayerError = false;

  @observable isFullPlayer = false;

  @observable isSkipDetailsPage: boolean = false;

  @observable detailsAsset?: ViewableEntitiesType | Linear = undefined;

  @observable focusAsset: Linear | undefined = undefined; // will be removed after transition to the new implementation of rail

  @observable focusAssetV2: any = undefined; // for new rail implementation

  @observable isLogoutInProgress = false;

  @observable isPossiblePresentPlayer?: boolean = undefined;

  @observable isNotFound = false;

  @observable isDisableMaxiPlayer = true; // because if initial state is false and hero rail does not exist, setDisableMaxiPlayer never called inside hero rail

  @observable isAwakened = false;

  @observable isOnBoarding = false;

  @observable isCanPlayFired = false;

  @observable isUrlToFullPlayer = false;

  @observable isKsErrorHandled = false;

  @action
  setIsKsErrorHandled(value: boolean) {
    this.isKsErrorHandled = value;
  }

  @action
  setIsSkipDetailsPage(value: boolean) {
    this.isSkipDetailsPage = value;
  }

  @action
  setDisableMaxiPlayer(value: boolean) {
    this.isDisableMaxiPlayer = value;
  }

  @action
  clearIsSkipDetailsPage() {
    this.isSkipDetailsPage = false;
  }

  @action
  setIsUrlToFullPlayer(value: boolean) {
    this.isUrlToFullPlayer = value;
  }

  @action
  setIsOnBoarding(value: boolean) {
    this.isOnBoarding = value;
  }

  @action
  clearIsOnBoarding() {
    this.isOnBoarding = false;
  }

  @action
  setIsCanPlayFired(value: boolean) {
    this.isCanPlayFired = value;
  }

  @action
  clearIsCanPlayFired() {
    this.isCanPlayFired = false;
  }

  @action
  setIsAwakened(value: boolean) {
    this.isAwakened = value;
  }

  @action
  clearIsAwakened() {
    this.isAwakened = false;
  }

  @action
  setIsLogoutInProgress(value: boolean) {
    this.isLogoutInProgress = value;
  }

  @action
  clearIsLogoutInProgress() {
    this.isLogoutInProgress = false;
  }

  @action
  setIsNotFound(value: boolean) {
    this.isNotFound = value;
  }

  @action
  clearIsNotFound() {
    this.isNotFound = false;
  }

  @action
  setIsFullPlayer(value: boolean) {
    this.isFullPlayer = value;
  }

  @action
  clearIsFullPlayer() {
    this.isFullPlayer = false;
  }

  @action
  setIsPlayerError(value: boolean) {
    this.isPlayerError = value;
  }

  @action
  clearIsPlayerError() {
    this.isPlayerError = false;
  }

  @action
  setPossiblePresentPlayer(value: boolean) {
    this.isPossiblePresentPlayer = value;
  }

  @action
  clearPossiblePresentPlayer() {
    this.isPossiblePresentPlayer = false;
  }

  @action
  setFocusAssetV2(value: any) {
    this.focusAssetV2 = value;
  }

  @action
  setFocusAsset(value: any) {
    this.focusAsset = value; // will be removed after transition to the new implementation of rail
  }

  @action
  clearFocusAsset() {
    this.focusAsset = undefined; // will be removed after transition to the new implementation of rail
    // focusAssetV2 for new implementation of rail
    this.focusAssetV2 = undefined;
  }

  @action
  setDetailsAsset(value?: ViewableEntitiesType | Linear) {
    this.detailsAsset = value instanceof Linear ? value.currentProgram : value;
  }

  @action
  clearDetailsAsset() {
    this.detailsAsset = undefined;
  }

  @action
  clear() {
    this.clearIsPlayerError();
    this.clearIsFullPlayer();
    this.clearPossiblePresentPlayer();
    this.clearIsLogoutInProgress();
    this.clearIsNotFound();
    this.clearIsOnBoarding();
    this.clearIsCanPlayFired();
  }
}

const globalStorage = new GlobalStorage();

export { globalStorage };
