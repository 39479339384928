import { CollectionAsset, getAssetImageUrl, WatchedAsset } from '@kaltura-ott/tvpil-shared';

import { RailSettings } from 'components/widgets/RailV2/components/RailProviderV2/types';
import { getImageSizeParams } from 'utils/getImageSizeParams';

export async function preloadImage(
  cleanupFunction: React.MutableRefObject<boolean>,
  setImagesLoading: Function,
  items: CollectionAsset[] | WatchedAsset[],
  settings: RailSettings,
) {
  const promises: Promise<void>[] = [];
  const { imageType } = settings;
  const { imageHeight, imageWidth } = getImageSizeParams(settings);

  items.forEach(({ images }) => {
    const src = getAssetImageUrl(images, {
      height: imageHeight,
      type: imageType,
      width: imageWidth,
    });

    if (images && src) {
      promises.push(
        new Promise((resolve) => {
          const img = new Image();

          img.src = src!;
          img.onload = resolve as any;
          img.onerror = resolve as any;
        }),
      );
    }
  });

  if (!cleanupFunction.current) {
    setImagesLoading(true);
  }

  await Promise.all(promises);

  if (!cleanupFunction.current) {
    setImagesLoading(false);
  }
}
