import { Dispatch, SetStateAction, useCallback } from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { featureEnabler, prefetchChannels } from '@kaltura-ott/tvpil-shared';

import { FEATURES } from 'consts';
import { useAppStore } from 'hooks/common/useAppStore';

export const usePreloadAndPrefetchChannels = (setIsNotAllowedCall: Dispatch<SetStateAction<boolean>>) => {
  const { appStore } = useAppStore();

  return useCallback(() => {
    const subtypes: UIDAPresentationTypeEnum[] | undefined = [];
    const isNewEpg = featureEnabler.variation(FEATURES.IS_NEW_EPG, true);
    const isNewHeroRail = featureEnabler.variation(FEATURES.NEW_HERO_RAIL_ENABLED, true);

    if (!isNewEpg) {
      subtypes.push(UIDAPresentationTypeEnum.epg);
    }
    if (!isNewHeroRail) {
      subtypes.push(UIDAPresentationTypeEnum.hero);
    }

    if (subtypes.length) {
      appStore.preloadAfterLoad(subtypes, isNewEpg);
    }

    if (isNewHeroRail) {
      const referenceId = appStore.rootStore.getHomePageHeroRailReferenceId();

      if (referenceId) {
        prefetchChannels({ referenceId });
      }
    }

    if (isNewEpg) {
      const referenceId = appStore.rootStore.getEpgReferenceId();

      if (referenceId) {
        prefetchChannels({ referenceId });
      }
    }

    setIsNotAllowedCall(true);
  }, []);
};
