import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router';

import { useCancelConfirmationModal } from './useCancelConfirmationModal';

type UseUnsavedChangesWarningArgs = {
  onAccept: () => void;
  shouldWarn: boolean;
  shouldBlockRouter: boolean;
};

/**
 * Custom hook to warn users about unsaved changes when navigating away from the current page.
 * It uses a blocker from react-router to intercept navigation and shows a confirmation modal if there are unsaved changes.
 *
 * @param onAccept - Callback function to execute when user accepts the navigation.
 * @param shouldWarn - Flag indicating whether there are unsaved changes.
 * @param shouldBlockRouter - Flag indicating whether the router should be blocked regardless of unsaved changes.
 */
export function useUnsavedChangesWarning({ onAccept, shouldWarn, shouldBlockRouter }: UseUnsavedChangesWarningArgs) {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      (shouldWarn && currentLocation.pathname !== nextLocation.pathname) || shouldBlockRouter,
  );

  const { openModal: showCancelConfirmationModal } = useCancelConfirmationModal();

  const resetBlocker = useCallback(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
  }, [blocker]);

  // Effect to reset the blocker and close the modal if there are no unsaved changes.
  useEffect(() => {
    if (blocker.state === 'blocked' && !shouldWarn) {
      blocker.proceed();
      blocker.reset();
    }
  }, [blocker, shouldWarn]);

  // Effect to handle the display of the confirmation modal when navigation is blocked.
  useEffect(() => {
    if (blocker.state === 'blocked' && shouldWarn && !shouldBlockRouter) {
      showCancelConfirmationModal({
        onAccept: () => {
          onAccept();
          blocker.proceed();
          blocker.reset();
        },
        onCancel: () => {
          blocker.reset();
        },
        onDecline: resetBlocker,
      });
    }
  }, [blocker, blocker.state, shouldWarn, onAccept, resetBlocker, shouldBlockRouter, showCancelConfirmationModal]);
}
