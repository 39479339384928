import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';

export const useLogoNavigation = () => {
  const { navigate } = useStableReactRouter();
  const location = useLocation();
  const [targetRoute, setTargetRoute] = useState<string | null>(null);

  const handleNavigate = useCallback(
    (to: string) => {
      setTargetRoute(to);
      navigate(to);
    },
    [navigate],
  );

  return {
    location,
    targetRoute,
    setTargetRoute,
    handleNavigate,
  };
};
