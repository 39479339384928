import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { useTimer } from '@kaltura-ott/tvpil-shared';

import ProgressBar from 'components/widgets/ProgressBar/ProgressBar';

import { topShelfStorage } from '../../topShelfStorage';

const PERCENT_OF_WIDTH = 100;

interface Props {
  className: string;
  transitionDelay: number;
}

function ProgressBarTopShelf({ className, transitionDelay }: Props) {
  const [percent, setPercent] = useState(0);
  const interval = transitionDelay / PERCENT_OF_WIDTH;
  const handleTimerTick = useCallback(() => {
    setPercent((prev: number) => prev + 1);
  }, []);

  useTimer({ interval, onTimerTick: handleTimerTick });

  useEffect(() => {
    setPercent(0);
  }, [topShelfStorage.isUpdated]);

  return <ProgressBar className={cn(className)} percent={percent} />;
}

export default observer(ProgressBarTopShelf);
