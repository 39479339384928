import { useCallback } from 'react';

import { useRailHorisontalDataContext } from 'components/widgets/Rail/components/RailHorisontalDataProvider/RailHorisontalDataProvider';

export const useMouseLeaveHandle = () => {
  const { onCardHover } = useRailHorisontalDataContext();
  return useCallback(
    () => {
      if (onCardHover) {
        onCardHover(false);
      }
    },
    [], // eslint-disable-line
  );
};
