import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { featureEnabler, RailStoreV2 } from '@kaltura-ott/tvpil-shared';

import { FEATURES } from 'consts';

import { checkIsLogoRail } from './checkIsLogoRail';

export function checkIsContinueWatchingRail(rail: RailStoreV2) {
  const isEnabledCwRail = !featureEnabler.variation(FEATURES.DISABLE_NEW_CW_RAIL, false);

  return rail?.subtype === UIDAPresentationTypeEnum.cw && !checkIsLogoRail(rail) && isEnabledCwRail;
}
