import React, { useCallback } from 'react';

import { Channel, useCreatePersonalLineup, useResetPersonalLineup } from '@kaltura-ott/tvpil-shared';
import { PersonalLineupEntry } from '@kaltura-ott/tvpil-shared/lib/rhino/phoenix';

import { Action } from '../enum';
import { addVirtualNumbers } from '../helper';

import { useErrorNotice } from './useErrorNotice';
import { useSuccessNotice } from './useSuccessNotice';

type UsePersonalLineupActionsArgs<T extends PersonalLineupEntry> = {
  setChannels: React.Dispatch<React.SetStateAction<Channel[] | null>>;
  updateLineup: React.Dispatch<React.SetStateAction<T[] | null>>;
  lineup: Array<T> | null;
  originalChannels: Channel[] | undefined;
};

export const usePersonalLineupActions = <T extends PersonalLineupEntry>({
  updateLineup,
  setChannels,
  lineup,
  originalChannels,
}: UsePersonalLineupActionsArgs<T>) => {
  const errorNotice = useErrorNotice();
  const successNotice = useSuccessNotice();

  const { createPersonalLineup, isCreating } = useCreatePersonalLineup();
  const { resetPersonalLineup, isResetting } = useResetPersonalLineup();

  const saveNewLineup = useCallback(async () => {
    if (!lineup) {
      return;
    }

    try {
      await createPersonalLineup(lineup);

      updateLineup(null);

      successNotice(Action.ReorderLineupSuccess);
    } catch (e) {
      errorNotice(Action.ReorderLineupFailure);
    }
  }, [createPersonalLineup, errorNotice, lineup, successNotice, updateLineup]);

  const revertToDefaultLineup = useCallback(async () => {
    try {
      await resetPersonalLineup();

      updateLineup(null);
      setChannels(null);

      successNotice(Action.RevertLineupSuccess);
    } catch (e) {
      errorNotice(Action.RevertLineupFailure);
    }
  }, [errorNotice, resetPersonalLineup, setChannels, successNotice, updateLineup]);

  const cancelLineupOrder = useCallback(() => {
    if (originalChannels) {
      const channelsWithVirtualChannelNumber = addVirtualNumbers(originalChannels);

      setChannels(channelsWithVirtualChannelNumber);

      updateLineup(null);
    }
  }, [originalChannels, setChannels, updateLineup]);

  return {
    cancelLineupOrder,
    saveNewLineup,
    revertToDefaultLineup,
    isSaving: isCreating,
    isResetting,
  };
};
