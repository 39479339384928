import {
  CollectionAsset,
  CollectionProgramAsset,
  getCollectionAssetChannelId,
  getCollectionAssetContentMarker,
  getProgramAiringDate,
  isCollectionAssetPurchasable,
  isCollectionChannelAsset,
  isCollectionEpisodeAsset,
  isCollectionInAppLinkAdult,
  isCollectionLinearAsset,
  isCollectionLinkAsset,
  isCollectionLinkAssetValid,
  isCollectionMovieAsset,
  isCollectionProgramAsset,
  isCollectionSeriesAsset,
  isCollectionVodAsset,
  shouldPlayOnExternalApp,
  useLinears,
} from '@kaltura-ott/tvpil-shared';

interface Props {
  data: CollectionAsset;
  isGrouped: boolean;
}

function useCollectionCardMainProps({ data, isGrouped }: Props) {
  const contentMarker = getCollectionAssetContentMarker(data);
  const isLinearAsset = isCollectionLinearAsset(data);
  const isLinkAsAsset = isCollectionLinkAsset(data);
  const isValidLinkAsAsset = isLinkAsAsset && isCollectionLinkAssetValid(data);
  const isVod = isCollectionVodAsset(data);
  const isProgramAsset = isCollectionProgramAsset(data);
  const isTypeOfSeries = isCollectionSeriesAsset(data);
  const isCollectionMovie = isCollectionMovieAsset(data);
  const isCollectionEpisode = isCollectionEpisodeAsset(data);
  const adult = data.isAdult;
  const isCollectionInAppAdultLink = isCollectionInAppLinkAdult(data);
  const isAssetPlayOnExternalApp = shouldPlayOnExternalApp(data);
  const isFuture = isProgramAsset && data.startDate > Date.now();
  const isChannelAsset = isCollectionChannelAsset(data);
  const isCatchupSeries = isGrouped && isProgramAsset && Boolean(data.seriesId);
  const { linears } = useLinears(); // TODO: must be replaced by useChannelsDictionary
  const catchUpAirDate = getProgramAiringDate(data as CollectionProgramAsset);
  const channelInfo = isLinearAsset ? linears.get(getCollectionAssetChannelId(data)) : undefined;
  const isPurchasable = isCollectionAssetPurchasable(data, linears);
  const hasSeriesId = (isCollectionEpisode || isProgramAsset) && Boolean(data.seriesId);
  const isSingleVod = isCollectionEpisode || isCollectionMovie;

  return {
    contentMarker,
    isLinkAsAsset,
    isValidLinkAsAsset,
    isPurchasable,
    isVod,
    isProgramAsset,
    isTypeOfSeries,
    isCollectionEpisode,
    adult,
    isAssetPlayOnExternalApp,
    isFuture,
    isCatchupSeries,
    catchUpAirDate,
    channelInfo,
    hasSeriesId,
    isSingleVod,
    isChannelAsset,
    isCollectionInAppAdultLink,
  };
}

export { useCollectionCardMainProps };
