import { SetStateAction, useCallback, useState } from 'react';

import { CollectionAsset, useRailCollection, useRailSettings } from '@kaltura-ott/tvpil-shared';

import { RailProviderData, RailSettings } from 'components/widgets/RailV2/components/RailProviderV2/types';
import { useHandleSkeletonRef } from 'components/widgets/RailV2/hooks/useHandleSkeletonRef/useHandleSkeletonRef';
import { RAIL_PAGE_SIZE } from 'consts';

function useCollectionRail(referenceId: string) {
  const originalSettings = useRailSettings({ referenceId });
  const originalData = useRailCollection(referenceId, RAIL_PAGE_SIZE);

  const [shouldPreventDisplay, setShouldPreventDisplay] = useState(true);

  const setShouldPreventDisplayForward = useCallback(
    (value: SetStateAction<boolean>) => setShouldPreventDisplay(value),
    [],
  );

  const hasNoData = !originalData.items.length && !originalData.isLoading;
  const isRailEmpty = hasNoData || Boolean(originalData.error);

  const data: RailProviderData<CollectionAsset> = {
    items: originalData.items,
    totalCount: originalData.totalCount,
    hasNextPage: originalData.hasNextPage,
    isLoading: originalData.isLoading,
    error: originalData.error,
    fetchNextPage: originalData.fetchNextPage,
    refetch: originalData.refetch,
  };
  const settings: RailSettings = {
    branding: originalSettings?.branding!,
    id: originalSettings?.id!,
    imageHeight: originalSettings?.imageHeight,
    imageType: originalSettings?.imageType,
    imageWidth: originalSettings?.imageWidth,
    title: originalSettings?.title!,
    moreLink: originalSettings?.moreLink,
    shouldShowRating: originalSettings?.shouldShowRating || false,
    shouldShowEntitlementIndicator: originalSettings?.shouldShowEntitlementIndicator || false,
  };
  const handleSkeletonRef = useHandleSkeletonRef(setShouldPreventDisplayForward, originalData.items, settings);

  return {
    data,
    settings: originalSettings ? settings : null,
    isRailEmpty,
    shouldPreventDisplay,
    handleSkeletonRef,
  };
}

export { useCollectionRail };
