import { AssetWithSeriesId } from 'types';

import { CollectionAsset, CollectionLinkAsset, convertRhinoAssetTypeToMediaType } from '@kaltura-ott/tvpil-shared';

import { FROM_REFERENCE_ID } from 'consts';
import { useMenuStore, usePageId, useSearchParam } from 'hooks';

import { resolveLinkAsAsset } from '../../helpers/resolveLinkAsAsset/resolveLinkAsAsset';
import { resolveLinkProgramAsset } from '../../helpers/resolveLinkProgramAsset/resolveLinkProgramAsset';

interface Props {
  data: CollectionAsset;
  isSingleVod: boolean;
  isFutureProgram: boolean;
  collectionType: string | undefined;
  isLinkAsAsset: boolean;
  isChannelAsset: boolean;
  isAsPlayAsset: boolean;
}

function useCardLink({
  data,
  isSingleVod,
  isLinkAsAsset,
  isFutureProgram,
  collectionType,
  isChannelAsset,
  isAsPlayAsset,
}: Props) {
  const menuStore = useMenuStore();
  const pageId = usePageId();
  const referenceId = useSearchParam(FROM_REFERENCE_ID) || pageId;
  const mediaTypeParam = convertRhinoAssetTypeToMediaType(data.type);

  let link;

  if (isLinkAsAsset) {
    link = resolveLinkAsAsset({ data: data as CollectionLinkAsset, menuStore, referenceId });
  } else {
    link = resolveLinkProgramAsset({
      data: data as AssetWithSeriesId,
      collectionType,
      mediaTypeParam,
      isFutureProgram,
      isSingleVod,
      isAsPlayAsset,
      isChannelAsset,
    });
  }

  return { link, mediaTypeParam };
}

export { useCardLink };
