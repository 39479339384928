import React from 'react';
import cn from 'classnames';

import Buttons from '../Buttons/Buttons';
import DescriptionView from '../DescriptionView/DescriptionView';
import PlayerView from '../PlayerView/PlayerView';

import styles from './VideoContent.module.scss';

const VideoContent = ({ isContentGeoBlocked }: { isContentGeoBlocked: boolean }) => (
  <>
    <PlayerView />
    <div className={cn(styles.content, { [styles.locked]: isContentGeoBlocked })}>
      <DescriptionView />
      <Buttons />
    </div>
  </>
);

export default VideoContent;
