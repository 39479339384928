import React from 'react';
import cn from 'classnames';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import DynamicRailViewer from 'components/widgets/RailV2/components/DynamicRailViewer/DynamicRailViewer';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import Title from 'components/widgets/RailV2/components/Title/Title';
import { useApplyStyles } from 'components/widgets/RailV2/hooks/useApplyStyles/useApplyStyles';
import { useWidgetContext } from 'context/widgetProvider/widgetProvider';

import RecommendationCardContainer from '../RecommendationCardContainer/RecommendationCardContainer';

import styles from './RecommendationRailContainer.module.scss';

const RecommendationRailContainer = () => {
  // using version v2 of the rail provider
  const { wrapperRef, refElementInner, componentHeight } = useRailContext();
  const {
    settings,
    data: { hasNextPage, totalCount, items },
    data,
  } = useWidgetContext<CollectionAsset>();

  const handleRef = useApplyStyles(settings);

  return (
    <div ref={handleRef} className={cn(styles.container)}>
      <div ref={refElementInner}>
        <Title
          hasNextPage={!!hasNextPage}
          totalCount={totalCount}
          itemsLength={items.length}
          forwardHeaderRef={handleRef}
          railSettings={settings}
        />
        <div
          ref={wrapperRef}
          style={{
            height: componentHeight,
          }}
        >
          <DynamicRailViewer railData={data}>
            {({ imageHeight, itemWidth, item }) => (
              <RecommendationCardContainer imageHeight={imageHeight} itemWidth={itemWidth} item={item} />
            )}
          </DynamicRailViewer>
        </div>
      </div>
    </div>
  );
};

export default RecommendationRailContainer;
