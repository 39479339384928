import React, { useRef } from 'react';
import cn from 'classnames';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { DisplayType } from '@kaltura-ott/tvpil/interfaces';
import { LinkAsAsset } from '@kaltura-ott/tvpil-shared';

import { IMAGE_PARAM } from 'consts';
import { usePerformanceEventsContext } from 'context/performanceEvents/performanceEvents';
import { PerformanceEvents } from 'interface';

import { IN_APP_LINK_HOME } from '../../constans';
import resolveMenuLinks from '../../resolveMenuLinks';
import LinkView from '../LinkView/LinkView';
import Logo from '../Logo/Logo';
import MenuItemsCombiner from '../MenuItemsCombiner/MenuItemsCombiner';

import styles from '../../Menu.module.scss';

type Props = {
  menuItem: LinkAsAsset;
  subtype?: UIDAPresentationTypeEnum;
};

function MenuItem({ menuItem: linkAsAsset, subtype }: Props) {
  const ref = useRef<HTMLLIElement | null>(null);
  const isActiveLink = useRef<string | undefined>(undefined);
  const performanceEventsContext = usePerformanceEventsContext();
  const menuItem = linkAsAsset.data.linkItem!;
  const path = resolveMenuLinks(menuItem);
  const isShowLogo = menuItem.image && menuItem.displayType === DisplayType.imageOnly;
  const isDisplayTypeOrText =
    menuItem.displayType === DisplayType.textOnly || menuItem.displayType === DisplayType.text;
  const isBothType = menuItem.image && menuItem.displayType === DisplayType.both;

  function performanceEventHandler() {
    if (menuItem.inAppLink?.toLowerCase() === IN_APP_LINK_HOME) {
      performanceEventsContext.setStartTime(PerformanceEvents.homePageReady);
    }

    if (subtype === UIDAPresentationTypeEnum.epg) {
      performanceEventsContext.setStartTime(PerformanceEvents.epgReady);
    }
  }

  function onClick() {
    performanceEventHandler();
  }

  const handleCheckIsLinkActive = () => {
    if (ref.current) {
      ref.current.setAttribute('active', `${path === isActiveLink.current}`);
    }
  };

  return (
    <>
      <MenuItemsCombiner isActive={isActiveLink} callback={handleCheckIsLinkActive} />
      <li ref={ref} role='menuitem' key={`${menuItem.name}-${menuItem.inAppLink}`} onClick={onClick}>
        {isShowLogo && <Logo className={cn(styles.logo)} path={path} image={`${menuItem.image}${IMAGE_PARAM}`} />}
        {isDisplayTypeOrText && (
          <LinkView path={path} menuItem={linkAsAsset}>
            {menuItem.name}
          </LinkView>
        )}
        {isBothType && (
          <LinkView path={path} menuItem={linkAsAsset}>
            <div className={styles.name}>{menuItem.name}</div>
            <img className={styles.logo} alt={menuItem.name} src={`${menuItem.image}${IMAGE_PARAM}`} />
          </LinkView>
        )}
      </li>
    </>
  );
}

export default React.memo(MenuItem);
