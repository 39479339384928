import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useCollectionGridSettings, useProviderLogo } from '@kaltura-ott/tvpil-shared';

import { COMPONENT_NAME } from 'components/pages/Grids/constants';
import { useGridMatchPath } from 'components/pages/Grids/hooks/useGridMatchPath/useGridMatchPath';
import { useGridResetAdultPin } from 'components/pages/Grids/hooks/useGridResetAdultPin/useGridResetAdultPin';
import { GridSettingsFetchDataTypes } from 'components/pages/Grids/types';
import { WIDGET_TYPE } from 'consts';
import routes from 'routesPaths';
import { applyStyleHexToHSL, applyStyles } from 'utils';

function useCollectionGrid() {
  const { type, id } = useGridMatchPath(routes.collectionGrid.path);
  const [, setForceUpdate] = useState(false); // For force updating of component
  const gridSettings = useCollectionGridSettings({ gridReferenceId: type, railReferenceId: id });
  const providerLogo = useProviderLogo(gridSettings.settings?.providerName);
  const wrapperRef = useRef(null);
  const isNoContent = !gridSettings.settings && !gridSettings.isLoading;
  const gridType = WIDGET_TYPE.COLLECTION;
  const intl = useIntl();
  const notValidPageTitle = intl.formatMessage({
    id: `${COMPONENT_NAME}.notValidPage`,
    defaultMessage: 'Not a valid page',
  });

  useEffect(() => {
    async function initGridPage() {
      const node = wrapperRef?.current;

      if (node && gridSettings.settings?.branding!) {
        applyStyles(node, gridSettings.settings.branding, false, true);
        applyStyleHexToHSL(node, gridSettings.settings.branding.backgroundColor);
      }
    }

    if (gridSettings && !gridSettings.error) {
      initGridPage();
    }
  }, [type, id, gridSettings.isLoading]);

  useGridResetAdultPin(gridSettings.settings);

  return ({
    gridSettings: gridSettings.settings,
    isLoading: gridSettings.isLoading,
    wrapperRef,
    providerLogo,
    setForceUpdate,
    isNoContent,
    notValidPageTitle,
    gridType,
  } as unknown) as GridSettingsFetchDataTypes;
}

export { useCollectionGrid };
