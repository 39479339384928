import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useToastMessageContext } from 'context/toast/toast';

import { Action } from '../enum';

export const useSuccessNotice = () => {
  const intl = useIntl();
  const toastMessageContext = useToastMessageContext();

  return useCallback(
    (action: Extract<Action, 'ReorderLineupSuccess' | 'RevertLineupSuccess'>) => {
      const id = `Settings.PersonalLineup.${action}`;

      const title = intl.formatMessage({
        id,
        defaultMessage: 'Channels were updated successfully',
      });

      toastMessageContext.showToast({
        success: true,
        title,
      });
    },
    [intl, toastMessageContext],
  );
};
