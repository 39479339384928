import React from 'react';
import cn from 'classnames';

import { CardSkeleton } from 'components/widgets/CardView/CardSkeleton/CardSkeleton';
import { getImageSizeParams } from 'utils/getImageSizeParams';

import { RailSettings } from '../RailProviderV2/types';

import styles from './RailSkeleton.module.scss';

interface Props {
  handleRef?: (node: HTMLDivElement) => void;
  settings: RailSettings;
  numberOfCells: number;
  shouldShowHeader?: boolean;
  className?: string;
}

function RailSkeleton({ handleRef, settings, numberOfCells, shouldShowHeader = false, className }: Props) {
  const { imageHeight, imageWidth } = getImageSizeParams(settings); // should be removed after fallback params becomes required

  return (
    <div ref={handleRef} className={cn(styles.railSkeleton, className)}>
      {shouldShowHeader && (
        <header
          style={{
            width: imageWidth,
          }}
        />
      )}
      <div>
        {new Array(numberOfCells).fill(0).map((_, i) => {
          /* eslint-disable react/no-array-index-key */
          return <CardSkeleton key={i} height={imageHeight ?? 0} width={imageWidth} />;
        })}
      </div>
    </div>
  );
}

export default RailSkeleton;
