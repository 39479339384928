import { useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { ParentalPinType } from '@kaltura-ott/tvpil-shared';

import { useGridMatchPath } from 'components/pages/Grids/hooks/useGridMatchPath/useGridMatchPath';
import { LAST_URL } from 'consts';
import { useModalContext } from 'context/modal/modal';
import routes from 'routesPaths';
import { globalStorage } from 'services/globalStorage';

import { useAppStore } from '../common/useAppStore';
import { useDetectLocation } from '../common/useDetectLocation';
import { useIsUrlToFullPlayer } from '../common/useIsUrlToFullPlayer';
import { useParentalPinStore } from '../common/useParentalPinStore';

import { useAppFindPageStore } from './useAppFindPageStore';

export function useAppHistoryListener() {
  const {
    appStore: { isReady: appStoreIsReady, rootStore },
  } = useAppStore();
  const modalContext = useModalContext();
  const parentalPinStore = useParentalPinStore();
  const location = useLocation();
  const { id } = useGridMatchPath(routes.grid.path);
  const { isHomePage, isEpg, isGrid, isPlayerLocation, isDetails } = useDetectLocation();
  const isUrlToFullPlayer = useIsUrlToFullPlayer(location.pathname);
  const { findPageStore, findSettingsSubStore } = useAppFindPageStore();
  const prevUrl = useRef('');

  useLayoutEffect(() => {
    if (location.pathname !== prevUrl.current) {
      sessionStorage.setItem(LAST_URL, prevUrl.current);
      prevUrl.current = location.pathname;
    }

    globalStorage.setIsUrlToFullPlayer(isUrlToFullPlayer);

    const currentPage = findPageStore(location.pathname);
    const substore = findSettingsSubStore(location.pathname);
    const isRestricted =
      !!(currentPage && parentalPinStore.isBlockByAdult(currentPage)) ||
      !!(substore && parentalPinStore.isBlockByParentRating(substore));

    if (!isRestricted && document.getElementById('mainModal')) {
      modalContext.closeModal();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!appStoreIsReady) {
      return;
    }

    const prevLocation = sessionStorage.getItem(LAST_URL);
    const isLeavingPlayerPage = prevLocation && isPlayerLocation(prevLocation) && !isPlayerLocation(location.pathname);
    const isLeavingDetailsPage = prevLocation && isDetails(prevLocation) && !isDetails(location.pathname);

    if (isLeavingPlayerPage) {
      parentalPinStore.clearParentalPin(ParentalPinType.parental);
    }

    if (isLeavingDetailsPage && !isEpg) {
      const pageStore = findPageStore(location.pathname);
      const gridStore = rootStore.grid?.getByReferenceId(id);
      const isNotPageStorePinProtected = !!pageStore && !pageStore?.parentalPinProtected;
      const isNotPinProtectedHomePage = isHomePage && !rootStore?.parentalPinProtected;
      const isNotPinProtectedGridPage = isGrid && !!gridStore && !gridStore?.parentalPinProtected;

      if (isNotPageStorePinProtected || isNotPinProtectedHomePage || isNotPinProtectedGridPage) {
        parentalPinStore.clearParentalPin(ParentalPinType.adult);
      }
    }
  }, [location.pathname, parentalPinStore, appStoreIsReady]);
}
