import { KalturaRecordingType } from '@kaltura-ott/ott-client-types/api/types';
import { isTopShelfProgramAsset, TopShelfAsset } from '@kaltura-ott/tvpil-shared';
import { RecordRepositoryFacade } from '@kaltura-ott/tvpil-shared/lib/facades/record-repository-facade';

interface Props {
  activeItem: TopShelfAsset | undefined;
  recordRepository: RecordRepositoryFacade;
}

function getRecordInfo({ activeItem, recordRepository }: Props) {
  let isSeriesRecording = false;
  let hasRecording = false;

  if (activeItem && activeItem.id) {
    const singleRecording = recordRepository.records.get(activeItem.id.toString());
    const seriesRecording =
      isTopShelfProgramAsset(activeItem) && activeItem.seriesId
        ? recordRepository.seriesRecords.get(activeItem.seriesId)
        : null;

    isSeriesRecording = Boolean(seriesRecording) || singleRecording?.data?.type! === KalturaRecordingType.series;
    hasRecording = Boolean(singleRecording) || Boolean(seriesRecording);
  }

  return { isSeriesRecording, hasRecording };
}

export { getRecordInfo };
