import { action, observable } from 'mobx';

interface PropsCardParams {
  imageWidth: number;
  imageHeight: number;
  isLandscape: boolean;
}

const defaultCardParams = {
  imageWidth: 0,
  imageHeight: 0,
  isLandscape: false,
};

class TopShelfStorage {
  @observable isUpdated = false;

  @observable isAnimating = false;

  @observable cardParams = defaultCardParams;

  @action
  setIsAnimating(value: boolean) {
    this.isAnimating = value;
  }

  @action
  setIsUpdated() {
    this.isUpdated = !this.isUpdated;
  }

  @action
  setCardParams(obj: PropsCardParams) {
    this.cardParams = obj;
  }

  @action
  clear() {
    this.isUpdated = false;
    this.isAnimating = false;
    this.cardParams = defaultCardParams;
  }
}

const topShelfStorage = new TopShelfStorage();

export { topShelfStorage };
