export const APPLICATION_SPINNER_EVENT_NAME = {
  APP: 'app',
  AUTH: 'auth',
  DEVICES: 'devices',
  DEVICE_LIMIT: 'devicelimit',
  FAVORITES: 'favorites',
  PERSONALIZED_LIST: 'personalized-list',
  GRID_PAGE: 'gridpage',
  PACKAGE_DETAILS: 'settings.packagedetails',
  PLAYER_RECORD: 'player-record',
  PLAYER_LOADER: 'player-loader',
  RAIL: 'RailHorisontal',
  RECORDS: 'records',
  SELECT_SERVICE: 'selectservice',
  SELFSERVICE: 'selfservice',
  SUBSCRIPTIONS: 'subscriptions',
  VIDEO_DETAILS: 'videodetails',
  VIDEO_PLAYOUT: 'videoplayout',
};

export const getAdditionalEventParam = (hash: string) => {
  if (
    [
      APPLICATION_SPINNER_EVENT_NAME.APP,
      APPLICATION_SPINNER_EVENT_NAME.PACKAGE_DETAILS,
      APPLICATION_SPINNER_EVENT_NAME.PLAYER_RECORD,
      APPLICATION_SPINNER_EVENT_NAME.PLAYER_LOADER,
      APPLICATION_SPINNER_EVENT_NAME.VIDEO_DETAILS,
      APPLICATION_SPINNER_EVENT_NAME.VIDEO_PLAYOUT,
      APPLICATION_SPINNER_EVENT_NAME.GRID_PAGE,
    ].includes(hash)
  ) {
    return `${hash}-${window.location.pathname}`;
  }

  return hash;
};
