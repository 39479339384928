import { useEffect } from 'react';

import { Channel, useChannels, useHeroSettings } from '@kaltura-ott/tvpil-shared';

import { RailProviderData, RailSettings } from 'components/widgets/RailV2/components/RailProviderV2/types';
import { DEFAULT_HEIGHT, LANDSCAPE_RATIO, WIDGET_TYPE } from 'consts';
import { globalStorage } from 'services/globalStorage';

import { useSetScrollPosition } from '../useSetScrollPosition/useSetScrollPosition';

/**
 * `useHeroRail` is a custom React hook used for retrieving and managing data necessary
 * for displaying a hero rail in a web application. This hook combines hero settings,
 * channel data, and rail configuration.
 *
 * @param referenceId - A unique identifier of the hero rail widget.
 * @param pageReferenceId - A unique identifier of the page containing the hero rail widget.
 *
 * @returns An object containing:
 * - `settings`: Configurations and settings for the hero component's UI, obtained from `useHeroSettings`.
 * - `railData`: An object structured as `RailProviderData<Channel>`, containing the channels,
 *               total count, and loading state.
 * - `railType`: Set to `WIDGET_TYPE.HERO`
 *               in this context, marking it as a hero-type rail component.
 */

function useHeroRail(referenceId: string, pageReferenceId: string) {
  const originalSettings = useHeroSettings({
    referenceId,
    imageSize: 'compact',
    defaultLandscapeImageHeight: DEFAULT_HEIGHT,
    defaultPortraitImageHeight: DEFAULT_HEIGHT,
    defaultImageRatio: LANDSCAPE_RATIO,
  });
  const data = useChannels({
    referenceId,
    pageReferenceId,
  });
  const items = data.channels || [];
  const railType = WIDGET_TYPE.HERO;

  useSetScrollPosition(!!data.channels?.length);

  const railData: RailProviderData<Channel> = {
    items,
    totalCount: items.length,
    isLoading: data.isLoading,
  };

  const settings: RailSettings = {
    branding: originalSettings?.branding,
    id: originalSettings?.id,
    imageHeight: originalSettings?.imageHeight,
    imageType: originalSettings?.imageType,
    imageWidth: originalSettings?.imageWidth,
    title: originalSettings?.title,
    channelIconType: originalSettings?.channelIconType,
    channelIconRatio: originalSettings?.channelIconRatio,
    shouldShowFavoriteIndicator: originalSettings?.shouldShowFavoriteIndicator,
    shouldShowCurrentProgram: originalSettings?.shouldShowCurrentProgram,
  };

  const isRailInvalid = !!railData.error || (!railData.items.length && !data.isLoading);

  useEffect(() => {
    if (items.length && items[0].id) {
      globalStorage.setFocusAssetV2(items[0]);
    }
  }, [items]);

  useEffect(() => {
    globalStorage.setDisableMaxiPlayer(isRailInvalid);
  }, [isRailInvalid]);

  return {
    railData,
    railType,
    isLoading: data.isLoading,
    settings: originalSettings ? settings : null,
    isRailInvalid,
  };
}

export { useHeroRail };
