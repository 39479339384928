import React from 'react';
import { Img } from 'react-image';

import { ContentMarker, PurchaseIcon, RatingV2 } from 'components/presenters/icons';

import CardContentRestricted from './components/CardContentRestricted/CardContentRestricted';
import { useCardContentCombiner } from './hooks/useCardContentCombiner/useCardContentCombiner';
import { CardCarouselProps } from './types';

import styles from './CardContent.module.scss';

function CardContent({ item, width, height, imageType, shouldPresentRating }: CardCarouselProps) {
  const {
    clickHandler,
    url,
    handleLinkRef,
    lockIcon,
    isAdult,
    contentMarker,
    isShowRating,
    isPurchasable,
    rating,
  } = useCardContentCombiner({
    item,
    width,
    height,
    imageType,
    shouldPresentRating,
  });

  return (
    <div ref={handleLinkRef} onClick={clickHandler} role='presentation'>
      <CardContentRestricted handleLinkRef={handleLinkRef} lockIcon={lockIcon} isAdult={isAdult} />
      <Img alt={item.title} style={{ width, height }} src={url} />
      {contentMarker && <ContentMarker className={styles.contentMarker} contentMarker={contentMarker} />}
      {isShowRating && <RatingV2 className={styles.rating} rating={rating!} />}
      {isPurchasable && <PurchaseIcon className={styles.cart} isBadge />}
    </div>
  );
}

export default React.memo(CardContent);
