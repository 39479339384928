import React, { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { SearchHistoryAsset } from '@kaltura-ott/tvpil-shared/lib/stores/search/search-store';

import { SEARCH_HISTORY_ICON_PATH } from 'consts';
import { useAppStore, useRootStore } from 'hooks';
import routesPaths from 'routesPaths';
import { getSearchHistoryIcon } from 'utils';

import styles from './SearchHistory.module.scss';

function SearchHistory() {
  const [searchValue, setSearchValue] = useState<string>('');
  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();
  const rootStore = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = rootStore!;
  const searchHistory = search?.historyList;
  const isReady = search?.railStore && search?.isReady;
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isReady && searchValue) {
      navigate(routesPaths.search.link(searchValue), { state: { goBackUrl: location.pathname } });
    }
  }, [isReady, searchValue]);

  if (!searchHistory?.length) {
    return null;
  }

  const handleOnMouseEnter = (value: string) => {
    return () => {
      const inputElement = document.getElementById('searchInput') as HTMLInputElement;
      inputElement.placeholder = value;
      inputElement.blur();
    };
  };

  const handleOnMouseLeave = () => {
    const inputElement = document.getElementById('searchInput') as HTMLInputElement;
    inputElement.placeholder = '';
    inputElement.focus();
  };

  const handleClickHistory = (title: string) => {
    return async (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      await search?.search(title, true);
      setSearchValue(title);
      handleOnMouseLeave();
    };
  };

  const renderHistoryList = () => {
    return searchHistory?.map((item: SearchHistoryAsset) => (
      <div
        key={item.id}
        className={styles.searchItem}
        role='presentation'
        onMouseEnter={handleOnMouseEnter(item.title)}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleClickHistory(item.title)}
      >
        <SVG
          className={styles.searchHistoryIcon}
          src={`${staticAssetsUrl}${getSearchHistoryIcon(SEARCH_HISTORY_ICON_PATH)}`}
        />
        <span>{item.title}</span>
      </div>
    ));
  };

  return (
    <div ref={ref} className={styles.searchHistory}>
      {renderHistoryList()}
    </div>
  );
}

export default observer(SearchHistory);
