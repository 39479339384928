/* eslint-disable no-continue */
import { Channel } from '@kaltura-ott/tvpil-shared';

type ShiftChannelNumbersBackwardArgs = { startIndex: number; destinationIndex: number; channels: Array<Channel> };

export function shiftChannelNumbersBackward({
  startIndex,
  destinationIndex,
  channels,
}: ShiftChannelNumbersBackwardArgs): Array<Channel> {
  const subArrayBefore = channels.slice(0, destinationIndex);
  const subArrayAfter = channels.slice(startIndex + 1);
  const subArrayMiddle = channels.slice(destinationIndex, startIndex + 1);

  const targetedChannel = channels[destinationIndex];

  let isChannelGapFilled = false;
  let foundNextToFill = false;

  let freeChannelNumber;

  const shiftedSubarray = [];

  for (let index = 0; index < subArrayMiddle.length; index += 1) {
    const channel = subArrayMiddle[index];

    // Skip channels if the gap is already filled or channel is adult
    if ((isChannelGapFilled && !foundNextToFill) || !channel.virtualNumber || channel.isAdult) {
      shiftedSubarray.push(channel);
      continue;
    }

    // Find the next free channel number if next channel is adult
    if (subArrayMiddle[index + 1]?.isAdult) {
      let i = index + 1;

      while (i < subArrayMiddle.length && !freeChannelNumber) {
        const nextChannel = subArrayMiddle[i + 1];
        const currentChannel = subArrayMiddle[i];

        const nextChannelNumber = nextChannel?.virtualNumber;
        const currentChannelNumber = currentChannel.virtualNumber;

        if (!nextChannel?.isAdult) {
          freeChannelNumber = nextChannelNumber;
          foundNextToFill = true;
          break;
        }

        // Check for gaps in channel numbers
        if (nextChannelNumber && nextChannelNumber - currentChannelNumber! > 1) {
          freeChannelNumber = currentChannelNumber! + 1;
          break;
        }

        i += 1;
      }

      if (!freeChannelNumber) {
        freeChannelNumber = targetedChannel.virtualNumber;
      }
    }

    const nextChannelNumber = subArrayMiddle[index + 1]?.virtualNumber;

    // Check for gaps in the channel numbers
    if (nextChannelNumber && nextChannelNumber - channel.virtualNumber > 1) {
      freeChannelNumber = channel.virtualNumber + 1;
    }

    // Assign the found free channel number
    if (freeChannelNumber) {
      shiftedSubarray.push({ ...channel, virtualNumber: freeChannelNumber });
      isChannelGapFilled = true;
      freeChannelNumber = undefined;
      continue;
    }

    // Update the targeted channel's number
    if (targetedChannel.id === channel.id) {
      shiftedSubarray.push({ ...channel, virtualNumber: subArrayMiddle[1].virtualNumber });
      continue;
    }

    // Increment channel number by 1 if no gaps found
    shiftedSubarray.push({ ...channel, virtualNumber: channel.virtualNumber! + 1 });
  }

  const sortedMiddleSubArray = shiftedSubarray.sort((a, b) => a.virtualNumber! - b.virtualNumber!);

  const composedChannels = [...subArrayBefore, ...sortedMiddleSubArray, ...subArrayAfter];

  return composedChannels;
}
