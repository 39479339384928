import React from 'react';

import { CollectionAsset } from '@kaltura-ott/tvpil-shared';

import { RAIL_PAGE_SIZE, WIDGET_TYPE } from 'consts';
import WidgetProvider from 'context/widgetProvider/widgetProvider';

import RailProviderV2 from '../components/RailProviderV2/RailProviderV2';
import RailSkeleton from '../components/RailSkeleton/RailSkeleton';

import CollectionRailContainer from './components/CollectionRailContainer/CollectionRailContainer';
import { useCollectionRail } from './hooks/useCollectionRail/useCollectionRail';

interface Props {
  referenceId: string;
}

function CollectionRail({ referenceId }: Props) {
  const { data, settings, isRailEmpty, shouldPreventDisplay, handleSkeletonRef } = useCollectionRail(referenceId);
  const type = WIDGET_TYPE.COLLECTION;

  if (!settings || isRailEmpty) {
    return null;
  }

  if (shouldPreventDisplay || !data.items.length) {
    return (
      <RailSkeleton handleRef={handleSkeletonRef} settings={settings} numberOfCells={RAIL_PAGE_SIZE} shouldShowHeader />
    );
  }

  return (
    <WidgetProvider<CollectionAsset> type={type} settings={settings} data={data}>
      <RailProviderV2 referenceId={referenceId}>
        <CollectionRailContainer />
      </RailProviderV2>
    </WidgetProvider>
  );
}

export default CollectionRail;
