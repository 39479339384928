import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import { useModalContext } from 'context/modal/modal';
import { ActionButtonTypesEnum } from 'interface';

interface ResetConfirmationModalProps {
  onAccept: () => void;
  onCancel?: () => void;
  onDecline?: () => void;
}

export function useCancelConfirmationModal() {
  const intl = useIntl();
  const modalContext = useModalContext();

  const onGoBackButtonClick = useCallback(
    (callback?: () => void) => {
      modalContext.closeModal();

      if (callback) {
        callback();
      }
    },
    [modalContext],
  );

  const openModal = useCallback(
    ({ onAccept, onCancel, onDecline }: ResetConfirmationModalProps) => {
      modalContext.openModal(
        <DefaultModal
          onDecline={onDecline}
          buttonsArray={[
            {
              onAccept: () => onGoBackButtonClick(onCancel),
              acceptButtonText: intl.formatMessage({
                defaultMessage: 'No, go back',
                id: 'Settings.PersonalLineup.CancelConfirmationModal.goBack',
              }),
              acceptButtonType: ActionButtonTypesEnum.secondary,
            },
            {
              onAccept,
              acceptButtonText: intl.formatMessage({
                defaultMessage: 'Yes, Remove',
                id: 'Settings.PersonalLineup.CancelConfirmationModal.reset',
              }),
              acceptButtonType: ActionButtonTypesEnum.primary,
            },
          ]}
          title={intl.formatMessage({
            defaultMessage: 'Are you sure you want to remove all change(s)?',
            id: 'Settings.PersonalLineup.CancelConfirmationModal.title',
          })}
        >
          <p>
            <FormattedMessage
              defaultMessage='Your current changes will be lost'
              id='Settings.PersonalLineup.CancelConfirmationModal.description'
            />
          </p>
        </DefaultModal>,
      );
    },
    [intl, modalContext, onGoBackButtonClick],
  );

  return { openModal, closeModal: modalContext.closeModal };
}
