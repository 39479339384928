import { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { observer } from 'mobx-react';

import { LOCK_ICON } from 'consts';
import { useAppStore, useParentalPinStore } from 'hooks';
import { getLockIcon } from 'utils';

interface Props {
  handleLinkRef: React.MutableRefObject<HTMLAnchorElement | null>;
  isAdult: boolean;
}

function CardContentRestrictedHook({ handleLinkRef, isAdult }: Props) {
  const parentalPinStore = useParentalPinStore();
  const isBlockByAdult = !parentalPinStore.isAdultPinEntered;
  const isShowAsContentRestricted = !!(isAdult && isBlockByAdult);

  const {
    appStore: { staticAssetsUrl },
  } = useAppStore();

  const lockIconUrl = `${staticAssetsUrl}${getLockIcon(LOCK_ICON)}`;

  useEffect(() => {
    if (isShowAsContentRestricted) {
      handleLinkRef?.current?.setAttribute('ui-type', 'restrictedContent');
    } else {
      handleLinkRef?.current?.removeAttribute('ui-type');
    }
  }, [isShowAsContentRestricted, handleLinkRef]);

  return <> {isShowAsContentRestricted && <SVG data-target='lock' src={lockIconUrl!} />}</>;
}

export default observer(CardContentRestrictedHook);
