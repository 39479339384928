import { CollectionAsset, getAssetImageUrl } from '@kaltura-ott/tvpil-shared';

import { useCardIconsPaths } from 'components/widgets/Card/hooks';

const SCALING_IMAGE = 2;

interface RecommendationCardImagesProps {
  item: CollectionAsset;
  imageType: number | undefined;
  imageHeight: number;
  imageWidth: number;
}

export function useCollectionCardImages({ item, imageWidth, imageType, imageHeight }: RecommendationCardImagesProps) {
  // getting background image
  const backgroundImageUrl = getAssetImageUrl(item.images, {
    type: imageType,
    width: imageWidth * SCALING_IMAGE,
    height: imageHeight * SCALING_IMAGE,
  });
  const { defaultImage } = useCardIconsPaths({ height: imageHeight, width: imageWidth });

  return {
    height: imageHeight,
    backgroundImageUrl,
    width: imageWidth,
    defaultImageUrl: defaultImage,
  };
}
