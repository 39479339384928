import React from 'react';

import { MIN_NUMBER_OF_ITEMS, SKELETON_HEIGHT, SKELETON_TEXT_LINE_NUMBER, SKELETON_WIDTH } from '../../constants';

import style from './CarouselSkeleton.module.scss';

function CarouselSkeleton() {
  return (
    <div className={style.wrapper}>
      <div className={style.scaffoldList}>
        {Array.from({ length: MIN_NUMBER_OF_ITEMS }, (_, index) => (
          <div
            style={{ width: SKELETON_WIDTH, height: SKELETON_HEIGHT }}
            key={`carousel-skeleton-${index}`}
            className={style.scaffoldItem}
          />
        ))}
      </div>
      <span className={style.progressBar} />
      <div className={style.textWrapper}>
        <span className={style.tags} />
        <span className={style.title} />
        <span className={style.description} />
        {Array.from({ length: SKELETON_TEXT_LINE_NUMBER }, (_, index) => (
          <span key={`carousel-skeleton-text-${index}`} className={style.text} />
        ))}
      </div>
    </div>
  );
}

export default CarouselSkeleton;
