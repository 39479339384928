import { useEffect, useRef } from 'react';

import { globalStorage } from 'services/globalStorage';

interface Props {
  startDate?: number;
  channelId?: number;
  index: number;
}

function useUpdateMaxiPlayer({ startDate = 0, channelId = 0, index }: Props) {
  const hasComponentAlreadyMounted = useRef(false);

  useEffect(() => {
    if (index === 0) {
      if (startDate) {
        if (hasComponentAlreadyMounted.current) {
          globalStorage.setFocusAssetV2({ id: channelId });
        }

        hasComponentAlreadyMounted.current = true;
      }
    }
  }, [startDate]);
}

export { useUpdateMaxiPlayer };
