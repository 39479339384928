import React, { useEffect, useState } from 'react';
import { Masonry } from 'masonic';

import {
  Channel as RhinoChannel,
  getChannelNumber,
  useChannels,
  usePersonalLineup,
  usePersonalLineupSettings,
} from '@kaltura-ott/tvpil-shared';

import { useBeforeUnloadWarning } from './hooks/useBeforeUnloadWarning';
import { useButtonHandlerWithModal } from './hooks/useButtonHandlerWithModal';
import { useErrorNotice } from './hooks/useErrorNotice';
import { useHandleLineupChange } from './hooks/useHandleLineupChange';
import { usePersonalLineupActions } from './hooks/usePersonalLineupActions';
import { useUnsavedChangesWarning } from './hooks/useUnsavedChangesWarning';
import {
  ActionButtons,
  Channel,
  DefaultChannelOrderMessage,
  DragAndDropChannelContainer,
  DragAndDropGridContainer,
  PersonalLineupContentWrapper,
  PersonalLineupSkeleton,
} from './components';
import { COLUMN_GUTTER, FOUR_COLUMNS_RESOLUTION, MAX_GRID_WIDTH_FOR_DEFAULT_CARD_SIZE, ROW_GUTTER } from './constants';
import { Action } from './enum';
import { addVirtualNumbers } from './helper';

import styles from './PersonalChannelLineup.module.scss';

type PersonalChannelLineupProps = { referenceId: string };
type Lineup = { channelId: number; channelNumber: number };

function PersonalChannelLineup({ referenceId }: PersonalChannelLineupProps) {
  const [channels, setChannels] = useState<null | Array<RhinoChannel>>(null);
  const [lineup, updateLineup] = useState<null | Array<Lineup>>(null);
  const [isUniqueModification, setIsUniqueModification] = useState<Boolean>(false);
  const errorNotice = useErrorNotice();

  const settings = usePersonalLineupSettings({ referenceId });
  const { personalLineup, isLoading: isPersonalLineupLoading, error: personalLineupError } = usePersonalLineup();
  const { channels: originalChannels, isLoading: isChannelsLoading, error: channelsError } = useChannels({
    referenceId,
  });

  const hasBeenModified = Boolean(lineup && isUniqueModification);
  const hasBeenUpdatedByUser = Boolean(personalLineup);

  const isLoading = isChannelsLoading || isPersonalLineupLoading;
  const hasError = channelsError || personalLineupError;

  const { saveNewLineup, revertToDefaultLineup, isSaving, cancelLineupOrder } = usePersonalLineupActions<Lineup>({
    updateLineup,
    setChannels,
    originalChannels,
    lineup,
  });

  useEffect(() => {
    if (originalChannels && !channels) {
      const channelsWithVirtualChannelNumber = addVirtualNumbers(originalChannels);

      setChannels(channelsWithVirtualChannelNumber);
    }
  }, [originalChannels, channels]);

  // hooks for handling routes changing
  useUnsavedChangesWarning({
    onAccept: cancelLineupOrder,
    shouldWarn: hasBeenModified,
    shouldBlockRouter: isSaving,
  });

  useBeforeUnloadWarning({ shouldWarn: hasBeenModified });

  const {
    handlePersonalLineupCancel,
    handlePersonalLineupSave,
    handlePersonalLineupRevert,
  } = useButtonHandlerWithModal({
    cancelLineupOrder,
    saveNewLineup,
    revertToDefaultLineup,
  });

  const handleLineupChange = useHandleLineupChange({
    originalChannels,
    setChannels,
    updateLineup,
    setIsUniqueModification,
  });

  if (hasError) {
    errorNotice(Action.LoadPersonalChannelLineupFailure);
  }

  if (!settings) return null;

  if (isLoading || hasError || !channels) {
    return <PersonalLineupSkeleton />;
  }

  return (
    <PersonalLineupContentWrapper>
      {hasBeenModified || hasBeenUpdatedByUser ? (
        <ActionButtons
          isSavingUserLineup={isSaving}
          shouldShowCancelButton={hasBeenModified}
          shouldShowSaveButton={hasBeenModified}
          shouldShowRevertButton={hasBeenUpdatedByUser}
          handleClickCancelButton={handlePersonalLineupCancel}
          handleClickSaveButton={handlePersonalLineupSave}
          handleClickRevertButton={handlePersonalLineupRevert}
        />
      ) : (
        <DefaultChannelOrderMessage />
      )}

      <DragAndDropGridContainer originalChannels={channels} setLineupChange={handleLineupChange}>
        <Masonry
          style={{
            maxWidth: window.innerWidth < FOUR_COLUMNS_RESOLUTION ? MAX_GRID_WIDTH_FOR_DEFAULT_CARD_SIZE : '100%',
          }}
          role='grid'
          className={styles.grid}
          items={channels}
          itemKey={(channel) => channel.id}
          columnGutter={COLUMN_GUTTER}
          rowGutter={ROW_GUTTER}
          overscanBy={10}
          render={({ data: channel }) => (
            <DragAndDropChannelContainer channel={channel}>
              {(state) => (
                <Channel
                  channelNumber={getChannelNumber(channel)}
                  images={channel.images}
                  title={channel.title}
                  settings={settings}
                  state={state}
                  isLocked={channel.isAdult}
                />
              )}
            </DragAndDropChannelContainer>
          )}
        />
      </DragAndDropGridContainer>
    </PersonalLineupContentWrapper>
  );
}

export default PersonalChannelLineup;
