import {
  CollectionAsset,
  EntityRating,
  isCollectionChannelAsset,
  isCollectionLinkAsset,
  useRatingProviders,
} from '@kaltura-ott/tvpil-shared';

interface Props {
  item: CollectionAsset;
  shouldShowRating: boolean | undefined;
}

export const useCollectionCardRating = ({ item, shouldShowRating }: Props) => {
  const { ratingProviders } = useRatingProviders();

  const ratingName = !isCollectionLinkAsset(item) && !isCollectionChannelAsset(item) && item.rating?.name;
  const assetRating = ratingName
    ? ({ ...ratingProviders.get(ratingName), value: item.rating?.value } as EntityRating)
    : undefined;
  const isDisplayRating = !!(shouldShowRating && assetRating);

  return { isDisplayRating, assetRating };
};
