import React, { useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { ViewableEntitiesType } from '@kaltura-ott/tvpil-shared';

import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import { PurchaseIcon } from 'components/presenters/icons';
import Loader from 'components/presenters/Loader/Loader';
import ActionButton from 'components/widgets/ActionButton/ActionButton';
import Options from 'components/widgets/common/Select/components/Options/Options';
import { useErrorScreenContext } from 'context/error/error';
import { useModalContext } from 'context/modal/modal';
import { useOnClickOutside } from 'hooks';
import { userActionEvents } from 'utils';

import PinCodeModalDetails from '../../../PinCodeModalDetails/PinCodeModalDetails';

import { useResolvedRent } from './hooks/useResolvedRent/useResolvedRent';
import { getOptions } from './utils';

import styles from './RentButton.module.scss';

export interface RentButtonProps {
  asset: ViewableEntitiesType;
  className?: string;
  seasonEpisodeTitle?: string;
}

export function RentButton({ asset, className, seasonEpisodeTitle }: RentButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isPurchaseRentLoading, setIsPurchaseRentLoading] = useState(false);
  const { resolveRent } = useResolvedRent();
  const modalContext = useModalContext();
  const ref = useRef(null);
  const errorScreenContext = useErrorScreenContext();
  const { isSingleRent, isPurchasePending, rents } = detailsStoreDataResolver(asset!);
  const options = useMemo(() => getOptions(rents, resolveRent), [rents]); // eslint-disable-line

  useOnClickOutside(ref, () => setIsOpen(false));

  if (!rents || !rents.length) {
    return null;
  }

  const switchPurchaseRentLoading = (flag: boolean) => {
    setIsPurchaseRentLoading(flag);
  };

  async function openPinCodeModal(optionIndex: number = 0) {
    const { price, viewLifeCycle } = resolveRent(rents[optionIndex]);

    modalContext.openModal(
      <PinCodeModalDetails
        asset={asset}
        switchPurchaseRentLoading={switchPurchaseRentLoading}
        duration={viewLifeCycle}
        price={price}
        optionIndex={optionIndex}
        context={UIDAPresentationTypeEnum.tvod}
        errorScreenContext={errorScreenContext}
      />,
    );
  }

  const handleClick = () => {
    if (isSingleRent) {
      userActionEvents(UserActionType.buyTry, asset?.data?.id);
      openPinCodeModal();

      return;
    }

    setIsOpen(!isOpen);
  };

  const handleOptionClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const optionIndex = Number(e.currentTarget.dataset.value);

    openPinCodeModal(optionIndex);
    userActionEvents(UserActionType.buyTry, asset?.data?.id);
    setIsOpen(false);
  };

  const firstOffer = resolveRent(rents[0]);

  return (
    <>
      {isPurchaseRentLoading ? (
        <div className={styles.loaderWraper}>
          <Loader />
        </div>
      ) : null}
      <div className={styles.wrapper}>
        <div className={styles.description}>
          {isSingleRent ? (
            firstOffer.duration
          ) : (
            <FormattedMessage
              defaultMessage='This title is a part of multiple offers'
              id={`${COMPONENT_NAME}.VideoContent.RentButton.labelOffersMultiple`}
            />
          )}
        </div>
        <div ref={ref} className={styles.buttonWrapper}>
          <ActionButton
            className={className}
            disabled={isPurchasePending}
            icon={<PurchaseIcon />}
            isLarge
            onClick={handleClick}
          >
            <FormattedMessage defaultMessage='Rent' id={`${COMPONENT_NAME}.VideoContent.RentButton.text`} />{' '}
            {seasonEpisodeTitle} {isSingleRent ? firstOffer.price : ''}
          </ActionButton>

          {isOpen && <Options className={styles.rentOptions} onClick={handleOptionClick} options={options} />}
        </div>
      </div>
    </>
  );
}
