import { Channel } from '@kaltura-ott/tvpil-shared';

export function hasOrderChanged(originalChannels: Array<Channel>, updatedChannels: Array<Channel>): boolean {
  for (let i = 0; i < originalChannels.length; i += 1) {
    if (originalChannels[i].id !== updatedChannels[i].id) {
      return true;
    }
  }
  return false;
}
