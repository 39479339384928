import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared/lib/stores/category/playout/playout-store.service';

import { COMPONENT_NAME } from 'components/pages/VideoDetails/constants';
import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import LocalTime from 'components/presenters/LocalTime/LocalTime';
import { useVideoContext } from 'context/videoContext/videoContext';
import { useIsNpvr } from 'hooks';
import { DateFormatStyle, formatDate, playoutStoreDataResolver } from 'utils';

import styles from './AvailableForWatching.module.scss';

type Props = {
  displayLanguage: string;
  latinLanguage?: string;
};

export const AvailableForWatching = ({ latinLanguage, displayLanguage }: Props) => {
  const { program, isOverlay } = useVideoContext();
  const isNpvr = useIsNpvr();
  const { type } = playoutStoreDataResolver();
  const { catchupAvailableTime, recordAvailableTime } = detailsStoreDataResolver(program);
  const availableTime =
    (isNpvr || (type === PlayoutStoreV2Type.NPVR && isOverlay)) && recordAvailableTime
      ? recordAvailableTime
      : catchupAvailableTime;
  const date = formatDate(availableTime!, DateFormatStyle.WithoutWeekday, latinLanguage || displayLanguage);

  return (
    <p className={styles.catchupAvailableTime}>
      <FormattedMessage
        defaultMessage={`Available for watching until ${date}`}
        id={`${COMPONENT_NAME}.VideoContent.AvailableForWatching.text`}
        values={{
          date,
        }}
      />{' '}
      <LocalTime date={availableTime} />
    </p>
  );
};
