import React from 'react';

import { WatchedAsset } from '@kaltura-ott/tvpil-shared';

import { RAIL_PAGE_SIZE } from 'consts';
import { WidgetProvider } from 'context/widgetProvider/widgetProvider';

import RailProviderV2 from '../components/RailProviderV2/RailProviderV2';
import RailSkeleton from '../components/RailSkeleton/RailSkeleton';

import ContinueWatchingRailContainer from './components/ContinueWatchingRailContainer/ContinueWatchingRailContainer';
import { useContinueWatchingRail } from './hooks/useContinueWatchingRail/useContinueWatchingRail';

interface Props {
  referenceId: string;
}

function ContinueWatchingRail({ referenceId }: Props) {
  const { data, settings, type, isRailEmpty, shouldPreventDisplay, handleSkeletonRef } = useContinueWatchingRail(
    referenceId,
  );

  if (!settings || isRailEmpty) {
    return null;
  }

  if (shouldPreventDisplay || !data.items.length) {
    return (
      <RailSkeleton handleRef={handleSkeletonRef} settings={settings} numberOfCells={RAIL_PAGE_SIZE} shouldShowHeader />
    );
  }

  return (
    <WidgetProvider<WatchedAsset> type={type} settings={settings} data={data}>
      <RailProviderV2 referenceId={referenceId}>
        <ContinueWatchingRailContainer />
      </RailProviderV2>
    </WidgetProvider>
  );
}

export default ContinueWatchingRail;
