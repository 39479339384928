import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DefaultModal from 'components/widgets/Modal/DefaultModal/DefaultModal';
import { useModalContext } from 'context/modal/modal';
import { ActionButtonTypesEnum } from 'interface';

interface ResetConfirmationModalProps {
  onAccept: () => void;
}

export const useRevertConfirmationModal = () => {
  const intl = useIntl();
  const modalContext = useModalContext();

  const openModal = useCallback(({ onAccept }: ResetConfirmationModalProps) => {
    modalContext.openModal(
      <DefaultModal
        buttonsArray={[
          {
            onAccept: () => modalContext.closeModal(),
            acceptButtonText: intl.formatMessage({
              defaultMessage: 'No, go back',
              id: 'Settings.PersonalLineup.RevertConfirmationModal.goBack',
            }),
            acceptButtonType: ActionButtonTypesEnum.secondary,
          },
          {
            onAccept,
            acceptButtonText: intl.formatMessage({
              defaultMessage: 'Yes, Revert!',
              id: 'Settings.PersonalLineup.RevertConfirmationModal.reset',
            }),
            acceptButtonType: ActionButtonTypesEnum.primary,
          },
        ]}
        title={intl.formatMessage({
          defaultMessage: 'Revert to original channel order by operator?',
          id: 'Settings.PersonalLineup.RevertConfirmationModal.title',
        })}
      >
        <p>
          <FormattedMessage
            defaultMessage='Your current channel list will be lost'
            id='Settings.PersonalLineup.RevertConfirmationModal.description'
          />
        </p>
      </DefaultModal>,
    );
  }, []);

  return openModal;
};
