import React from 'react';

import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';
import { getImageByHeightV2 } from '@kaltura-ott/tvpil-shared';

import LocalTime from 'components/presenters/LocalTime/LocalTime';

import { useVideoPlayoutContext } from '../../../VideoPlayoutProvider/VideoPlayoutProvider';
import NoProgramInfo from '../NoProgramInfo/NoProgramInfo';

import styles from './LogTimeInfo.module.scss';

const LOGO_HEIGHT = 80;

const LogoTimeInfo = () => {
  const {
    asset,
    isImagesErrorCondition,
    isLinearWithoutCurrentProgram,
    isNoProgramCondition,
    isImagesCondition,
    programName,
    timeRange,
    logoImages,
    setChannelLogoError,
  } = useVideoPlayoutContext();

  return (
    <div className={styles.logoTimeInfo}>
      {isImagesCondition && (
        <img
          alt='logo'
          className={styles.logo}
          onError={() => setChannelLogoError(true)}
          src={getImageByHeightV2(LOGO_HEIGHT, AssetPresentationRatioEnum['16x9'], logoImages).url}
        />
      )}
      {isImagesErrorCondition && <p>{programName}</p>}
      <div className={styles.time}>
        <LocalTime date={timeRange.startTime} withRangeDelimiter />
        <LocalTime date={timeRange.endTime} />
      </div>
      {isNoProgramCondition && <NoProgramInfo />}
      {isLinearWithoutCurrentProgram && <div className={styles.title}>{asset.data.title}</div>}
    </div>
  );
};

export default React.memo(LogoTimeInfo);
