import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { Channel, getProgramTitle, useChannelLiveProgram } from '@kaltura-ott/tvpil-shared';
import { getAssetEpisodeIndication } from '@kaltura-ott/tvpil-shared/lib/rhino/data/asset';

import { useMouseEnterHandle } from 'components/presenters/Card/hooks/useMouseEnterHandle/useMouseEnterHandle';
import { useMouseLeaveHandle } from 'components/presenters/Card/hooks/useMouseLeaveHandle/useMouseLeaveHandle';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { getSeasonEpisodeTitle } from 'components/widgets/RailV2/utils';
import { useWidgetContext } from 'context/widgetProvider/widgetProvider';
import routes from 'routesPaths';

import { useClickHandler } from '../useClickHandler/useClickHandler';
import { useHandleClickInfo } from '../useHandleClickInfo';
import { useHeroCardImages } from '../useHeroCardImages';
import { useHeroCardRef } from '../useHeroCardRef';
import { useUpdateMaxiPlayer } from '../useUpdateMaxiPLayer/useUpdateMaxiPlayer';

interface Props {
  channel: Channel;
  itemWidth: number;
  imageHeight: number;
  timestamp: number;
  index: number;
}

function useHeroCard({ channel, itemWidth, imageHeight, timestamp, index }: Props) {
  const link = routes.live.link(channel.id, 'program');
  // using version v2 of the rail provider
  const { onHover, nextButtons } = useRailContext();
  // using widget provider
  const { settings, imageWidth } = useWidgetContext();
  // getting live program for the channel
  const { liveProgram } = useChannelLiveProgram({ channelId: channel.id, timestamp });
  // adults
  const isAdult = !!(liveProgram?.isAdult ?? channel.isAdult);
  // channel has no program
  const isChannelHasNoProgram = liveProgram ? Boolean(liveProgram.isNotFound) : true;
  // refs
  const handleLinkRef = useRef(null);

  // temp solution
  useUpdateMaxiPlayer({ startDate: liveProgram?.startDate, channelId: liveProgram?.channelId, index });

  const { ref, handleRef } = useHeroCardRef(itemWidth, imageHeight);
  // handlers
  const onMouseEnter = useMouseEnterHandle({ ref, itemWidth, imageHeight, isGrid: false, onHover, nextButtons });
  const onMouseLeave = useMouseLeaveHandle(onHover);
  const clickHandler = useClickHandler({
    channelId: channel.id,
    isChannelHasNoProgram,
    data: liveProgram ?? channel,
    link,
    mediaTypeParam: 'program',
    imageHeight,
    isPurchasable: !channel.isEntitled,
    collectionType: 'hero',
    adult: isAdult,
    mosaicExternalId: liveProgram?.mosaicExternalId,
  });
  const { handleClickInfo } = useHandleClickInfo({
    channelId: channel.id,
    id: liveProgram?.id!,
    externalId: liveProgram?.mosaicExternalId,
    mediaTypeParam: 'program',
    isChannelHasNoProgram,
  });
  // images urls
  const { backgroundImageUrl, defaultImageUrl, channelLogoUrl } = useHeroCardImages({
    channel,
    imageType: settings.imageType,
    imageWidth,
    liveProgram,
    channelIconType: settings.channelIconType,
    channelIconRatio: settings.channelIconRatio!,
    imageHeight,
    isChannelHasNoProgram,
  });
  const imageUrl = isChannelHasNoProgram ? channelLogoUrl : backgroundImageUrl;
  // translations and titles
  const intl = useIntl();
  const title = liveProgram?.title ? getProgramTitle(liveProgram) : channel.title;
  const seasonInfoData = liveProgram && getAssetEpisodeIndication(liveProgram);
  const seasonEpisodeTitle = seasonInfoData ? getSeasonEpisodeTitle(intl, seasonInfoData, ':', true) : '';

  return {
    link,
    title,
    isAdult,
    imageUrl,
    handleRef,
    liveProgram,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    handleLinkRef,
    handleClickInfo,
    channelLogoUrl,
    defaultImageUrl,
    backgroundImageUrl,
    seasonEpisodeTitle,
    isChannelHasNoProgram,
  };
}

export { useHeroCard };
