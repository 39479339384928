import React, { memo } from 'react';
import SVG from 'react-inlinesvg';
import cn from 'classnames';

import { getImageByRatio, MyFavoriteChannelsSettings, RhinoImage } from '@kaltura-ott/tvpil-shared';

import burgerIcon from 'assets/images/drag-and-drop-icon.svg';
import { LOGO_HEIGHT } from 'components/pages/Settings/RhinoFavorites/constants';

import { DndAction } from '../../enum';

import styles from './Channel.module.scss';

export type ChannelProps = {
  state: DndAction;
  images: Array<RhinoImage>;
  title: string;
  settings: Pick<MyFavoriteChannelsSettings, 'channelIconType' | 'channelIconRatio'>;
  channelNumber?: number;
  isLocked?: boolean;
};

export const Channel = memo(({ images, title, settings, state, channelNumber, isLocked }: ChannelProps) => {
  if (!settings.channelIconType) {
    return null;
  }

  const originImage = getImageByRatio(images, {
    imageHeight: LOGO_HEIGHT,
    imageRatio: settings.channelIconRatio,
    imageType: settings.channelIconType,
  });

  return (
    <div
      className={cn(styles.channel, {
        [styles.dragging]: state === DndAction.Dragging && !isLocked,
        [styles.locked]: isLocked,
      })}
      tabIndex={0}
      role='button'
    >
      {channelNumber && <p>{channelNumber}</p>}
      {originImage?.url ? (
        <img draggable='false' src={originImage.url} alt={title} />
      ) : (
        <span className={styles.title}>{title}</span>
      )}

      <div className={styles.burger}>
        <SVG src={burgerIcon} />
      </div>
    </div>
  );
});
