import { useCallback } from 'react';

import { AssetPresentationRatioEnum } from '@kaltura-ott/tvpil';
import { getWidthByHeight, useTopShelfSettings } from '@kaltura-ott/tvpil-shared';

import { HERO } from 'consts';
import { applyStyle, checkLandscapeOrientation } from 'utils';

import { HEIGHT_BY_DEFAULT_LANDSCAPE, HEIGHT_BY_DEFAULT_PORTRAIT } from '../constants';
import { topShelfStorage } from '../topShelfStorage';

/**
 * `useTopShelfRailCombiner` is a custom React hook used for retrieving and managing data necessary
 * for displaying a top shelf rail in a web application. This hook combines top shelf rail settings,
 * rail data.
 *
 * @param referenceId - A unique identifier of the top shelf rail widget.
 *
 * @returns An object containing:
 * - `isAutoPlay`: logical flag for auto playing of rail.
 * - `handleRef`: a callback function for applying styles on wrapper.
 * - `settings`: Configurations and settings for the top shelf rail component's UI, obtained from `useTopShelfRailCombiner`.
 *
 */

function useTopShelfRailCombiner(referenceId: string) {
  const settings = useTopShelfSettings({ referenceId });
  const isAutoPlay = !!settings?.autoTransitionDelay;
  const ratio = settings?.imageRatio || AssetPresentationRatioEnum['16x9'];
  const isLandscape = checkLandscapeOrientation(ratio);

  const handleRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node && settings) {
        let { imageWidth, imageHeight } = settings;

        if (!imageHeight || Number.isNaN(imageHeight)) {
          imageHeight = HEIGHT_BY_DEFAULT_PORTRAIT;
          if (isLandscape) {
            imageHeight = HEIGHT_BY_DEFAULT_LANDSCAPE;
          }
        }

        if (!imageWidth) {
          imageWidth = getWidthByHeight(imageHeight, ratio);
        }

        // For calculating the necessary parameters for displaying elements and animation
        const firstElement = (imageWidth! - imageWidth! * 0.8) * 0.75;
        const thirdElement = imageWidth! * 0.8 * 0.28;
        const lastElement = (imageWidth! - imageWidth! * 0.58) * 0.2;
        const result = firstElement + imageWidth! + thirdElement + lastElement;

        applyStyle(node, '--container-width', `${result}px`);
        applyStyle(node, '--shift-left', `${firstElement}px`);
        applyStyle(node, '--image-height', `${imageHeight}px`);
        applyStyle(node, '--image-width', `${imageWidth}px`);
        // temporary solution to add indentation if hero rail is used simultaneously with TSC
        if (document.getElementById(HERO)) {
          applyStyle(node, '--extra-gap', '16px');
        }

        topShelfStorage.setCardParams({
          imageWidth,
          imageHeight,
          isLandscape,
        });
      }
    },
    [settings],
  );

  return { settings, handleRef, isAutoPlay };
}

export { useTopShelfRailCombiner };
