import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { CollectionProgramAsset, isCollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import { useCardRef, useMouseEnterHandle, useMouseLeaveHandle } from 'components/presenters/Card/hooks';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { useWidgetContext } from 'context/widgetProvider/widgetProvider';
import { getSeasonEpisodeTitleV2 } from 'utils';

import { useSeriesCatchUpCardChannelInfo } from '../useSeriesCatchUpCardChannelInfo/useSeriesCatchUpCardChannelInfo';
import { useSeriesCatchUpCardImages } from '../useSeriesCatchUpCardImages/useSeriesCatchUpCardImages';
import { useSeriesCatchUpCardInfoClickHandler } from '../useSeriesCatchUpCardInfoClickHandler/useSeriesCatchUpCardInfoClickHandler';
import { useSeriesCatchUpCardLink } from '../useSeriesCatchUpCardLink/useSeriesCatchUpCardLink';
import { useSeriesCatchUpCardMainProps } from '../useSeriesCatchUpCardMainProps/useSeriesCatchUpCardMainProps';
import { useSeriesCatchUpCardRating } from '../useSeriesCatchUpCardRating/useSeriesCatchUpCardRating';
import { useSeriesCatchUpClickHandler } from '../useSeriesCatchUpClickHandler/useSeriesCatchUpClickHandler';

interface Props {
  seriesCatchup: CollectionProgramAsset;
  itemWidth: number;
  imageHeight: number;
}

function useSeriesCatchUpCard({ seriesCatchup, itemWidth, imageHeight }: Props) {
  const intl = useIntl();

  const { onHover, nextButtons } = useRailContext();
  const {
    settings: { shouldShowRating, imageType, shouldShowEntitlementIndicator },
    type,
  } = useWidgetContext();

  const {
    isTypeOfSeries,
    isPurchasable,
    isLinkAsAsset,
    isCatchupSeries,
    isChannelAsset,
    isSingleVod,
    isProgramAsset,
    isCollectionEpisode,
    isCollectionInAppAdultLink,
    adult,
  } = useSeriesCatchUpCardMainProps({
    data: seriesCatchup,
    isGrouped: true,
  });

  const isAdult = Boolean(adult);
  const shouldDisplayCartIcon = isPurchasable && Boolean(shouldShowEntitlementIndicator);

  const { link } = useSeriesCatchUpCardLink({
    data: seriesCatchup,
    isSingleVod,
    isFutureProgram: false,
    collectionType: type,
    isChannelAsset,
  });

  // refs
  const handleLinkRef = useRef(null);
  const { ref, handleRef } = useCardRef(itemWidth, imageHeight);
  // handlers
  const onMouseEnter = useMouseEnterHandle({ ref, itemWidth, imageHeight, isGrid: false, onHover, nextButtons });
  const onMouseLeave = useMouseLeaveHandle(onHover);

  const clickHandler = useSeriesCatchUpClickHandler({
    data: seriesCatchup,
    link,
    mediaTypeParam: seriesCatchup.type,
    isLinkAsAsset,
    isPurchasable,
    collectionType: type,
    isProgramAsset,
    isTypeOfSeries,
    isCatchupSeries,
    isCollectionEpisode,
    isCollectionInAppAdultLink,
    isAdult,
  });
  const { handleClickInfo } = useSeriesCatchUpCardInfoClickHandler({
    id: seriesCatchup?.id!,
    externalId: undefined,
    mediaTypeParam: seriesCatchup.type,
    isChannelHasNoProgram: false,
    seriesId: seriesCatchup?.seriesId,
  });
  // rating
  const { isDisplayRating, assetRating } = useSeriesCatchUpCardRating({ shouldShowRating, item: seriesCatchup });
  // images urls
  const { backgroundImageUrl, defaultImageUrl } = useSeriesCatchUpCardImages({
    item: seriesCatchup,
    imageType,
    imageWidth: itemWidth,
    imageHeight,
  });
  // title and channelName
  const { title, channelName } = useSeriesCatchUpCardChannelInfo(seriesCatchup);
  // collectionProgramAsset type checking for AirTime presentation
  const collectionProgramAsset = isCollectionProgramAsset(seriesCatchup) ? seriesCatchup : undefined;
  // checking for info button presentation
  const channelHasProgram = Boolean(!isChannelAsset && !isLinkAsAsset);

  // season / episode title definition
  let seasonEpisodeTitle = '';

  const isEpisodeInfo = (isProgramAsset || isCollectionEpisode) && !isCatchupSeries;
  if (isEpisodeInfo) {
    seasonEpisodeTitle = getSeasonEpisodeTitleV2(intl, seriesCatchup, ':', true);
  }

  // seriesId definition
  const seriesId = seriesCatchup.seriesId!;

  return {
    id: seriesCatchup.id,
    link,
    title,
    isAdult,
    seriesId,
    handleRef,
    assetRating,
    channelName,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    handleLinkRef,
    isLinkAsAsset,
    handleClickInfo,
    isDisplayRating,
    defaultImageUrl,
    channelHasProgram,
    seasonEpisodeTitle,
    backgroundImageUrl,
    collectionProgramAsset,
    shouldDisplayCartIcon,
  };
}

export { useSeriesCatchUpCard };
