import React from 'react';

import { RAIL_PAGE_SIZE } from 'consts';
import WidgetProvider from 'context/widgetProvider/widgetProvider';

import RailProviderV2 from '../components/RailProviderV2/RailProviderV2';
import RailSkeleton from '../components/RailSkeleton/RailSkeleton';

import HeroRailContainer from './components/HeroRailContainer/HeroRailContainer';
import { useHeroRail } from './hooks/useHeroRail/useHeroRail';

interface Props {
  referenceId: string;
  pageReferenceId: string;
}

function HeroRail({ referenceId, pageReferenceId }: Props) {
  const { settings, railData, railType, isLoading, isRailInvalid } = useHeroRail(referenceId, pageReferenceId);

  if (!settings || isRailInvalid) {
    return null;
  }

  if (!railData.items.length && isLoading) {
    return <RailSkeleton settings={settings} numberOfCells={RAIL_PAGE_SIZE} />;
  }

  return (
    <WidgetProvider type={railType} settings={settings} data={railData}>
      <RailProviderV2 referenceId={referenceId} pageReferenceId={pageReferenceId}>
        <HeroRailContainer />
      </RailProviderV2>
    </WidgetProvider>
  );
}

export default HeroRail;
