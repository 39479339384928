import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import style from './AnimateBackground.module.scss';

interface Props {
  className?: string;
  classNameOfShadow?: string;
  url?: string;
  fallBackUrl?: string;
}

function AnimateBackground({ className, classNameOfShadow, url, fallBackUrl }: Props) {
  const prevUrl = url || fallBackUrl;
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(prevUrl);
  const [isAnimatingState, setIsAnimatingState] = useState(false);
  let timer: any;

  useEffect(() => {
    if (prevUrl) {
      new Promise((resolve) => {
        const img = new Image();

        img.src = prevUrl!;
        img.onload = resolve as any;
        img.onerror = resolve as any;
      });
    }

    if (prevUrl !== backgroundImageUrl) {
      setIsAnimatingState(true);

      timer = setTimeout(() => {
        setIsAnimatingState(false);
        setBackgroundImageUrl(prevUrl);
      }, 400);
    }

    return () => clearTimeout(timer);
  }, [url, fallBackUrl]);

  return (
    <div
      className={cn(className, { [style.animating]: isAnimatingState })}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <span className={classNameOfShadow} />
    </div>
  );
}

export default AnimateBackground;
