import React from 'react';

import { RentalAsset } from '@kaltura-ott/tvpil-shared/lib/rhino/data/rental/types';

import { RAIL_PAGE_SIZE } from 'consts';
import { WidgetProvider } from 'context/widgetProvider/widgetProvider';

import RailProviderV2 from '../components/RailProviderV2/RailProviderV2';
import RailSkeleton from '../components/RailSkeleton/RailSkeleton';

import RentalsRailContainer from './components/RentalsRailContainer/RentalsRailContainer';
import { useRentalsRail } from './hooks/useRentalsRail/useRentalsRail';

interface Props {
  referenceId: string;
}

function RentalsRail({ referenceId }: Props) {
  const { railData, railType, railSettings, isRailHidden } = useRentalsRail(referenceId);

  if (!railSettings || isRailHidden) {
    return null;
  }

  if (!railData.items.length) {
    return <RailSkeleton settings={railSettings} numberOfCells={RAIL_PAGE_SIZE} shouldShowHeader />;
  }

  return (
    <WidgetProvider<RentalAsset> type={railType} settings={railSettings!} data={railData}>
      <RailProviderV2 referenceId={referenceId}>
        <RentalsRailContainer />
      </RailProviderV2>
    </WidgetProvider>
  );
}

export default RentalsRail;
