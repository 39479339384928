import { useEffect, useRef } from 'react';

import { PlayoutStoreV2Type } from '@kaltura-ott/tvpil-shared';
import { PlayableAsset } from '@kaltura-ott/tvpil-shared/lib/stores/entities/PlayableAsset';

import { useAppStore, useCleanupState } from 'hooks';
import { globalStorage } from 'services/globalStorage';

export const useInitializationHook = (isPlayerCondition: boolean) => {
  const hasComponentAlreadyMounted = useRef(false);
  const [loaded, setLoaded] = useCleanupState(() => false);
  const playableAsset = useRef<PlayableAsset | undefined>(undefined);
  const {
    appStore: { playoutStoreV2: playoutStore, lastWatchedLinear },
  } = useAppStore();
  const focusAsset = globalStorage.focusAsset!;

  useEffect(() => {
    async function effect() {
      await playoutStore.init({
        playableAsset: globalStorage.focusAsset!,
        type: PlayoutStoreV2Type.LIVE,
        isMaxiPlayer: true,
        mosaic: {
          externalId: lastWatchedLinear.mainHeroRail?.lastWatchedSubLinearExternalId,
        },
      });

      hasComponentAlreadyMounted.current = true;
      setLoaded(!!playoutStore.currentAsset);
      globalStorage.clearFocusAsset();
      playableAsset.current = playoutStore.currentAsset;
    }

    if (focusAsset?.id && playoutStore && !(hasComponentAlreadyMounted.current && isPlayerCondition)) {
      setLoaded(false);
      effect();
    }

    return () => {
      if (focusAsset?.id) {
        hasComponentAlreadyMounted.current = false;
      }
    };
  }, [playoutStore, globalStorage.focusAsset?.id, setLoaded, isPlayerCondition]);

  return { loaded, playableAsset: playableAsset.current };
};
