import React, { useEffect, useRef } from 'react';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import { autoScrollWindowForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { isChannel } from 'guards/isChannel';

import { Channel } from '@kaltura-ott/tvpil-shared';

import { shiftChannelNumbersBackward, shiftChannelNumbersForward } from '../helper';

type DragAndDropGridContainerProps = {
  originalChannels: Array<Channel>;
  setLineupChange: (arg: Array<Channel>) => void;
  children: React.ReactNode;
};

export function DragAndDropGridContainer({
  children,
  originalChannels,
  setLineupChange,
}: DragAndDropGridContainerProps) {
  const mainRef = useRef<HTMLImageElement>(null);

  const originalStartIndexRef = useRef<null | number>(null);
  const originalDestinationIndexRef = useRef<null | number>(null);

  useEffect(() => {
    return autoScrollWindowForElements({
      getConfiguration: () => ({
        maxScrollSpeed: 'fast',
      }),
    });
  });

  useEffect(() => {
    return monitorForElements({
      onDropTargetChange({ source, location }) {
        const destination = location.current.dropTargets[0];

        if (!destination) {
          return;
        }

        const startChannel = source.data.channel;
        const destinationChannel = destination.data.channel;

        if (!isChannel(startChannel) || !isChannel(destinationChannel)) {
          return;
        }

        if (destinationChannel.isAdult) return;

        const startIndex = originalChannels.findIndex((channel) => channel.id === startChannel.id);
        const destinationIndex = originalChannels.findIndex((channel) => channel.id === destinationChannel.id);

        originalStartIndexRef.current = startIndex;
        originalDestinationIndexRef.current = destinationIndex;
      },

      onDrop({ source, location }) {
        const current = location.current.dropTargets[0];
        const destination = location.initial.dropTargets[0];

        if (originalStartIndexRef.current === null || originalDestinationIndexRef.current === null) {
          return;
        }

        const channels = reorder({
          list: originalChannels,
          startIndex: originalStartIndexRef.current,
          finishIndex: originalDestinationIndexRef.current,
        });

        if (!destination || !current) {
          return;
        }

        const startChannel = source.data.channel;
        const currentChannel = current.data.channel;
        const destinationChannel = destination.data.channel;

        if (!isChannel(startChannel) || !isChannel(destinationChannel) || !isChannel(currentChannel)) {
          return;
        }

        const startIndex = originalStartIndexRef.current;
        const destinationIndex = channels.findIndex((channel) => channel.id === destinationChannel.id);

        originalStartIndexRef.current = null;

        if (startChannel.id === currentChannel.id) {
          return;
        }

        const isMovingForward = startIndex < destinationIndex;

        if (isMovingForward) {
          const updatedChannels = shiftChannelNumbersForward({ startIndex, destinationIndex, channels });

          setLineupChange(updatedChannels);

          return;
        }

        const updatedChannels = shiftChannelNumbersBackward({ startIndex, destinationIndex, channels });

        setLineupChange(updatedChannels);
      },
    });
  }, [originalChannels, setLineupChange]);

  return <div ref={mainRef}>{children}</div>;
}
