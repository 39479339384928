import React, { useCallback } from 'react';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { WatchedAsset } from '@kaltura-ott/tvpil-shared';

import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalTypesEnum';
import PinCodeModalV2 from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalV2';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { useModalContext } from 'context/modal/modal';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import { useIsUrlToFullPlayer, useParentalPinStore, useSettingsStore } from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { userActionEvents } from 'utils';

interface Props {
  data: WatchedAsset;
  link: string;
  mediaTypeParam: string;
}

export const useClickHandler = ({ data, link, mediaTypeParam }: Props) => {
  const { onCardClick } = useRailContext();
  const { navigate } = useStableReactRouter();
  const { settingsStore } = useSettingsStore();
  const parentalPinStore = useParentalPinStore();
  const isUrlToFullPlayer = useIsUrlToFullPlayer(link);
  const modalContext = useModalContext();
  // needs for parental rating check in case of url to full player and parental rating is blocked
  const parentalRating = Number(data?.parentalRating);

  return useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      globalStorage.clearDetailsAsset(); // Temporary solution to avoid opening a detail page with an asset saved in globalStorage

      if (onCardClick) {
        onCardClick(data?.id);
      }

      const isAdultPinNotEntered = !parentalPinStore.isAdultPinEntered;
      const isRestricted = !!(data.isAdult && isAdultPinNotEntered);

      if (isRestricted) {
        e.preventDefault();
        e.stopPropagation();

        const type = PinCodeModalTypes.RestrictedContent;

        modalContext.openModal(
          <PinCodeModalV2
            parentalRating={Number(parentalRating)}
            context={UIDAPresentationTypeEnum.applications}
            type={type}
          />,
        );

        return;
      }

      if (isUrlToFullPlayer && parentalPinStore.isBlockedByParentalRating(parentalRating)) {
        e.preventDefault();

        modalContext.openModal(
          <PinCodeModalV2
            callback={(success: boolean) => {
              if (success) {
                navigate(link);
              }
            }}
            parentalRating={parentalRating}
            context={UIDAPresentationTypeEnum.applications}
            description={parentalRating && <DescriptionWithAge age={parentalRating} />}
            type={PinCodeModalTypes.RestrictedContentParental}
          />,
        );
        return;
      }

      if (link.includes('http')) {
        e.preventDefault();
        window.open(link, '_blank');
      }

      if (link.includes('details')) {
        userActionEvents(UserActionType.details, data?.id, mediaTypeParam);
      }
    },
    [modalContext, link, parentalRating, settingsStore],
  );
};
