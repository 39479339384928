import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import detailsStoreDataResolver from 'components/pages/VideoDetails/utils/detailsStoreDataResolver';
import ProgressBar from 'components/widgets/ProgressBar/ProgressBar';
import TimeProgressBar from 'components/widgets/ProgressBar/TimeProgressBar';
import { useVideoContext } from 'context/videoContext/videoContext';
import { useDetectLocation } from 'hooks';

import { COMPONENT_NAME } from '../../../../constants';

import styles from './Progress.module.scss';

type Props = {
  watchedDuration: number;
  remainingTime?: number;
  finishedWatching?: boolean;
};

export const Progress = ({ watchedDuration, remainingTime, finishedWatching }: Props) => {
  const { program, isOverlay } = useVideoContext();
  const { isDetailsInfo, isDetailsSeries } = useDetectLocation();
  const {
    endDate,
    startDate,
    isLive,
    isVodSingle,
    enableCatchUp,
    isPast,
    record,
    isProgramRecord,
  } = detailsStoreDataResolver(program);
  const isShowingBookmark = isPast && !isDetailsSeries && enableCatchUp && !!watchedDuration;
  const isLiveDetailsInfo = isLive && isDetailsInfo;
  const isLiveOverlay = isLive && isOverlay;
  const isOnGoingRecord = record?.data.isOngoing;
  const isProgressShow = isLiveDetailsInfo || isShowingBookmark || isLiveOverlay || (isVodSingle && !!watchedDuration);

  function calcRemainingTime() {
    let resultCalcRemainingTime;

    if ((isLiveDetailsInfo || isLiveOverlay) && endDate) {
      resultCalcRemainingTime = Math.round((endDate - Date.now()) / 60000) || 1;
    } else if (remainingTime) {
      const calcResultTime = remainingTime / 60;
      if (calcResultTime < 1) {
        resultCalcRemainingTime = '<1';
      } else {
        resultCalcRemainingTime = Math.round(calcResultTime) || 1;
      }
    }

    return resultCalcRemainingTime;
  }

  if (isOnGoingRecord && isProgramRecord) return null;

  if (isProgressShow) {
    const viewRemainingTime = calcRemainingTime();

    return (
      <div className={styles.progress}>
        {(isLiveDetailsInfo || isLiveOverlay) && endDate && startDate && (
          <TimeProgressBar endDate={endDate} startDate={startDate}>
            {(percent: number) => <ProgressBar className={styles.timeline} percent={percent} />}
          </TimeProgressBar>
        )}

        {(isShowingBookmark || (isVodSingle && !!watchedDuration)) && (
          <ProgressBar className={cn(styles.timeline, { [styles.full]: finishedWatching })} percent={watchedDuration} />
        )}

        {viewRemainingTime && (
          <FormattedMessage
            defaultMessage='Remaining time: {time}m'
            id={`${COMPONENT_NAME}.VideoContent.remainingTime`}
            tagName='span'
            values={{ time: viewRemainingTime }}
          />
        )}
      </div>
    );
  }
  return null;
};
